import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Modal from "react-modal";
import { BsX } from "react-icons/bs";
import { Button, Flex, View } from "@aws-amplify/ui-react";

export const DataSetDelete = ( { className, onDelete } ) => {
    const [isModalOpen, setIsModalOpen] = useState( false )

    let wrapperClasses = classNames( 'cp-ctl', className )

    function deleteDataset() {
        onDelete && onDelete()
        closeModal()
    }
    function closeModal() {
        setIsModalOpen( false )
    }

    function openModal() {
        setIsModalOpen( true )
    }

    const modalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            overflow: "hidden"
        },
        overlay: {
            zIndex: 2
        }
    }

    return (
        <View className={wrapperClasses} >
            <BsX color="#f47e39" size="20px" title="Edit" onClick={openModal}/>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Color Picker"
            >
                
                Are you sure you want to delete the dataset from the chart?

                <Flex marginTop="medium" width="100%" justifyContent="space-between">
                <Button onClick={closeModal}>
                    Cancel
                </Button>

                <Button variation="primary" onClick={deleteDataset}>
                    Delete
                </Button>
                </Flex>

            </Modal>

        </View>
    )
}

DataSetDelete.propTypes = {
//   onUpdate: PropTypes.func.isRequired,
//   value: PropTypes.string,
//   label: PropTypes.string,
}
