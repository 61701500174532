import React from 'react';

require('./OverflowWrapper.scss');

interface Props {
  children: React.ReactNode;
}

export function OverflowWrapper({children}: Props) {
  return <div className={'dnd-overflowwrapper'}>{children}</div>;
}
