import { getUsedData } from '../specUtils'
import { filterData } from './filterData'

export function getVegaData( chartConfig, chartSpec, chartData, filterState ) {
  let vegaData = {}

  const validData = getUsedData( chartSpec )

  // Create the datasets as needed by VEGA.
  if ( chartData && Object.keys( chartData ).length ) {

    vegaData = Object.keys( chartData ).reduce( ( obj, d ) => {

      // Only add in the dataset if it's being used and if we have the data.
      if ( validData.includes( d ) && Array.isArray( chartData[d].data ) ) {
        obj[d] = [ ...chartData[d].data ]
      }
      return obj
    }, {} )

    vegaData = filterData( chartConfig.iflt, vegaData, filterState.ifValues )
  }

  return vegaData
}
