import { area } from './types/area'
import { bar } from './types/bar'
import { stackedbar } from './types/stackedbar'
import { beeswarm } from './types/beeswarm'
import { donut } from './types/donut'
import { line } from './types/line'
import { pcoord } from './types/pcoord'
import { scatter } from './types/scatter'
import { ushexstates } from './types/ushexstates'
import { usstates } from './types/usstates'

const types = {
  area: area,
  bar: bar,
  stackedbar: stackedbar,
  beeswarm: beeswarm,
  doughnut: donut,
  line: line,
  pcoord: pcoord,
  scatter: scatter,
  ushexstates: ushexstates,
  usstates: usstates
}

export function genChartSpec( config, data ) {
  let spec = {}

  if ( config && config.type && types[config.type]  ) {
    spec = types[config.type]( config, data )
  } else {
    // console.log( 'UNKNOWN CHART TYPE:', config, types )
  }

  return spec
}
