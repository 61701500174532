const EarthRadius = 3958.8 // miles

export function gcDistance( loc1, loc2 ) {
  let distance = null

  if ( loc1 && loc2 ) {
    let l1Latr = ( loc1.lat * ( Math.PI / 180 ) )
    let l2Latr = ( loc2.lat * ( Math.PI / 180 ) )
    let lonDiff = ( ( loc2.lon - loc1.lon ) * ( Math.PI / 180 ) )

    distance = EarthRadius * Math.acos( Math.sin( l1Latr ) * Math.sin( l2Latr ) +  Math.cos( l1Latr ) * Math.cos( l2Latr ) * Math.cos( lonDiff ) )
  }

  return distance
}
