import React, { useContext, useEffect, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import clone from 'clone'
import isEqual from 'lodash/isEqual'

import { VegaEditorContext } from '../../../../VegaEditorContext'
import { FIELDTYPE_NUMERIC } from '../../../../../../../../hooks'

import { ConfigGroup } from './ConfigGroup'
import { DropField } from './DropField'
import { SliderInput } from './SliderInput'

export const SizeConfig = ( { className, label, isDynamic, validDataset } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  // Perform a conversion for the old style colors being just a field definition.
  let colorValues = config.size ? { field: config.size } : config.symbol ? config.symbol : {}

  // Setup the static colors.
  const [symbol, setSymbol] = useReducer(
  ( state, colorUpdate ) => {
    return { ...state, ...colorUpdate }
  }, clone( colorValues ) )

  // If the symbol information changes update the config.
  useEffect( () => {
    if ( !isEqual( prevSymbol.current, symbol ) ) {
      updateConfig( { symbol: symbol } )
    }
  } )

  const prevSymbol = useRef()
  useEffect( () => {
    prevSymbol.current = symbol
  } )

  return (
    <ConfigGroup label={label ? label : 'Size'}>

        {
          isDynamic ? (
            <DropField
              prop="field"
              label="Value"
              field={symbol.field ? symbol.field : null}
              accept={[FIELDTYPE_NUMERIC]}
              onDrop={setSymbol}
              validDataset={validDataset}
            />
          ) : null
        }

        <SliderInput
          min={1}
          max={15}
          step={1}
          marks={[5,10,15]}
          markFormat=".0f"
          value={symbol.size ? symbol.size : 8}
          onUpdate={( size ) => { setSymbol( { size: size } ) }}
        />

    </ConfigGroup>
  )
}

SizeConfig.propTypes = {
  configProp: PropTypes.string,
  label: PropTypes.string,
  isDynamic: PropTypes.bool,
  validDataset: PropTypes.string
}
