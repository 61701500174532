import KSUID from 'ksuid';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $insertNodeToNearestRoot } from "@lexical/utils";
import {
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand
} from "lexical";
import { useEffect } from "react";

import { $createChartNode, ChartNode } from "../nodes/ChartNode";

export const INSERT_CHART_COMMAND: LexicalCommand<string> = createCommand(
  "INSERT_CHART_COMMAND"
);

export function genChartId(): string {
  const id = `c_${KSUID.randomSync( Date.now() ).string}`
  return id
}

export default function ChartPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ChartNode])) {
      throw new Error("ChartPlugin: ChartNode not registered on editor");
    }

    return editor.registerCommand<string>(
      INSERT_CHART_COMMAND,
      (payload) => {
        const chartNode = $createChartNode(payload);
        $insertNodeToNearestRoot(chartNode);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
