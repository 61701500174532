import { useEffect, useState } from 'react'
import Modal from "react-modal";
import AutoSizer from 'react-virtualized-auto-sizer';
import { Button, Flex, SelectField, View } from "@aws-amplify/ui-react";
import { SelectTable } from './SelectTable';
import { SortDirection } from "react-virtualized";
import { getNodeValue, formatNumber } from '@ripedata/datautils';

import { TextValueOption } from '../TextValueOption';

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden"
  },
  overlay: {
      background: "#4c4d4e",
      zIndex: 100
  }
};


export const FixedCountDataSelector = ( { data, config, numOptions, options, onUpdate, valueFormat } ) => {
  const [selecting, setSelecting] = useState( false );
  const [selDsId, setSelDsId] = useState( config && config.dsId ? config.dsId : null);
  const [recs, setRecs] = useState( data && data[selDsId] && Array.isArray( data[selDsId].data ) ? data[selDsId].data : []);
  const [selLabelField, setSelLabelField] = useState( config && config.labelField ? config.labelField : null);
  const [selValueField, setSelValueField] = useState( config && config.valueField ? config.valueField : null);
  // const [columns, setColumns] = useState( data && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields : []);
  const [selOptions, setSelOptions] = useState( Array.isArray( options ) ? options.slice() : []);
  const [sortDir, setSortDir] = useState( SortDirection.DESC );

  useEffect( () => {

    // We don't have a dataset established yet, so use the first one.
    if ( ( data && Object.keys( data ).length && !config.dsId ) || ( config.dsId && data && Object.keys( data ).length && ! data[config.dsId] ) ) {
      let newDsId = Object.keys( data )[0];

      // We are setting a new dataset so remove the current options 
      setSelOptions( [] );

      // Now find the first string/categorical field to use as the label.
      let newLabelField = data[newDsId].fields.find( d => {
        return d.type === 'string';
      } )

      // Then the first numeric field to use as the value.
      let newValueField = data[newDsId].fields.find( d => {
        return d.type === 'number';
      } )

      // Update the config we are using.
      setSelDsId( newDsId );
      // setColumns( [newLabelField, newValueField] );

      // sortRecs( data[newDsId].data, newValueField.loc, sortDir );
      setRecs( data[newDsId].data )

      setSelLabelField( newLabelField ? newLabelField.loc : null );
      setSelValueField( newValueField ? newValueField.loc : null );
    }
  }, [data, config] );

  function closeSelector() {
    setSelecting( false );
  }

  function saveUpdate() {
    const updOptCfg = {
      dsId: selDsId,
      labelField: selLabelField,
      valueField: selValueField
    }

    onUpdate( { options: selOptions, optionsConfig: updOptCfg } );

    closeSelector();
  }

  function setDsId( e ) {

    if ( e.target.value !== selDsId ) {
      let newDsId = e.target.value;

      // We are setting a new dataset so remove the current options 
      setSelOptions( [] );

      // Set the new dataset id
      setSelDsId( newDsId );

      setRecs( data[newDsId].data )

      // Now find the first string/categorical field to use as the label.
      let newLabelField = data[newDsId].fields.find( d => {
        return d.type === 'string';
      } )

      // Then the first numeric field to use as the value.
      let newValueField = data[newDsId].fields.find( d => {
        return d.type === 'number';
      } )

      setSelLabelField( newLabelField ? newLabelField.loc : null );
      setSelValueField( newValueField ? newValueField.loc : null );
    }
  }

  function setLabelField( e ) {
    setSelLabelField( e.target.value );

    // Find the new label field.
    const lf = findField( e.target.value );

    // Then the value field
    const vf = findField( selValueField );

    // Then update the columns for the table.
    // setColumns( [lf, vf] );
    setSelOptions( [] );
  }

  function setValueField( e ) {
    setSelValueField( e.target.value );

    // Find the new value field.
    const vf = findField( e.target.value );

    // Then the label field
    const lf = findField( selLabelField );

    // Then update the columns for the table.
    // setColumns( [lf, vf] );
    setSelOptions( [] );

    // And resort the data with the new values.
    // sortRecs( data[selDsId].data, e.target.value, sortDir );
  }

  function findField( loc ) {
    let field = null 

    if ( data && selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ) {
      field = data[selDsId].fields.find( d => {
        return d.loc === loc;
      } )
    }

    return field;
  }

  function itemSelected( rd ) {
    let newOptions = selOptions.slice();

    // Check to see if this item was already selected.
    let idx = selOptions.findIndex( d => {
      return d.label === rd[selLabelField];
    })

    if ( idx >= 0 ) {
      // We already have this record so it needs to be removed.
      newOptions = [...selOptions.slice( 0, idx ), ...selOptions.slice( idx + 1 )];
    } else {
      // We don't have this record so add it to the list (if we have room ).
      if ( selOptions.length < numOptions ) {
        newOptions.push( { label: rd[selLabelField], value: rd[selValueField] } );
      }
    }

    setSelOptions( newOptions );
  }

  function sortRecs( recs, sortKey, dir ) {
    let sorted = recs;

    if ( Array.isArray( recs ) && sortKey ) {
      // Filter any records that don't have a value
      sorted = recs.filter( d => {
        return getNodeValue( d, sortKey ) !== undefined;
      } )

      // Then sort them.
      sorted.sort( ( a, b ) => {
        let A = getNodeValue( a, sortKey )
        let B = getNodeValue( b, sortKey )

        let compare
        if ( A !== undefined && B !== undefined ) {
          compare = B < A ? 1 : B > A ? -1 : 0
        } else {
          compare = ( A === undefined && B === undefined ) ? 0 : A === undefined ? -1 : 1
        }

        return ( dir === SortDirection.DESC ) ? compare * ( -1 ) : compare
      } )

      // setRecs( sorted );
    }

    return sorted;
  }

  function sortRequested( { sortBy, sortDirection} ) {
    
    let dir = sortDir === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC

    // sortRecs( data[selDsId].data, sortBy, dir );
    setSortDir( dir );
  }

  const columns = [selLabelField, selValueField].reduce( ( ary, d ) => {
    if ( data && selDsId && data[selDsId] && Array.isArray( data[selDsId].fields )) {
      let field = data[selDsId].fields.find( f => {
        return f.loc === d;
      })

      if ( field ) {
        ary.push( field );
      }
    }

    return ary 
  }, [] );

  let sortedRecs = sortRecs( recs, selValueField, sortDir );

  function rowClassname( { index } ) {
    let rcn = '';

    if ( index && index <= sortedRecs.length ) {
      let lVal = getNodeValue( sortedRecs[index], selLabelField )
      if ( lVal ) {

        let selected = selOptions.find( d => {
          return d.label === lVal;
        });

        if ( selected ) {
          rcn = 'gp-fcds-sel'
        }
      }
    }

    return rcn;
  }

  // console.log( 'DATA:', sortedRecs, recs, data, selDsId )

  return (
    <Flex direction="column" alignItems="center"  gap="0px" marginTop="large">

      <Button
        onClick={()=> setSelecting( true )}
        size="default"
      >
        Select Data
      </Button>

      <Modal
          isOpen={selecting}
          onRequestClose={closeSelector}
          style={customModalStyles}
          contentLabel="Game Editor"
      >
        <Flex direction="column" width="100%" height="100%">
          <Flex justifyContent="flex-end" marginBottom="small" marginRight="small">
            <Button onClick={closeSelector}>
              Cancel
            </Button>

            <Button variation="primary" onClick={saveUpdate} disabled={numOptions === undefined || selOptions.length < numOptions}>
              Save Data
            </Button>
          </Flex>

          <View className="ved-wksp">
            <View>
              <SelectField
                key="Dataset"
                label="Dataset"
                value={selDsId}
                onChange={setDsId}
              >
                {
                  Object.keys( data ).length ? Object.keys( data ).map( d => {
                    return(
                      <option key={data[d].dsId} value={data[d].dsId}>
                        {data[d].name}
                      </option>
                    ) }
                  ) : null
                }

              </SelectField>

              <SelectField
                key={`lf-${selDsId}`}
                label="Label Field"
                value={selLabelField}
                onChange={setLabelField}
              >
                {
                  selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields.reduce( ( ary, d ) => {
                    if ( d.type === 'string' ) {
                      ary.push(
                        <option key={d.loc} value={d.loc}>
                          {d.name}
                        </option>
                      )
                    }

                    return ary
                  }, [] ) : null
                }

              </SelectField>

              <SelectField
                key={`vf-${selDsId}`}
                label="Value Field"
                value={selValueField}
                onChange={setValueField}
              >
                {
                  selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields.reduce( ( ary, d ) => {
                    if ( d.type === 'number' ) {
                      ary.push(
                        <option key={d.loc} value={d.loc}>
                          {d.name}
                        </option>
                      )
                    }

                    return ary
                  }, [] ) : null
                }

                </SelectField>

                <View marginTop="large">
                <table>
                  <thead>
                    <tr>
                    <th>Label</th>
                    <th>Value</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      selOptions.map( ( d, i ) => {
                        return (
                          <tr key={`p${i}`}>
                            <td>{d.label}</td>
                            <td style={{textAlign: "end"}}>{valueFormat ? formatNumber( valueFormat, d.value ) : d.value}</td>
                          </tr>
                        )
                      } )

                    }
                  </tbody>
                </table>
                </View>

              </View>

              <View marginLeft="small" grow={1} shrink={1}>
                <AutoSizer>
                  {({height, width}) => (
                    <SelectTable 
                      key={`${selDsId}:${columns.map( d => { return d.loc })}:${sortDir}`}
                      width={width}
                      height={height}
                      recs={sortedRecs}
                      columns={columns}
                      selected={selOptions}
                      onSelect={itemSelected}
                      onSort={sortRequested}
                      sortBy={selValueField}
                      sortDir={sortDir}
                      rowClassname={rowClassname}
                    />
                  )}
                </AutoSizer>
              </View>
          </View>
        </Flex>
      </Modal>
    </Flex>
  )
}