export const FIELDTYPE_TEXT = "T";
export const FIELDTYPE_CATEGORICAL = "C";
export const FIELDTYPE_NUMERIC = "#";
export const FIELDTYPE_BOOLEAN = "B";

export const fieldTypes = [FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC, FIELDTYPE_TEXT, FIELDTYPE_BOOLEAN];

export function fieldType( field ) {
  let tooMany = field.valuesExceeded || field.valueLenExceeded
  let ftype = ''
  let ftt = ''

  switch( field.type ) {
    case 'number':
      ftype = FIELDTYPE_NUMERIC
      ftt = 'The field is numeric'
      break;

    case 'boolean':
      ftype = FIELDTYPE_BOOLEAN
      ftt = 'The field is boolean'
      break;  

    case 'string':
    default:
      ftype = tooMany ? FIELDTYPE_TEXT : FIELDTYPE_CATEGORICAL
      ftt = `The field is ${tooMany ? 'a string' : 'categorical'}`
      break;
  }

  return {
    fname: field.name ? field.name : field.loc,
    type: field.type,
    ftype,
    ftt
  }
}