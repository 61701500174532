import { useEffect, useState } from 'react'
import Modal from "react-modal";
import AutoSizer from 'react-virtualized-auto-sizer';
import { Button, Flex, SelectField, View } from "@aws-amplify/ui-react";
import { SelectTable } from './SelectTable';
import { SortDirection } from "react-virtualized";
import { getNodeValue, formatNumber } from '@ripedata/datautils';
import { TextInput } from '../TextInput';

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden"
  },
  overlay: {
      background: "#4c4d4e",
      zIndex: 100
  }
};

export const CategoricalDataSelector = ( { data, config, options, onUpdate, valueFormat, numBins, labels } ) => {
  const [selecting, setSelecting] = useState( false );
  const [selDsId, setSelDsId] = useState( config && config.dsId ? config.dsId : null);
  const [selCategoryField, setSelCategoryField] = useState( config && config.categoryField ? config.categoryField : null);
  const [selLabelField, setSelLabelField] = useState( config && config.labelField ? config.labelField : null);
  const [selValueField, setSelValueField] = useState( config && config.valueField ? config.valueField : null);
  const [selOptions, setSelOptions] = useState( Array.isArray( options ) ? options.slice() : []);
  const [sortDir, setSortDir] = useState( SortDirection.ASC );

  const [categories, setCategories] = useState( config.categories ? config.categories : []);

  const numOptions = numBins * numBins;

  // Set the default fields if we don't have any or when the dataset changes.
  useEffect( () => {

    // We don't have a dataset established yet, so use the first one.
    if ( ( data && Object.keys( data ).length && !config.dsId ) || ( config.dsId && data && Object.keys( data ).length && ! data[config.dsId] ) ) {
      let newDsId = Object.keys( data )[0];

      // We are setting a new dataset so remove the current options 
      setSelOptions( [] );

      // Now find the first categorical field to use as the label.
      let newCategoryField = data[newDsId].fields.find( d => {
        return d.type === 'string' && Array.isArray( d.values ) && !( d.valuesExceeded || d.valueLenExceeded );
      } )

      // Now find the first string/categorical field to use as the label.
      let newLabelField = data[newDsId].fields.find( d => {
        return d.type === 'string';
      } )

      // Then the first numeric field to use as the value.
      let newValueField = data[newDsId].fields.find( d => {
        return d.type === 'number';
      } )

      // Update the config we are using.
      setSelDsId( newDsId );
      // setColumns( [newLabelField, newValueField] );

      // sortRecs( data[newDsId].data, newValueField.loc, sortDir );

      setSelCategoryField( newCategoryField ? newCategoryField.loc : null );
      setSelLabelField( newLabelField ? newLabelField.loc : null );
      setSelValueField( newValueField ? newValueField.loc : null );
    }
  }, [data, config] );

  function closeSelector() {
    setSelecting( false );
  }

  function saveUpdate() {
    const updOptCfg = {
      dsId: selDsId,
      categoryField: selCategoryField,
      labelField: selLabelField,
      valueField: selValueField,
      categories: categories
    }

    onUpdate( { options: selOptions, optionsConfig: updOptCfg } );

    closeSelector();
  }

  function setDsId( e ) {

    if ( e.target.value !== selDsId ) {
      let newDsId = e.target.value;

      // We are setting a new dataset so remove the current options 
      setSelOptions( [] );

      // Set the new dataset id
      setSelDsId( newDsId );

      // Now find the first categorical field to use as the label.
      let newCategoryField = data[newDsId].fields.find( d => {
        return d.type === 'string' && Array.isArray( d.values ) && !( d.valuesExceeded || d.valueLenExceeded );
      } )

      // Now find the first string/categorical field to use as the label.
      let newLabelField = data[newDsId].fields.find( d => {
        return d.type === 'string';
      } )

      // Then the first numeric field to use as the value.
      let newValueField = data[newDsId].fields.find( d => {
        return d.type === 'number';
      } )

      setSelCategoryField( newCategoryField ? newCategoryField.loc : null );
      setSelLabelField( newLabelField ? newLabelField.loc : null );
      setSelValueField( newValueField ? newValueField.loc : null );

      // Establish the new breaks.
      setCategories( [] );
    }
  }

  function setCategoryField( e ) {

    if ( selCategoryField !== e.target.value ) {
      setSelCategoryField( e.target.value );

      // Find the new label field.
      const cf = findField( e.target.value );

      // Then the value field
      const vf = findField( selValueField );

      // Then update the columns for the table.
      // setColumns( [lf, vf] );

      // Clear out the current categories
      setCategories( [] );

      // and the selected options.
      setSelOptions( [] );
    }
  }

  function setLabelField( e ) {
    setSelLabelField( e.target.value );

    // Find the new label field.
    const lf = findField( e.target.value );

    // Then the value field
    const vf = findField( selValueField );

    // Then update the columns for the table.
    // setColumns( [lf, vf] );
    setSelOptions( [] );
  }

  function setValueField( e ) {
    setSelValueField( e.target.value );

    // Find the new value field.
    const vf = findField( e.target.value );

    // Then the label field
    const lf = findField( selCategoryField );

    // Then update the columns for the table.
    // setColumns( [lf, vf] );
    setSelOptions( [] );
  }

  function setCatValues( e ) {
    let newCategories = categories.slice();
    const newCat = e.target.value;

    if ( categories.includes( newCat ) ) {
      // It's been selected again so remove it from the current list.
      newCategories = categories.filter( d => {
        return d !== newCat;
      } )
    } else {
      // (It's not part of the list so add it if we aren't at the max already );
      if ( categories.length < numBins ) {
        newCategories.push( newCat );
      }
    }

    setCategories( newCategories );
  }

  function findField( loc ) {
    let field = null 

    if ( data && selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ) {
      field = data[selDsId].fields.find( d => {
        return d.loc === loc;
      } )
    }

    return field;
  }

  function itemSelected( rd ) {
    let newOptions = selOptions.slice();

    // Check to see if this item was already selected.
    let idx = selOptions.findIndex( d => {
      return d.label === rd[selLabelField];
    })

    if ( idx >= 0 ) {
      // We already have this record so it needs to be removed.
      newOptions = [...selOptions.slice( 0, idx ), ...selOptions.slice( idx + 1 )];
    } else {
      // We don't have this record so add it to the list (if we have room ).
      const bin = getBin( rd[selCategoryField] );
      const binRecs = getSelBinRecs( bin );

      if ( binRecs.length < numBins ) {
        newOptions.push( { category: rd[selCategoryField], label: rd[selLabelField], value: rd[selValueField] } );
        newOptions.sort( ( a, b ) => {
          return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
        });
      }
    }

    setSelOptions( newOptions );
  }

  const sortRecs = ( recs, sortKey, dir ) => {
    let sorted = recs;

    if ( Array.isArray( recs ) && sortKey ) {
      // Filter any records that don't have a value
      sorted = recs.filter( d => {
        return getNodeValue( d, sortKey ) !== undefined;
      } )

      // Then sort them.
      sorted.sort( ( a, b ) => {
        let A = getNodeValue( a, sortKey )
        let B = getNodeValue( b, sortKey )

        let compare
        if ( A !== undefined && B !== undefined ) {
          compare = B < A ? 1 : B > A ? -1 : 0
        } else {
          compare = ( A === undefined && B === undefined ) ? 0 : A === undefined ? -1 : 1
        }

        return ( dir === SortDirection.ASC ) ? compare * ( -1 ) : compare
      } )
    }

    return sorted;
  }

  const sortRequested = ( { sortBy, sortDirection} ) => {
    
    let dir = sortDir === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC
    setSortDir( dir );
  }

  const columns = [selCategoryField, selLabelField, selValueField].reduce( ( ary, d ) => {
    if ( data && selDsId && data[selDsId] && Array.isArray( data[selDsId].fields )) {
      let field = data[selDsId].fields.find( f => {
        return f.loc === d;
      })

      if ( field ) {
        ary.push( field );
      }
    }

    return ary 
  }, [] );

  const recs = data && data[selDsId] && Array.isArray( data[selDsId].data ) ? data[selDsId].data : [];
  let sortedRecs = sortRecs( recs, selCategoryField, sortDir );

  const rowClassname = ( { index } ) => {
    let rcn = '';

    if ( index <= sortedRecs.length ) {
      let cVal = getNodeValue( sortedRecs[index], selCategoryField );
      let lVal = getNodeValue( sortedRecs[index], selLabelField );
      let vVal = getNodeValue( sortedRecs[index], selValueField );

      if ( cVal && lVal && vVal ) {

        let bin = getBin( cVal );
        rcn = bin >= 0 ? `gp-gds-bin${bin}` : '';

        let selected = selOptions.find( d => {
          return d.label === lVal;
        });

        if ( selected ) {
          rcn += ' gp-gds-sel'
        }
      }
    }

    return rcn;
  }

  const getBin = ( val ) => {

    return categories.findIndex( d => {
      return d === val;
    } );
  }

  function getSelBinRecs( bin ) {
   return selOptions.filter( d => {
      const recBin = getBin( d.category );

      return recBin === bin;
    } )
  }

  const displayBins = ( bin ) => {

    // Get the records for this bin.
    const binRecs = getSelBinRecs( bin );

    return (
      <View key={`bin${bin}`} marginBottom="large">
        {
          labels && labels[bin] ? (
            <View>
              {labels[bin]}
            </View>
          ) : (
            <View>
              {categories[bin]}
            </View>
          )
        }

        <table style={{width: "100%"}}>
          <thead>
            <tr>
              <th>Label</th>
              <th>Value</th>
            </tr>
          </thead>

          <tbody>
            {
              binRecs.map( ( d, i ) => {
                return (
                  <tr key={`p${i}`}>
                    <td>{d.label}</td>
                    <td style={{textAlign: "end"}}>{valueFormat ? formatNumber( valueFormat, d.value ) : d.value}</td>
                  </tr>
                )
              } )

            }
          </tbody>
        </table>
      </View>
    )
  }

  const catField = findField( selCategoryField );

  return (
    <Flex direction="column" alignItems="center"  gap="0px">

      <Button
        onClick={()=> setSelecting( true )}
        size="default"
      >
        Select Data
      </Button>

      <Modal
          isOpen={selecting}
          onRequestClose={closeSelector}
          style={customModalStyles}
          contentLabel="Game Editor"
      >
        <Flex direction="column" width="100%" height="100%">
          <Flex justifyContent="flex-end" marginBottom="small" marginRight="small">
            <Button onClick={closeSelector}>
              Cancel
            </Button>

            <Button variation="primary" onClick={saveUpdate} disabled={numOptions === undefined || selOptions.length < numOptions}>
              Save Data
            </Button>
          </Flex>

          <View className="ved-wksp">
            <View overflow="auto">
              <SelectField
                label="Dataset"
                value={selDsId}
                onChange={setDsId}
              >
                {
                  Object.keys( data ).length ? Object.keys( data ).map( d => {

                    return(
                      <option key={data[d].dsId} value={data[d].dsId}>
                        {data[d].name}
                      </option>
                    ) }
                  ) : null
                }

              </SelectField>

              <SelectField
                key={`cf-${selDsId}`}
                label="Category Field"
                value={selCategoryField}
                onChange={setCategoryField}
              >
                {
                  selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields.reduce( ( ary, d ) => {

                    if ( d.type === 'string' && !( d.valuesExceeded || d.valueLenExceeded ) ) {
                      ary.push(
                        <option key={d.loc} value={d.loc}>
                          {d.name}
                        </option>
                      )
                    }

                    return ary
                  }, [] ) : null
                }

              </SelectField>

              <SelectField
                key={`lf-${selDsId}`}
                label="Label Field"
                value={selLabelField}
                onChange={setLabelField}
              >
                {
                  selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields.reduce( ( ary, d ) => {

                    if ( d.type === 'string' ) {
                      ary.push(
                        <option key={d.loc} value={d.loc}>
                          {d.name}
                        </option>
                      )
                    }

                    return ary
                  }, [] ) : null
                }

              </SelectField>

              <SelectField
                key={`vf-${selDsId}`}
                key="ValueField"
                label="Value Field"
                value={selValueField}
                onChange={setValueField}
              >
                {
                  selDsId && data[selDsId] && Array.isArray( data[selDsId].fields ) ? data[selDsId].fields.reduce( ( ary, d ) => {

                    // Add all of the field types here.
                    ary.push(
                      <option key={d.loc} value={d.loc}>
                        {d.name}
                      </option>
                    )

                    return ary
                  }, [] ) : null
                }

              </SelectField>

              <SelectField
                key={`categories-${selDsId}`}
                label="Categories"
                value={categories}
                onChange={setCatValues}
                isMultiple={true}
                selectSize={4}
                options={catField && Array.isArray( catField.values ) ? catField.values : []}
              />

              <View marginTop="large">
                {
                  Array(numBins).fill('').map( ( d, i ) => {
                    return displayBins( i )
                  } )
                }
              </View>

            </View>

            <View marginLeft="small" grow={1} shrink={1}>
              <AutoSizer>
                {({height, width}) => (
                  <SelectTable 
                    key={`${selDsId}:${columns.map( d => { return d.loc })}:${sortDir}`}
                    width={width}
                    height={height}
                    recs={sortedRecs}
                    columns={columns}
                    selected={selOptions}
                    onSelect={itemSelected}
                    onSort={sortRequested}
                    sortBy={selValueField}
                    sortDir={sortDir}
                    rowClassname={rowClassname}
                  />
                )}
              </AutoSizer>
            </View>
          </View>
        </Flex>
      </Modal>
    </Flex>
  )
}