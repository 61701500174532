import React from 'react'
import { SelectField } from '@aws-amplify/ui-react';

export const Select = ( { options, ...remaining } ) => {

    return (
        <SelectField
            backgroundColor="white"
            labelHidden
            options={Array.isArray( options ) ? options : []}
            {...remaining}
        />
    )
}
