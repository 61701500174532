import React, { useContext } from 'react'
import { View } from "@aws-amplify/ui-react";

import { DataSetSelect } from './components'
import { VegaEditorContext } from '../../VegaEditorContext'

import { DataSet } from './DataSet'

require( './DataPanel.scss' )

// Manage the datasets for the chart.  The datasets are defined in a structure

export const DataPanel = () => {
  const { data, chartDatasets, updateChartData } = useContext( VegaEditorContext )
  const chartData = Array.isArray( chartDatasets ) ? chartDatasets : [];

  return (
    <View className="dp" >
      <View fontSize="large" >
        Data Sets
      </View>

      {
        !chartData.length ? (
          <DataSetSelect
            closeOnSelect={true}
            datasets={data}
            current={chartData}
            onSelect={updateChartData}
            onClose={() => {}}
          />
        ) :
          chartData.map( ( d ) => {
            return (
              <DataSet
                key={`ds${d}`}
                dataset={data[d] ? data[d] : {}}
              />
            )
          } )
      }
    </View>
  )
}

DataPanel.propTypes = {
  // datasets: PropTypes.object.isRequired,
  // onUpdate: PropTypes.func.isRequired
}
