import { useEffect, useState } from 'react';
import { fetchAuthSession } from "aws-amplify/auth";

export const useGroups = () => {
    const [groups, setGroups] = useState([]);

    useEffect(() => {

		try {
            fetchAuthSession()
            .then( authSession => {
                const { idToken } = authSession.tokens ?? {};
                const payload = idToken && idToken.payload ? idToken.payload : {};

                setGroups( payload['cognito:groups'] ? payload['cognito:groups'] : [] );
            } )
    
        } catch (error) {
            console.log( 'TOKEN ERROR:', error);
        }

	}, []);

    return [groups];
}