import React, { useContext } from 'react'
import { ButtonGroup, View } from "@aws-amplify/ui-react";

import { VegaEditorContext } from '../../VegaEditorContext'

// Order in this array dictates order in display
const chartTypes = ['area','bar','stackedbar','doughnut','line','scatter','beeswarm','pcoord','ushexstates']
const chartLabels = {
  area: 'Area',
  bar: 'Bar',
  line: 'Line',
  beeswarm: 'Bee Swarm',
  pcoord: 'Parallel Coords',
  scatter: 'Scatter',
  stackedbar: 'Stacked Bar',
  doughnut: 'Donut',
  ushexstates: "US Cartogram"
}

export const ChartType = () => {
  const { config, updateConfig } = useContext( VegaEditorContext )
  let chartType = config && config.type ? config.type : ''

  return (
    <View  className="cp-typ" >
      <View className="cp-typ-label" >
        Chart Type
      </View>

      <ButtonGroup wrap="wrap" className="cp-typ-grid">
        {
          chartTypes.map( d => {
            let btnStyles = {}

            if ( chartType === d ) {
              btnStyles.color = "#f47e39"
            }
              // borderColor: ididioColors[d],
              // backgroundColor: chartType === d ? ididioColors[d] : null,
            //   color: chartType === d ? ididioColors.orange : ididioColors[d]

            return (
              <button
                key={d}
                onClick={() => { updateConfig( { type: d } ) }}
                className="type-item spaced "
                aria-label={`Chart Type ${d}`}
                style={btnStyles}
              >
                <i className={`format cp-${d}`} />
                {chartLabels[d]}
              </button>
            )
          } )
        }
      </ButtonGroup>
    </View>
  )
}
