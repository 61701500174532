import React, {PropsWithChildren} from 'react';
require('./ConfirmModal.scss');

interface Props {
  onConfirm(): void;
  onDeny(): void;
}

export const ConfirmModal = ({
  onConfirm,
  onDeny,
  children,
}: PropsWithChildren<Props>) => (
  <div className={'dnd-confirmmodal'}>
    <h1>{children}</h1>
    <div>
      <button onClick={onConfirm}>Yes</button>
      <button onClick={onDeny}>No</button>
    </div>
  </div>
);
