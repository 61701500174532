import { getNodeValue } from './paths'

export function unique( myArray, config ) {
  let key = config ? config.path ? config.path : config.objectKey ? config.objectKey : null : null
  let newArray = []
  if ( key ) {
    myArray.reduce( ( curr, e ) => {
      let value = getNodeValue( e, key )
      if ( value !== undefined && curr[value] === undefined ) {
        newArray.push( e )
        curr[value] = true
      }
      return curr
    }, {} )
  } else {
    newArray = Array.from( myArray.reduce( ( curr, e ) => {
      return e !== undefined ? curr.add( e ) : curr
    }, new Set() ) )
  }
  return newArray
}

export function sort( array, path, reverse, numeric ) {
  if ( Array.isArray( array ) ) {
    array.sort( ( a, b ) => {

    } )
  }

  return array
}

// arraySort, sort the elements of an array using the specified options.
//
// Valid options include:
//   path - path to the element inside the Object (also accept objectKey due to legacy code)
//   reverse - reverse the sort order
//   numeric - use numeric values to sort
//   caseSensitive - use case insensitive values
//
export function arraySort ( array, opts )  {
  if ( Array.isArray( array ) ) {
    let key = opts ? opts.path ? opts.path : opts.objectKey ? opts.objectKey : null : null

    array.sort( ( a, b ) => {
      let A = key ? getNodeValue( a, key ) : a
      let B = key ? getNodeValue( b, key ) : b

      if ( opts.numeric ) {
        A = Number.parseFloat( A )
        B = Number.parseFloat( B )
      } else if ( opts && ( opts.caseSenstive === undefined || !opts.caseSensitive ) ) {
        A = typeof A  === 'string' ?  A.toUpperCase() : A
        B = typeof B  === 'string' ?  B.toUpperCase() : B
      }

      let compare = B < A ? 1 : B > A ? -1 : 0
      return ( opts && opts.reverse ) ? compare * ( -1 ) : compare
    } )
  }

  return array
}

// Convert an array into an object using the provided property as the key and value as the value.
export function arrayToObj( ary, prop, val ) {
  let obj = {}

  if ( Array.isArray( ary ) ) {
    obj = ary.reduce( ( o, d ) => {
      if ( d[prop] && d[val] ) {
        o[d[prop]] = d[val]
      }

      return o
    }, {} )
  }

  return obj
}

export function arraySum( arr1, arr2, args ) {
  // args could have subract: true, nullToZero: true
  let result
  if ( Array.isArray( arr1 ) && Array.isArray( arr2 ) && arr1.length === arr2.length ) {
    result = arr1.reduce( ( arr, v, i ) => {
      v = v || !args.nullToZero ? v  : 0
      let val = arr2[i] || !args.nullToZero ? arr2[i] : 0
      let sum = v !== undefined && arr2[i] !== undefined ? args.subtract ? v - arr2[i] : v + arr2[i] : null

      arr.push( sum )

      return arr
    }, [] )
  }
  return result
}

export function extents ( arr ) {
  let extents
  if ( Array.isArray( arr ) ) {
    extents = arr.reduce( ( obj, v ) => {
      if ( obj.max ) {
        obj.max = v > obj.max ? v : obj.max
      } else {
        obj.max = v
      }

      if ( obj.min) {
        obj.min = v < obj.max ? v : obj.max
      } else {
        obj.min= v
      }

      return obj

    }, {} )

  }

  return extents

}
