import { Flex, Loader } from '@aws-amplify/ui-react';

import { useGame, useUserStats } from '../../../hooks';
import { GameView } from './GameView';

export const GameWrapper = ( { gameId }) => {
    const [game] = useGame( gameId )
    const [userStats, setUserStats] = useUserStats( gameId );

    function setAnswer( gameStats ) {
        let update = {
            category: game.category,
            gameType: game && game.game && game.game.type ? game.game.type : 'none',
            answer: gameStats ? JSON.stringify( gameStats ) : gameStats
        }

        setUserStats( update );
    }

    return (
        <Flex
            direction="column"
            margin="0 auto"
            height="100%"
            backgroundColor="#fff"
            paddingTop="large"
        >
            {
                !userStats._fetching && game._loaded ? (
                    <GameView
                        game={game}
                        correct={userStats.correct}
                        gameStatus={userStats.answer}
                        onAnswer={setAnswer}
                    />
                ) : (
                    <Loader className="rdloader" />
                )
            }
        </Flex>
    )
}