import React from 'react'

import { ChartTitle } from './ChartTitle'
import { ChartType } from './ChartType'
import { ChartConfig } from './Charts'

require( './ConfigPanel.scss' )

export const ConfigPanel = ( props ) => {

  return (
    <div  className="cp" >

      <div className="cp-label" >
        Chart Configuration
      </div>

      <div className="cp-ctr">
        <ChartTitle />
        
        <ChartType />

        <ChartConfig />
      </div>
    </div>
  )
}
