import { $getNodeByKey, LexicalEditor, NodeKey } from "lexical";

import {
  $isChartNode,
  ChartNode,
} from "./ChartNode";

export const withChartNode = (
  nodeKey: NodeKey,
  editor: LexicalEditor,
  cb: (node: ChartNode) => void
): void => {
  editor.update(() => {
    const node = $getNodeByKey(nodeKey);
    if ($isChartNode(node)) {
      cb(node);
    }
  });
};
