import * as vega from 'vega'
import { genChartSpec, getVegaData, initFilterState } from './ChartSpecifications'

export async function genThumbnail( chartConfig, chartData ) {

  // Get the filter definitions if any exist.
  let filters = chartConfig && chartConfig.iflt ? chartConfig.iflt : undefined

  // Generate the VEGA specification
  const chartSpec = genChartSpec( chartConfig.spec, chartData )

  // Setup the default state for the filters.
  let filterState = initFilterState( filters, chartData )

  // Default values for filter data.
  let vegaData = getVegaData( chartConfig, chartSpec, chartData, filterState )

   // Now update the vega state data specifications to include the data
   // directly so we can generate only from the spec.
   if ( chartSpec && Array.isArray( chartSpec.data ) ) {
     chartSpec.data = chartSpec.data.map( d => {
       let newData = { ...d }

       // We have a dataset so assign it to the data.
       if ( newData.name && vegaData[newData.name] ) {
         newData.values = vegaData[newData.name].slice()
       }

       return newData
     } )
   }

   // Remove any legends that may have been specified
   chartSpec.legends = []

   // Turn off axis labels
   if ( chartSpec.axes ) {
    chartSpec.axes = chartSpec.axes.map( d => {
      let axis = { ...d }
      axis.labels = false
      return axis
    } )
  }

   // Set a default size for the chart.
   chartSpec.width = 300
   chartSpec.height = 300

   if ( chartSpec.signals ) {
    chartSpec.signals = chartSpec.signals.filter( d => {
      return d.name !== 'width' && d.name !== 'height'
    } )
  }

   // Now we can go ahead and generate the the image.
   const view = new vega.View( vega.parse( chartSpec ), { renderer: 'none', width: 300, height: 300 } )
   return await view.toCanvas()
}
