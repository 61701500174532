import { getDataset, getConfigField } from './utils'

const ididioColors = [
  '#FAAF40',   // apricot
  '#8BC53F',   // pear
  '#F05A28',   // orange
  '#21B0C6',   // turquoise
  '#983628',   // russet
  '#F0D543',   // yellow
  '#1DA596',   // teal
  '#F32831',   // red
  '#712A86',   // violet
  '#034694',   // blue
  '#189B66',   // green
  '#EF3F74',   // pink
]

export function addColorScale( spec, config, name ) {

  if ( spec && config ) {
    let colorField = getConfigField( config )

    if ( colorField ) {
      // Get the dataset which will add the dataset if it hasn't already
      // been added.
      getDataset( spec, colorField.datasetId )

      const colorName = name ? name : 'color'

      // We have a color field so add in the scale and any other required
      // components.

      let scaleSpec = {
        name: colorName,
        type: 'ordinal',
      }

      if ( Array.isArray( colorField.colors ) && Array.isArray( colorField.values ) ) {

        // We have a defined color map use that information to create the
        // color scale.
        scaleSpec.domain = colorField.values
        scaleSpec.range = colorField.colors

      } else if ( Array.isArray( colorField.values ) ) {

        // We are using a color scheme so setup the scale to use that.

        scaleSpec.domain = colorField.values
        
      } else {
        // We don't have a color array, or a values array so default for both.
        scaleSpec.domain = {
          data: colorField.datasetId,
          field: colorField.loc
        }
      }

      // A range hasn't been set so set one.
      if ( !scaleSpec.range ) {
        if ( config.scheme && config.scheme !== 'ididio' ) {
          scaleSpec.range = {
            scheme: config.scheme
          }
        } else {
          scaleSpec.range = ididioColors
        }  
      }

      spec.scales.push( scaleSpec )

      // Now setup the legend if it's requested (default is to show it)
      if ( !config.legend || ( config.legend && config.legend.show ) ) {

        let legend = {
          fill: colorName,
          title: config.label && config.label.title ? config.label.title : colorField.name ? colorField.name : colorField.loc,
          orient: window.innerWidth > 768 ? 'right' : 'bottom',
          direction: config.legend && config.legend.direction ? config.legend.direction : undefined,
          rowPadding: 5,
          columnPadding: 10,
          clipHeight: 0,
          padding: 10,
          encode: {
            symbols: {
              name: 'legendSymbol',
              enter: {
                fill: { scale: colorName, field: colorField.loc }
              }
            },
            labels: {
              name: 'legendLabel'
            }
          }
        }

        // if ( !config.legend || ( config.legend && config.legend.show && config.legend.interactive !== false ) ) {
        if ( !config.legend || ( config.legend && config.legend.show ) ) {

          // Now make the legend interactive if desired.
          spec.data.push(
            {
              name: 'selected',
              on: [
                { trigger: 'clear', remove: true },
                { trigger: '!shift', remove: true },
                { trigger: '!shift && clicked', insert: 'clicked' },
                { trigger: 'shift && clicked', toggle: 'clicked' }
              ]
            }
          )

          spec.signals.push(
            {
              name: 'clear',
              value: true,
              on: [
                {
                  events: 'mouseup[!event.item]',
                  update: 'true',
                  force: true
                }
              ]
            }
          )

          spec.signals.push(
            {
              name: 'shift',
              value: false,
              on: [
                {
                  events: '@legendSymbol:click, @legendLabel:click',
                  update: 'event.shiftKey',
                  force:  true
                }
              ]
            }
          )

          spec.signals.push(
            {
              name: 'clicked',
              value: null,
              on: [
                {
                  events: '@legendSymbol:click, @legendLabel:click',
                  update: '{ value: datum.value }',
                  force:  true
                }
              ]
            }
          )

          // Add the interactity to the legend...
          legend.encode.symbols.interactive = true
          legend.encode.symbols.update = {
            opacity: [
              { test: "!length(data('selected')) || indata('selected', 'value', datum.value)", value: 1.0 },
              { value: 0.05}
            ]
          }

          legend.encode.labels.interactive = true
          legend.encode.labels.update = {
            opacity: [
              { test: "!length(data('selected')) || indata('selected', 'value', datum.value)", value: 1.0 },
              { value: 0.05}
            ]
          }

        }

        // Now add the defined legend to the spec.
        spec.legends.push( legend )
      }
    }
  }

  return spec
}
