import { useState } from 'react'
import Modal from "react-modal";
import { Button, Flex, View } from "@aws-amplify/ui-react";

import { QuestionView } from '../../components';

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // width: "90%",
    // height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    padding: "0px"
  },
  overlay: {
      background: "#4c4d4e",
      zIndex: 100
  }
};

export const GamePreview = ( { game } ) => {
  const [previewing, setPreviewing] = useState( false );
  const [previewAnswer, setPreviewAnswer] = useState( null );
  
  function setAnswer( answer ) {
    console.log( 'ANSWER:', answer )
    setPreviewAnswer( answer );
  }

  function onClose() {
    setPreviewing( false );
    setPreviewAnswer( null );
  }

  return (
    <Flex direction="column" alignItems="center"  gap="0px">

      <Button
        onClick={()=> setPreviewing( true )}
        size="default"
      >
        Preview
      </Button>

      <Modal
          isOpen={previewing}
          onRequestClose={onClose}
          style={customModalStyles}
          contentLabel="Question Preview"
      >

        <View maxWidth="375px" maxHeight="677px" overflow="auto">
          <QuestionView game={game} answer={previewAnswer} onAnswer={setAnswer} {...previewAnswer} />
        </View>

        <Flex padding="small" justifyContent="center" backgroundColor="gray">
          <Button onClick={onClose} backgroundColor="white">Done</Button>
        </Flex>

      </Modal>
    </Flex>
  )
}