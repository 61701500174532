import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { View } from "@aws-amplify/ui-react";
import { FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC } from '../../../../../../../../hooks'

import { VegaEditorContext } from '../../../../VegaEditorContext'
import { ConfigGroup } from './ConfigGroup'
import { FilterItem } from './FilterItem'
import { dropBackgroundStyle } from './DropField'

const accept = [FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC]

// Generates interactive filter configurations stored in the configuration
// based on the provided prop.
//

export const InteractiveFilter = ( { prop } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const filtFields = Array.isArray( config[prop] ) ? config[prop].map( d => {
    return d.id ? d.id : d.field.loc
  } ) : []

  // Handle drop of fields to create tooltip item.
  const [ { isOver, canDrop }, drop ] = useDrop( {
    accept,
    canDrop: ( item ) => { return !filtFields.includes( item.loc ) },
    drop: ( item ) => {

      // A new field has been dropped so add it to the list of fields.
      let newFlt = config && Array.isArray( config[prop] ) ? [...config[prop]] : []

      newFlt.push( {
        id: item.loc,
        field: item
      } )

      updateConfig( { [prop]: newFlt } )
    },
    collect: (monitor) => ( {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    } ),
  } )

  function removeItem ( idx ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length ) {
      let newFlt = [...config[prop].slice( 0, idx ), ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newFlt } )
    }
  }

  function updateItem ( idx, item ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length && item ) {
      let newFlt = [...config[prop].slice( 0, idx ), item, ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newFlt } )
    }
  }

  function moveItem ( from, to ) {
    if ( Array.isArray( config[prop] ) ) {
      let flti = config[prop][from]
      let rmTt = [...config[prop].slice( 0, from ), ...config[prop].slice( from + 1 )]
      let newFlt = [...rmTt.slice( 0, to ), flti, ...rmTt.slice( to )]

      updateConfig( { [prop]: newFlt } )
    }
  }

  const dzClasses = Array.isArray( config[prop] ) && !config[prop].length ? 'cp-mf-dz cp-cfg-drp-fld-dflt' : 'cp-mf-dz'
  const dropTypes = accept.join( ' ' )

  return (
    <ConfigGroup label="Interactive Filters">
      <View className="cp-tt">
        <View
          ref={drop}
          className={dzClasses}
          style={dropBackgroundStyle( isOver, canDrop )}
        >
          {
            Array.isArray( config[prop] ) && config[prop].length ? (
              config[prop].map( ( d, i )=> {
                return (
                  <FilterItem
                    key={d.id}
                    item={d}
                    idx={i}
                    onRemove={removeItem}
                    onUpdate={updateItem}
                    onMove={moveItem}
                  />
                )
              } )
            ) : (
              <div>
                Drop Filter fields here!
                <br />
                {`Accepts: ${dropTypes}`}
              </div>
            )
          }
        </View>
      </View>
    </ConfigGroup>
  )
}


InteractiveFilter.propTypes = {
  prop: PropTypes.string.isRequired
}
