import React, { useContext, useState } from 'react'
import { Button, Flex, View } from "@aws-amplify/ui-react";

import { VegaEditorContext } from '../../../../VegaEditorContext';

import { DataSetItem } from './DataSetItem';
import { DataSetAdd } from './DataSetAdd';

export const DataSetSelect = ( { datasets, current, onSelect, onClose, closeOnSelect } ) => {
  const { data, updateData } = useContext( VegaEditorContext )
  const [adding, setAdding] = useState( false );
  let component = null;

  function onDsSelect( ds ) {
    onSelect( [ds.dsId] )
    closeOnSelect && onClose && onClose()
  }

  function addDataset( ds ) {
    if ( ds.dsId ) {
      let newData = { ...data }
      newData[ds.dsId] = ds
      updateData( newData )
    }

    setAdding( false );
  }

  function removeDataset( ds ) {

    if ( ds.dsId ) {
      let newData = Object.keys( datasets ).reduce( ( obj, d ) => {
        if ( ds.dsId !== d ) {
          obj[d] = datasets[d];
        }
        return obj
      }, {} );

      updateData( newData );
    }
  }

  if ( adding ) {
    component = (
      <DataSetAdd
        onClose={()=>{setAdding(false)}}
        onAdd={addDataset}
      />
    )
  } else {
    component = (
      <Flex direction="column" grow={1} shrink={1} overflow="hidden"  className="dp-sel" >
        <Flex justifyContent="space-between" alignItems="center" margin="medium 0px" className="dp-sel-label-cont" >
          <View className="dp-sel-label" >
            Select a dataset for your chart
          </View>

          <Button onClick={() => { setAdding( true )}}>
            Add Dataset
          </Button>

          <Button onClick={onClose}>
            Cancel
          </Button>
        </Flex>

        <View grow={1} shrink={1} overflow="auto" className="dp-sel-list">
          {
            Object.keys( datasets ).map( ( d, i ) => {
             
              return (
                <DataSetItem
                  key={`dss${i}`}
                  dataset={datasets[d]}
                  onUpdate={onDsSelect}
                  onDelete={removeDataset}
                  selected={current.includes( datasets[d].dsId )}
                />
              )
            } )
          }
        </View>

      </Flex>
    )
  }

  return component
}
