import { Heading, View } from "@aws-amplify/ui-react";

export const PrivacyPage = () => {

    return (
        <View padding="30px" maxWidth="992px" margin="0 auto">
            
            <Heading level={1} paddingLeft="large">Privacy Policy</Heading>

            <View padding="large">
                <p>
                    Welcome to ripedata.com, a web site operated by Ripe Data, LLC, a limited liability company with offices located at 
                    4029 Autumn Court, Fairfax, Virginia 22030 (the "Site"). This document explains the privacy practices of our 
                    Site (the "Privacy Policy"). This Privacy Policy is an integral part of our Terms of Service under which you may use 
                    this Site (collectively, the “Agreement”). By using our Site, you consent to this Privacy Policy, as well as any new 
                    version of it posted since your last visit. If this Privacy Policy is not acceptable, then please do not use our Site. 
                    This Privacy Policy was last updated on: January 3th, 2022.
                </p>

                <p>
                    Our Site is committed to protecting the privacy of individuals who use our Site and the security of data which users may 
                    enter into our Site.
                </p>

                <p>
                    At the present time, our Site does not collect personally identifiable information (“PII”) from you. We do not collect 
                    any payment card information (“PCI”) from you. The only information about you that we obtain is the information that 
                    you choose to transmit to us voluntarily. If you enter your email address into our Site as your username, then we will 
                    use your email address to administer your use of the Site features and to contact you about the Site. We do not sell, 
                    rent, trade or distribute any PII or PCI related data to third parties.  You can delete any preferences or favorites 
                    you have stored by deleting your account.
                </p>

                <p>
                    Deleting your account will remove all of the information associated with the account.  You can delete your account by 
                    selecting the 'delete my account' button on the profile page.
                </p>

                <p>
                    We currently do not process payments at the Site and therefore we do not collect any PCI data. If we begin collecting 
                    payments for certain Generated Content or subscription-based accounts in the future, we will update this Privacy Policy 
                    at that time.
                </p>

                <p>
                    Our Site uses “cookies” to allow us to recognize you as a returning user in order to avoid your having to re-enter 
                    information into our Site each time you visit. Cookies are small pieces of code placed on your computer that collects 
                    Site usage data on an anonymous basis We also use Google Analytics on our Site to help us understand how visitors use 
                    the features of our Site. Google Analytics uses cookies, too. We use cookies to help us improve your experience on the 
                    Site and our Site offerings. For more information on how Google Analytics handles data, please 
                    visit: <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a>
                </p>

                <p>
                    Our Site may contain direct links and advertising links to other websites. We do not control the privacy practices or 
                    policies of other websites, so please review the privacy policy of each website you visit.
                </p>

                <p>
                    Our Site does not offer content directed to, and is not to be used by children under the age of 13. 
                </p>
            </View>
        </View>
    )
}