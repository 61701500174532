import type {
    EditorConfig,
    ElementFormatType,
    LexicalEditor,
    LexicalNode,
    NodeKey,
    Spread,
  } from "lexical";
  import { $getNodeByKey } from "lexical";

  import {
    DecoratorBlockNode,
    SerializedDecoratorBlockNode
  } from "@lexical/react/LexicalDecoratorBlockNode";
  import * as React from "react";
  import { LexicalChart } from "./LexicalChart"
 
  // type ChartComponentProps = Readonly<{
  //   className: Readonly<{
  //     base: string;
  //     focus: string;
  //   }>;
  //   format: ElementFormatType | null;
  //   nodeKey: NodeKey;
  //   chartId: string;
  //   chartSpec: string;
  // }>;
  
  // function ChartComponent({
  //   className,
  //   format,
  //   nodeKey,
  //   chartId,
  //   chartSpec
  // }: ChartComponentProps) {

  //   return (
  //     <BlockWithAlignableContents
  //       className={className}
  //       format={format}
  //       nodeKey={nodeKey}
  //     >

  //       <LexicalChart
  //         chartId={chartId}
  //         chartSpec={chartSpec}
  //       />

  //     </BlockWithAlignableContents>
  //   );
  // }
  
  export type SerializedChartNode = Spread<
    {
      chartId: string;
      type: "chart";
      version: 1;
    },
    SerializedDecoratorBlockNode
  >;
  
  export class ChartNode extends DecoratorBlockNode {
    __id: string;
  
    static getType() {
      return "chart";
    }
  
    static clone(node: ChartNode): ChartNode {
      return new ChartNode(node.__id, node.__format, node.__key);
    }

    constructor(id: string, format?: ElementFormatType, key?: NodeKey ) {
      super(format, key);
      this.__id = id;
    }
  
    static importJSON(serializedNode: SerializedChartNode): ChartNode {
      const node = $createChartNode(serializedNode.chartId);
      node.setFormat(serializedNode.format);
      return node;
    }
  
    exportJSON(): SerializedChartNode {
      return {
        ...super.exportJSON(),
        type: "chart",
        version: 1,
        chartId: this.__id
      };
    }
  
    updateDOM(): false {
      return false;
    }
   
    // getTextContent(
    //   _includeInert?: boolean | undefined,
    //   _includeDirectionless?: false | undefined
    // ): string {
    //   console.log( 'GETTING TEXT:', this )
    //   return `chart_${this.__id}`;
    // }
 
    decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
      const embedBlockTheme = config.theme.embedBlock || {};
      const className = {
        base: embedBlockTheme.base || "",
        focus: embedBlockTheme.focus || ""
      };
      return (
        <LexicalChart
          className={className}
          format={this.__format}
          nodeKey={this.getKey()}
          chartId={this.__id}
        />
      );
    }
  
    isInline(): false {
      return false;
    }
  }
  
  export function $createChartNode(chartId: string): ChartNode {
    return new ChartNode(chartId);
  }
  
  export function $isChartNode(
    node: ChartNode | LexicalNode | null | undefined
  ): node is ChartNode {
    return node instanceof ChartNode;
  }
  