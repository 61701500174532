import React, { useContext } from 'react'

import { VegaEditorContext } from '../../../VegaEditorContext'
import {
  CategoricalAxisConfig,
  ColorConfig,
  InteractiveFilter,
  NumericAxisConfig,
  SizeConfig,
  TooltipConfig,
  useValidateFields
} from './components'

import { getDataId } from './configUtils'

import {
  clrDefaultMarkFill,
  clrDefaultMarkStroke,
  clrDefaultHoverFill,
  clrDefaultHoverStroke,
} from './defaultValues'

const fieldProps = ['b', 'v', 'color', 'tt', 'iflt']

// Assign labels for each of the items we can configure with color.
const colorTypes = [
  {
    val: 'mark',
    label: 'Dots'
  },
  {
    val: 'hover',
    label: 'Hover'
  }
]

export const defaultBeeSwarm = {
  b: {
    domain: {
      show: true
    },
    grid: {
      show: false
    },
    ticks: {
      show: false
    },
    label: {
      angle: -45,
      align: 'right'
    }
  },
  v: {
    domain: {
      show: true
    },
    grid: {
      show: true
    },
    ticks: {
      show: true
    }
  },
  symbol: {
    size: 8
  },
  color: {
    mark: {
      stroke: clrDefaultMarkStroke,
      fill: clrDefaultMarkFill
    },
    hover: {
      stroke: clrDefaultHoverStroke,
      fill: clrDefaultHoverFill
    }
  }
}

export const BeeSwarmConfig = () => {
  const { config, updateConfig } = useContext( VegaEditorContext )
  const curDataId = getDataId( config, fieldProps )

  useValidateFields( fieldProps )

  // let size = config && config.symbol && config.symbol.size ? config.symbol.size : 8

  // function updateSize( val ) {
  //   let newSymbol = config.symbol ? { ...config.symbol } : {}
  //   newSymbol.size = val
  //   updateConfig( { symbol: newSymbol } )
  // }

  // Perform a conversion for the old style colors being just a field definition.
  let colorValue = config.color && config.color.datasetId ? { field: config.color } : config.color ? config.color : {}

  return (
    <div  className="cp-ctls" >

      <CategoricalAxisConfig
        prop="b"
        label="Bins"
        defaultProps={defaultBeeSwarm}
        validDataset={curDataId}
      />

      <NumericAxisConfig
        prop="v"
        label="Value"
        defaultProps={defaultBeeSwarm}
        validDataset={curDataId}
      />

      <ColorConfig
        isDynamic
        validDataset={curDataId}
        staticTypes={colorTypes}
      />

      <SizeConfig validDataset={curDataId} />

      <TooltipConfig prop="tt" />

      <InteractiveFilter prop="iflt" />
    </div>
  )
}
