import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View } from "@aws-amplify/ui-react";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";

export const Panel = ( { label, children } ) => {
  const [isOpen, setIsOpen] = useState( false )

  return (
    <View className="gp-pnl" marginTop="large" >
      <View
        width="16px"
        height="16px"
        margin="xs"
        right="0"
        style={{cursor: "pointer"}}
        onClick={() => { setIsOpen( !isOpen ) }}
      >
        {
          isOpen ? (
            <BsChevronDown size={16} />
          ) : (
            <BsChevronRight size={16} />
          )
        }
      </View>

      <View className="gp-pnl-ctr" >
        <View
          grow={1}
          shrink={1}
          marginBottom="xs"
          className="gp-pnl-lbl"
          onClick={() => { setIsOpen( !isOpen ) }}
        >
          {label}
        </View>

        { isOpen ? children : null }
      </View>
    </View>
  )
}