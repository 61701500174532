import React from 'react';

require('./GridContainer.scss');

export interface Props {
  children: React.ReactNode;
  columns: number;
}

export function GridContainer({children, columns}: Props) {
  return (
    <ul
      className={'dnd-gridcontainer'}
      style={
        {
          '--col-count': columns,
        } as React.CSSProperties
      }
    >
      {children}
    </ul>
  );
}
