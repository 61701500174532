'use strict'
import { timeFormat } from 'd3-time-format'

export function formatDate( template, val, defaultVal ) {
  let formatted = val

  if ( template ) {

    if ( typeof val === 'number' ) {
      formatted = doFormat( template, val )
    } else if ( typeof defaultVal === 'number' ) {
      formatted = doFormat( template, defaultVal )
    } else if ( typeof defaultVal === 'string' ) {
      formatted = defaultVal
    }
  } else {
    formatted = val.toString()
  }

  return formatted
}

function doFormat( template, val ) {
  let fmt = timeFormat( template )
  return fmt( val )
}
