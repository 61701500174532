import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'

import { VegaEditorContext } from '../../../../VegaEditorContext'
import { FIELDTYPE_TEXT, FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC } from '../../../../../../../../hooks'
import { dropBackgroundStyle } from './DropField'
import { ConfigGroup } from './ConfigGroup'
import { TooltipItem } from './TooltipItem'

const accept = [FIELDTYPE_TEXT, FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC]

// Generates tooltip configurations stored in the configuration based on the
// provided prop.
//
// A tooltip configuration is an array of tooltip item structures which look
// like this:
// {
//    id: <a unique identifier (currently the field location>
//    field: <A field structure defining the source of information>
//    format: <The format to be used if it's a numeric field>
//    label: <The label to be used for this item in the tooltip, it defaults
//            to the field name>
// }

export const TooltipConfig = ( { prop } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const ttFields = Array.isArray( config[prop] ) ? config[prop].map( d => {
    return d.id ? d.id : d.field.loc
  } ) : []

  // Handle drop of fields to create tooltip item.
  const [ { isOver, canDrop }, drop ] = useDrop( {
    accept,
    canDrop: ( item ) => { return !ttFields.includes( item.loc ) },
    drop: ( item ) => {

      // A new field has been dropped so add it to the list of fields.
      let newTt = config && Array.isArray( config[prop] ) ? [...config[prop]] : []

      newTt.push( {
        id: item.loc,
        field: item
      } )

      updateConfig( { [prop]: newTt } )
    },
    collect: (monitor) => ( {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    } ),
  } )

  function removeItem ( idx ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length ) {
      let newTt = [...config[prop].slice( 0, idx ), ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newTt } )
    }
  }

  function updateItem ( idx, item ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length && item ) {
      let newTt = [...config[prop].slice( 0, idx ), item, ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newTt } )
    }
  }

  function moveItem ( from, to ) {
    if ( Array.isArray( config[prop] ) ) {
      let tti = config[prop][from]
      let rmTt = [...config[prop].slice( 0, from ), ...config[prop].slice( from + 1 )]
      let newTt = [...rmTt.slice( 0, to ), tti, ...rmTt.slice( to )]

      updateConfig( { [prop]: newTt } )
    }
  }

  const dzClasses = Array.isArray( config[prop] ) && !config[prop].length ? 'cp-mf-dz cp-cfg-drp-fld-dflt' : 'cp-mf-dz'
  const dropTypes = accept.join( ' ' )

  return (
    <ConfigGroup label="Tooltip">
      <div className="cp-tt">
        <div
          ref={drop}
          className={dzClasses}
          style={dropBackgroundStyle( isOver, canDrop )}
        >
          {
            Array.isArray( config[prop] ) && config[prop].length ? (
              config[prop].map( ( d, i )=> {
                return (
                  <TooltipItem
                    key={d.id}
                    item={d}
                    idx={i}
                    onRemove={removeItem}
                    onUpdate={updateItem}
                    onMove={moveItem}
                  />
                )
              } )
            ) : (
              <div>
                Drop Tooltip fields here!
                <br />
                {`Accepts: ${dropTypes}`}
              </div>
            )
          }
        </div>
      </div>
    </ConfigGroup>
  )
}


TooltipConfig.propTypes = {
  prop: PropTypes.string.isRequired
}
