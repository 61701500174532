import React from 'react'
import PropTypes from 'prop-types'
import { Flex, View } from "@aws-amplify/ui-react"

import { TextInput } from '../../../../../../Controls'
import { fieldType } from '../../DataField'
import { Sortable } from '../../../../../../dnd'

export const FieldEditor = ( { field, onUpdate } ) => { 
    const loc = field && field.loc ? field.loc : ''
    const name = field && field.name ? field.name : ''
    const description = field && field.name ? field.name : ''
    const values = field && Array.isArray( field.values ) ? field.values : []

    const typeValue = fieldType( field )

    const fieldValues = values.map( d => {
        return {
            id: d,
            label: d,
            data: null
        }
    })

    return (
        <Flex direction="column" padding="large" gap="5px" overflow="hidden" justifyContent="stretch">
            <Flex justifyContent="space-between" grow={0} shrink={0}>
                <Flex>
                    <View>Path:</View>
                    <View>{loc}</View>
                </Flex>

                <Flex>
                    <View>Type:</View>
                    <View>{typeValue.ftype}</View>
                </Flex>

            </Flex>

            <View grow={0} shrink={0}>
                <TextInput
                    className="cp-xs-prp"
                    label="Name"
                    value={name}
                    onUpdate={( val ) => { onUpdate( { name: val } ) }}
                />
            </View>

            <View grow={1} shrink={1} overflow="auto" height="350px">
                <Sortable
                    items={fieldValues}
                    onChange={( items ) => { onUpdate( { values: items.map( d => { return d.id }) } ) }}
                />
            </View>

        </Flex>
    )
}

FieldEditor.propTypes = {
  field: PropTypes.object.isRequired
}