import { getNodeValue } from './paths'
import { formatNumber } from './format'

const keepCapArr = ['english', 'french', 'spanish', 'german', 'african', 'latin', 'american', 'european', 'eastern', 'pacific', 'scandinavian', 'asian', 'european', 'canadian', 'balkans', 'baltic', 'slavic', 'u.', 's.', 'ural', 'altaic', 'caribbean', 'italian', 'japanese', 'korean', 'polish', 'iberian', 'tibetan', 'hispanic', 'indian', 'puerto', 'rican', 'mexican', 'chicano', 'irish', 'ukraine', 'native', 'east', 'bosnian', 'polish', 'czech', 'albanian', 'bulgarian', 'serbian', 'croatian', 'ukrainian', 'danish', 'dutch', 'flemish', 'norwegian', 'swedish', 'south', 'greek', 'hindi', 'sanskrit', 'bengali', 'punjabi', 'tamil', 'urdu', 'iranian', 'portugese', 'romanian', 'near', 'semitic', 'arabic', 'hebrew', 'celtic', 'australian', 'oceanic', 'indonesian', 'malay', 'burmese', 'filipino', 'tagalog', 'khmer', 'cambodian', 'lao', 'thai', 'vienamese', 'australian', 'southesast', 'turkish', 'uralic', 'hungarian', 'magyar', 'mongolian', 'turkic', 'uralic', 'altaic', 'caucaisian', 'west', 'western', 'eastern']
const keepCapSet = new Set( keepCapArr )

export function Capitalize( str ) {
  let capitalized

  if ( typeof str === 'string' ) {
    capitalized = str.replace( /\w\S*/g, function( txt ) {
      return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase()
    } )
  }

  return capitalized
}


export function sentenceCase( str ) {
  //CHOSE to make this utilty also handle bad spacing
  str = str.trim()
  let arr = str.split( ' ' )
  arr[0] = Capitalize( arr[0] )
  return arr.join( ' ' )
}

let varRe = new RegExp( /\$\{\s*([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*\}/g )
// let formatRe = new RegExp( /\$\{\s*formatNumber\(\s*"([0-9\,\.\~\$\%mf]*)"\s*,\s*([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*\)\s*\}/g )
let formatRe = new RegExp( /\$\{\s*formatNumber\(\s*"([0-9\,\.\~\$\%mf]*)"\s*,\s*(([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)|([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*,\s*"([0-9a-zA-Z\.\s]*)")\s*\)\s*\}/g )
let lookupRe = new RegExp( /\$\{\s*lookup\(\s*([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*,\s*([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*\)\s*\}/g )
let ptileRe = new RegExp( /\$\{\s*formatPtile\(\s*([a-zA-Z_][a-zA-Z0-9_\.\[\*\]]*)\s*\)\s*\}/g )

export function renderTemplateString( template, context, lookup ) {
  let string = template

  // Replace formatted values
  string = string.replace( formatRe, function( match, fmt, v1, v2, v3, v4, offset, orig ) {
    let replacement = getNodeValue( context, v2 ? v2 : v3 )
    let defVal = v4 ? v4 : v1
    return formatNumber( fmt, replacement, defVal )
  } )

  // Replace ptile format values{
  string = string.replace( ptileRe, function( match, val, offset, orig ) {
    let rep = getNodeValue( context, val )
    return rep ? formatPtile( rep ) : val
  } )

  // Replace lookup values
  if ( lookup ) {
    string = string.replace( lookupRe, function( match, key, val, offset, orig ) {
      let rep = getNodeValue( context, val )
      return rep !== undefined && lookup[key] ? lookup[key][rep] ? lookup[key][rep] : rep : val
    } )
  }

  string = string.replace( varRe, function( match, val, offset, orig ) {
    let replacement = getNodeValue( context, val )
    return replacement !== undefined ? replacement : val
  } )

  return string;
}

export function lowerCaseField( str ) {
  let strArr = str.split( ' ' )

  strArr = strArr.map( word => {
    word = word.toLowerCase()
    let checkWord = word.split( '-' )
    return keepCapSet.has( charNumOnly( checkWord[0] ) ) ? Capitalize( word ) : word
  } )
  return strArr.join( ' ' ).replace( /middle East/i, 'Middle East' ).replace( /us department of eduction/i, 'US Department of Education' )
}

export function charNumOnly( c ) {
  return c.replace( /[^A-Za-z0-9_]/g,'' );
}

export function formatPtile( num ) {
  num = Math.round( num )
  num = num === 100 ? 99 : num
  let str = num.toString()
  let dig = str[str.length - 1]
  let dig1 = str.length > 1 ? str[0] : null
  let output = ''
  switch ( dig ) {
  case '1':
    output = dig1 && dig1 === '1' ? `${str}th` : `${str}st`
    break

  case '2':
    output = dig1 && dig1 === '1' ? `${str}th` : `${str}nd`
    break

  case '3':
    output = dig1 && dig1 === '1' ? `${str}th` :`${str}rd`
    break

  default:
    output = `${str}th`
    break
  }

  return output
}

export function formatList( arr ) {
  arr = Array.isArray( arr ) ? arr : []
  let len = arr.length
  let str
  switch ( len ) {
  case 0:
    str = null
    break

  case 1:
    str = arr[0]
    break

  case 2:
    str = `${arr[0]} and ${arr[1]}`
    break

  default:
    str = arr.reduce( ( s, v, i ) => {
      return i < len  - 2 ? s.concat( `${v}, ` ) : i === len - 2 ?  s.concat( `${v}, and ` ) : s.concat( v )
    }, '' )
  }
  return str
}
