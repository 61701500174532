import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex, View } from "@aws-amplify/ui-react";

import { NavButtons, GameWrapper } from '../../components';
import { useArchive } from '../../hooks';


export const ArchivePage = () => {
    const navigation = useNavigate();
    const { gameId } = useParams();
    const [navPointers, setNavPointers] = useArchive( gameId );

    function onNavigate( newGame ) {
        // Update the naviation pointers
        setNavPointers( newGame );

        // Update the url to reflect the new game.
        navigation( `/archive/${newGame}` );
    }

    useEffect(() => {
 
        // If a game wasn't specified but we have the navigation pointers 
        // loaded navigate to whatever is set as the current.
        if ( !gameId && navPointers.current ) {
            navigation( `/archive/${navPointers.current}` );
        }
    }, [gameId, navPointers.current] );

    return (
        <View margin="large" grow={1} shrink={1}>

            <Flex
                direction="column"
                maxWidth="600px"
                width="100%"
                margin="0 auto"
                height="100%"
                backgroundColor="#fff"
                paddingTop="large"
            >

                <NavButtons
                    first={navPointers._loaded ? navPointers.first : null}
                    previous={navPointers._loaded ? navPointers.previous : null}
                    random={navPointers._loaded ? navPointers.random : null}
                    next={navPointers._loaded ? navPointers.next : null}
                    latest={navPointers._loaded ? navPointers.latest : null}
                    onSelect={onNavigate}
                />

                <GameWrapper gameId={gameId} />

                <NavButtons
                    first={navPointers._loaded ? navPointers.first : null}
                    previous={navPointers._loaded ? navPointers.previous : null}
                    random={navPointers._loaded ? navPointers.random : null}
                    next={navPointers._loaded ? navPointers.next : null}
                    latest={navPointers._loaded ? navPointers.latest : null}
                    onSelect={onNavigate}
                />
            </Flex>
        </View>
    )
}