import { Flex, Link, View } from "@aws-amplify/ui-react";

export const ShortFooter = () => {

    return (
        <Flex
            backgroundColor="#4c4d4e"
            color="font.inverse"
            alignItems="center"
            fontSize="12px"
            paddingLeft="large"
            width="100%"
            padding="xxxs"
        >
            <Link
                href="/about"
                color="font.inverse"
                paddingLeft="large"
            >
                About
            </Link >

            <Link
                href="/privacy"
                color="font.inverse"
            >
                Privacy
            </Link>

            <Link
                href="/tos"
                color="font.inverse"
            >
                Terms of Service
            </Link>

            <View grow={1} shrink={1} />

            <View fontSize="8px" paddingRight="large">
                Site design / logo © 2023 RipeData LLC
            </View>
        </Flex>
    )
}