import { Flex } from "@aws-amplify/ui-react";
import DatePicker from 'react-date-picker';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

export const GamePublish = ( { game, onChange } ) => {

  function onDateSelect( date ) {
    if ( date ) {
      const dateString = JSON.stringify( date );
      const ymdStr = dateString.slice( 1, 11 );

      // FIXME
      // For now we are just pushing the date into the published table so we can continue with testing.
      // We will need to setup a chron job to get the current date from the games and publish 
      // any where the date has passed the current date.


      onChange && onChange( ymdStr )
    }
  }

  const pubDate = game.published ? `${game.published}EST` : null;

  return (
    <Flex style={{zIndex: 1}} direction="column" justifyContent="center">
      <DatePicker
        calendarAriaLabel="Toggle calendar"
        clearAriaLabel="Clear value"
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        nativeInputAriaLabel="Date"
        onChange={onDateSelect}

        value={pubDate}
        shouldCloseCalendar={(props) => { return true }}
        yearAriaLabel="Year"
      />
    </Flex>
  )
}