import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clone from "clone";
import { Button, Flex, Loader, View } from "@aws-amplify/ui-react";

import { Game } from "../Game"
import { ConfigPanel } from "./components";
import { GameConfigContext } from "./GameConfigContext";

require( "./GameConfig.scss" );

export const GameConfig = ( { game, datasets, onClose, onSave } ) => {
  const [savingChanges, setSavingChanges] = useState( false );
  const [editConfig, setEditConfig] = useState( game ? clone( game ) : {} );
  const [editDatasets, setEditDatasets] = useState( datasets ? { ...datasets } : {} );

  function saveGame() {

    // Indicate we are saving the changes
    setSavingChanges( true );

    // Update both the chart spec and datasets for the chart.
    onSave( editConfig );
  }

  useEffect( () => {
    if ( savingChanges ) {
      // We are currently saving changes and the thumbnail has been saved so
      // go ahead and close the dialog.
      setSavingChanges( false );
      onClose();
    }

  }, [savingChanges] );

  // Update a property in the chart configuration
  function onConfigUpdate( updates ) {
    if ( updates ) {
      let newConfig = {};

      if ( updates.type && updates.type !== editConfig.type ) {

        // We are changing the type so remove the current configuration and
        // use only the description and updates as a starting point.
        if ( editConfig.description ) {

          // Save the description even if we change the type.
          newConfig.description = editConfig.description;
        }

        newConfig = { ...newConfig, ...updates };
      } else {

        // If we aren't chaning the type then clone the existing configuration.
        newConfig = { ...editConfig, ...updates };
      }

      setEditConfig( newConfig );
    }
  }

  // Update the datasets
  function onDatasetUpdate( update ) {

    // We have an array of updates so proceess each one.
    if ( update ) {

      // Update the data for display
      setEditDatasets( update );
    }
  }

  const geContext = {
    config: clone( editConfig ),
    updateConfig: onConfigUpdate,
    // gameDatasets: editChartDatasets,
    // updateChartData: onChartDatasetUpdate,
    data: editDatasets,
    updateData: onDatasetUpdate
  };

  // let chartData = editChartDatasets.reduce( ( obj, d ) => {
  //   if ( editDatasets[d] ) {
  //     obj[d] = editDatasets[d];
  //   }
  //   return obj
  // }, {} );

  const options = Array.isArray( editConfig.options ) ? editConfig.options : [];
  const optionsLabels = options.map( d => { return d.label } ).join( '-' );

  return (
    <View className="ved">

      {
        savingChanges ? (
          <Loader className="rdloader" ariaLabel="Saving..."/>
        ) : (

            <GameConfigContext.Provider value={geContext}>
              <Flex justifyContent="flex-end" marginBottom="small" marginRight="small">
                <Button onClick={onClose}>
                  Cancel
                </Button>

                <Button variation="primary" onClick={saveGame}>
                  Save Game
                </Button>
              </Flex>

              <View className="ved-wksp" >

                <View width="500px" >
                  <ConfigPanel />
                </View>

                <View margin="30px auto">
                  <Game 
                    key={optionsLabels}
                    game={{game: editConfig}}
                    onAnswer={( correct ) => { console.log( 'ANSWER:', correct )}}
                  />
                </View>

              </View>
                
            </GameConfigContext.Provider>

        )
      }
    </View>
  )
}

GameConfig.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
