import React from 'react'
import PropTypes from 'prop-types'
import { View } from "@aws-amplify/ui-react";
import { fieldType } from '../../DataField'

export const DataEditorField = ( { field, selected, onSelect } ) => { 
  let typeValue = fieldType( field )

  return (
    <div
      role="Field"
      className="dp-fld"
      title={typeValue.ftt}
      onClick={onSelect}
    >
      <View className="dp-fld-type" >
        {typeValue.ftype}
      </View>

      <View className="dp-fld-name" >
        {typeValue.fname}
      </View>
    </div>
  )
}

DataEditorField.propTypes = {
  field: PropTypes.object.isRequired
}