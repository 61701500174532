
export function formatLookup( lookups, key, value ) {

  let table = lookups[key] ? lookups[key] : {}

  // remap the lookup to use the label instead of the object
  return Object.keys( table ).reduce( ( all, d ) => {
    all[d] = table[d][value]
    return all
  }, {} )
}
