import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { View } from "@aws-amplify/ui-react";

export const Select = ( { className, label, value, Values, placeholder, onUpdate } ) => {
  const [current, setCurrent] = useState( value )

  let wrapperClasses = classNames( 'cp-ctl', className )

  return (
    <View>
      Select goes here!
    </View>
  )

  // return (
  //   <div className={wrapperClasses}>
  //     {
  //       label ? (
  //         <div className="cp-ctl-lbl" >
  //           {label}
  //         </div>
  //       ) : null
  //     }

  //     <input
  //       className="cp-ctl-val"
  //       placeholder={placeholder}
  //       defaultValue={value}
  //       onBlur={( event ) => { onUpdate( event.target.value ) }}
  //     />
  //   </div>
  // )
}

Select.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string
}
