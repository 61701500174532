import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { TextInput } from '../../../../../../Controls'
import { VegaEditorContext } from '../../../../VegaEditorContext'

import { ConfigField } from './ConfigField'

export const FieldConfig = ( { accept, label, prop, validDataset, defaultProps, children } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const myConfig = config && config[prop] ? config[prop] : defaultProps && defaultProps[prop] ? defaultProps[prop] : {}
  const myLabel = myConfig.label ? myConfig.label : {}
  const myTitle = myLabel.title ? myLabel.title : ''

  function updateField( update ) {
    let newConfig = { ...myConfig, ...update }

    updateConfig( { [prop]: newConfig } )
  }

  return (
    <ConfigField
      label={label}
      fieldProp="field"
      field={myConfig.field ? myConfig.field : null}
      accept={accept}
      onDrop={updateField}
      validDataset={validDataset}
    >
      <TextInput
        className="cp-xs-prp"
        label="Label"
        value={myTitle}
        onUpdate={( val ) => { updateField( { label: { title: val } } ) }}
      />

      {
        children ? (
          children
        ) : null
      }
      
    </ConfigField>
  )
}

FieldConfig.propTypes = {
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired
}
