
export class Metadata {

  constructor( recs ) {
    this.metadata = recs.slice()
    this.metaMap = this.metadata.reduce( ( obj, d ) => {
      obj[d.path] = d
      return obj
    }, {} )

    this.booleanFields = this.buildFieldStructures( this.metadata, 'boolean' )
    this.numberFields = this.buildFieldStructures( this.metadata, 'number' )
    this.stringFields = this.buildFieldStructures( this.metadata, 'string' )
  }

  getField( field ) {
    return this.metaMap && this.metaMap[field] ? this.metaMap[field] : {}
  }

  buildFieldStructures( metadata, type ) {
    let fields = metadata.filter( d => {
      return d.type === type
    } )

    let values = fields.map( d => {
      return d.path
    } )

    let map = fields.reduce( ( obj, d ) => {
      obj[d.path] = d.name ? d.name : d.path
      return obj
    }, {} )

    return { fields, values, map }
  }
}
