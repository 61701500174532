import { getConfigField } from './utils'
import { genTooltip } from './tooltip'
import { addNumericAxis } from './numericaxis'
import { addCategoricalAxis } from './categoricalaxis'

const aggData = '_barData_'
const aggVLoc = 'v'

export function bar( config, data ) {
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated bar chart specification.',
    usermeta: {embedOptions: {renderer: "svg"}},
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    height: 600,
    padding: 5,

    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  // The bars value value is defined so we have a chart to define.
  let bField = getConfigField( config.b )
  let vField = getConfigField( config.v )

  // We must have a bars field in order to do anything.
  if ( bField ) {

    // Push in the base dataset
    spec.data.push( {
      name: bField.datasetId,
      transform: []
    } )

    // Then setup the aggregation dataset.
    let aggDs = {
      name: aggData,
      source: bField.datasetId,
      transform: []
    }

    if ( vField ) {
      // Only use values that are valid numbers
      aggDs.transform.push( {
        type: 'filter',
        expr: `isNumber( datum.${vField.loc} )`
      } )
      
      // We have an aggregation field so sum those fields.
      aggDs.transform.push(
        {
          type: 'aggregate',
          groupby: [`${bField.loc}`],
          fields: [`${vField.loc}`],
          valid: true,
          ops: ['sum'],
          as: [aggVLoc]
        }
      )
    } else {

      // No aggregation field specified so just count instances.
      aggDs.transform.push(
        {
          type: 'aggregate',
          groupby: [`${bField.loc}`],
          ops: ['count'],
          as: [aggVLoc]
        }
      )
    }

    // Filter for valid bar values.
    aggDs.transform.push(
      {
        type: 'filter',
        expr: `datum.${bField.loc}`
      }
    )

    // And push the dataset definition into the list of data.
    spec.data.push( aggDs )

    // First add the scales and axes for the bars...
    // To do this we need to create a field spec that points to the aggregate
    // dataset.
    let aggBField = { ...bField }
    aggBField.datasetId = aggData

    let aggVField = vField ? { ...vField } : { }
    aggVField.datasetId = aggData
    aggVField.name = vField ? vField.name : 'Count'
    aggVField.loc = aggVLoc

    // let barConfig = { ...config.b, field: aggBField, sort: { field: aggVField.name, order: 'ascending'} }
    let barConfig = { ...config.b, field: aggBField }

    // Now configure the scale and axis for the bars.
    addCategoricalAxis( spec, barConfig, 'b', 'bottom', 'width' )

    let valConfig = { ...config.v, field: aggVField }

    let vOpts = {
      prefix: 'v',
      orient: 'left',
      domain: { data: aggVField.datasetId, field: aggVField.loc },
      range: 'height',
    }

    addNumericAxis( spec, valConfig, vOpts )

    let markClr = config.color && config.color.mark ? config.color.mark : {}

    // Now we can add in the mark(s)
    let barMark = {
      type: 'rect',
      from: { data: aggData },
      encode: {
        enter: {
          x: { scale: 'bScale', field: aggBField.loc },
          width: { scale : 'bScale', band: 1 },
          y: { scale: 'vScale', field: aggVLoc },
          y2: { scale: 'vScale', value: 0 }
        },
        update: {
          fill: { value: markClr.fill ? markClr.fill : 'steelblue' }
        },
        // hover: {
        //   fill: { value: hoverClr.fill ? hoverClr.fill : 'red' }
        // }
      }
    }

    if ( config.tt && config.tt.show ) {
      // A tooltip is requested so create one from the bar and value marks.
      let tt = []

      if ( aggBField ) {
        // Generate the tooltip spec from the existing configuration.
        tt.push( {
          field: aggBField,
          label: config.b.title
        } )

        tt.push( {
          field: aggVField,
          label: config.v.title,
          format: config.tt.format ? config.tt.format : config.v.format ? config.v.format : undefined
        } )

        // Generate and add the tooltip to the mark.
        barMark.encode.update.tooltip = { signal: genTooltip( tt ) }

        // Now add the hover coloring so we have a visual indication of what
        // the tooltip applies to.
        let hoverClr = config.color && config.color.hover ? config.color.hover : {}

        barMark.encode.hover = {
          fill: { value: hoverClr.fill ? hoverClr.fill : 'red' }
        }
      }
    }

    spec.marks.push( barMark )
  }

  // console.log( 'BAR CONFIG:', config )

  return spec
}
