import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import TrieSearch from 'trie-search'
import { ToggleButtonGroup, ToggleButton, View } from "@aws-amplify/ui-react";
import { RiDatabase2Line } from "react-icons/ri";

import { VegaEditorContext } from '../../VegaEditorContext'

import { DataField, fieldType } from './DataField'
import { SearchInput } from './SearchInput'
import { DataSetDelete, DataSetEditor } from './components'
import {
  FIELDTYPE_CATEGORICAL,
  FIELDTYPE_NUMERIC,
  fieldTypes
} from '../../../../../../hooks'

export const DataSet = ( { dataset } ) => {
  const dsFields = Array.isArray( dataset.fields ) ? dataset.fields.slice() : [];
  const { updateChartData, updateData } = useContext( VegaEditorContext );
  const [ searchTerms, setSearchTerms ] = useState( '' );
  const [ searchIndex, setSearchIndex ] = useState( null );
  const [ selFieldTypes, setSelFieldTypes] = useState( [FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC]);
  const [ filteredFields, setFilteredFields ] = useState( organizeFields( dsFields ) );

  useEffect( () => {
    if ( searchIndex === null || !isEqual(dsFields, prevFields.current) ) {
      let index = new TrieSearch( ['name', 'desc'], { idFieldOrFunction: 'ripeID', ignoreCase: true } )

      if ( Array.isArray( dataset.fields ) ) {
        index.addAll( dataset.fields )
        setSearchIndex( index )

        updateFields()
      }
    }
  }, [dataset.fields] )

  const prevFields = useRef()
  useEffect( () => {
    prevFields.current = dataset.fields
  } )

  useEffect( () => {
    if ( !isEqual(searchIndex, prevIndex.current) ) {
      updateFields();
    }
  }, [dataset.fields] )

  const prevIndex = useRef()
  useEffect( () => {
    prevIndex.current = searchIndex
  } )

  useEffect( () => {
    updateFields()
  }, [selFieldTypes, searchTerms] );


  function organizeFields( fields ) {
    return ( orderFields( filterFieldTypes( fields ) ) )
  }

  function updateFields() {

    if ( searchTerms ) {
      // Break the terms into tokens and remove any empty tokens
      let query = searchTerms.split( ' ' ).filter( d => {
        return d ? true : false
      } )

      let newFields = searchIndex.get( query, TrieSearch.UNION_REDUCER )
      setFilteredFields( organizeFields( newFields ) )
    } else {
      setFilteredFields( organizeFields( dsFields.slice() ) )
    }
  }

  function orderFields( fields ) {
    let sorted = fields.slice() 

    sorted.sort( ( a, b ) => {
      const A = fieldType( a )
      const B = fieldType( b )
      
      return A.fname < B.fname ? -1 : A.fname > B.fname ? 1 : 0
    } )

    return sorted
  }

  function filterFieldTypes( fields ) {
      return fields.filter( d => {
        let fieldInfo = fieldType( d )
        return selFieldTypes.includes( fieldInfo.ftype )
      })
  }

  function onDataUpdate( update ) {
    const newData = { [update.dsId]: update }

    updateData( newData )
  }

  return (
    <View className="dp-src" >
      <View className="dp-src-nm" >
        <View
          marginTop="auto"
          marginBottom="auto"
          marginRight="small"
        >
          <RiDatabase2Line />
        </View>
        
        <View className="dp-src-nm-label" >
          {dataset.name}
        </View>

        <DataSetEditor dataset={dataset} onUpdate={( update ) => { onDataUpdate( update ) }}/>

        <DataSetDelete onDelete={() => { updateChartData( [] ) }} />
      </View>

      <View className="dp-flds">

        <View className="dp-flds-hdr">
          <View className="dp-flds-hdr-label">
            Fields
          </View>

          <SearchInput
            className="dp-flds-hdr-srch"
            onChange={setSearchTerms}
            onSearch={setSearchTerms}
            value={searchTerms}
          />
          
        </View>

        <View className="dp-flds-hdr">
          <View className="dp-flds-hdr-label">
            Field Types
          </View>

          <ToggleButtonGroup
            value={selFieldTypes}
            onChange={setSelFieldTypes}
            isSelectionRequired
            marginTop="xs"
            marginBottom="xs"
          >
            {
              fieldTypes.map( ( d, i ) => {
                return( 
                  <ToggleButton height="20px" paddingLeft="12px" paddingRight="12px" key={i} value={d}>
                    {d}
                  </ToggleButton>
                )
              })
            }
    
          </ToggleButtonGroup>
          
        </View>

        <View className="dp-flds-lst">
          {
            filteredFields.map( f => {
              let key = `${f.loc}-${f.values ? f.values.join('-') : 'noval'}`
              return (
                <DataField
                  key={key}
                  field={f}
                  datasetId={dataset.dsId}
                />
              )
            } )
          }
        </View>
      </View>

    </View>
  )
}

DataSet.propTypes = {
  dataset: PropTypes.object.isRequired
}
