import { getNodeValue } from '@ripedata/datautils'

export function getDataValues( data, filter ) {
  let iVals = []

  if ( data && filter && filter.field ) {
    const field = filter.field
    const dataId = field.datasetId

    // If the have the specified data...
    if ( dataId && data[dataId] && Array.isArray( data[dataId].data ) ) {

      // Gather the values from each of the data records making them
      // unique by creating an object with the value as a property.
      let values = data[dataId].data.reduce( ( obj, r ) => {
        let val = getNodeValue( r, field.loc )

        if ( val !== undefined ) {
          obj[val] = true
        }

        return obj
      }, {} )

      // Get the unique values and sort them.
      iVals = Object.keys( values ).sort()
    }

  }

  return iVals
}
