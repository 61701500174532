/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGame = /* GraphQL */ `
  query GetGame($gameId: ID!) {
    getGame(gameId: $gameId) {
      gameId
      author
      title
      category
      thumbnail
      published
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $gameId: ID
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGames(
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gameId
        author
        title
        category
        thumbnail
        published
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gamesByAuthor = /* GraphQL */ `
  query GamesByAuthor(
    $author: String!
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gamesByAuthor(
      author: $author
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gameId
        author
        title
        category
        thumbnail
        published
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gamesByCategory = /* GraphQL */ `
  query GamesByCategory(
    $category: String!
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gamesByCategory(
      category: $category
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gameId
        author
        title
        category
        thumbnail
        published
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gamesByPubDate = /* GraphQL */ `
  query GamesByPubDate(
    $published: AWSDate!
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gamesByPubDate(
      published: $published
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gameId
        author
        title
        category
        thumbnail
        published
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserStats = /* GraphQL */ `
  query GetUserStats($userId: ID!, $gameId: ID!) {
    getUserStats(userId: $userId, gameId: $gameId) {
      userId
      gameId
      category
      gameType
      correct
      answer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserStats = /* GraphQL */ `
  query ListUserStats(
    $userId: ID
    $gameId: ModelIDKeyConditionInput
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserStats(
      userId: $userId
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        gameId
        category
        gameType
        correct
        answer
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGameStats = /* GraphQL */ `
  query GetGameStats($gameId: ID!) {
    getGameStats(gameId: $gameId) {
      gameId
      category
      correct
      incorrect
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGameStats = /* GraphQL */ `
  query ListGameStats(
    $gameId: ID
    $filter: ModelGameStatsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGameStats(
      gameId: $gameId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gameId
        category
        correct
        incorrect
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gameStatsByCategory = /* GraphQL */ `
  query GameStatsByCategory(
    $category: String!
    $gameId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameStatsByCategory(
      category: $category
      gameId: $gameId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gameId
        category
        correct
        incorrect
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublishedGames = /* GraphQL */ `
  query GetPublishedGames($type: String!, $published: AWSDate!) {
    getPublishedGames(type: $type, published: $published) {
      type
      published
      gameId
      title
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPublishedGames = /* GraphQL */ `
  query ListPublishedGames(
    $type: String
    $published: ModelStringKeyConditionInput
    $filter: ModelPublishedGamesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublishedGames(
      type: $type
      published: $published
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        type
        published
        gameId
        title
        category
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
