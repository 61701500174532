import { useContext, useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'
import { VegaEditorContext } from '../../../../VegaEditorContext'

function validField( toCheck, dataIds ) {
  let valid = true

  if ( toCheck && Array.isArray( dataIds ) ) {

    // Start by assuming that we have just a field specification
    if ( toCheck.datasetId ) {

      valid = dataIds.includes( toCheck.datasetId )

    } else if ( toCheck.field && toCheck.field.datasetId ) {

      // Or we may have a structure that contains a field so check there as well.
      valid = dataIds.includes( toCheck.field.datasetId )
    }
  }

  return valid
}
// Verify that the fields in the configuration are present in the current
// datasets.
export function useValidateFields( toCheck ) {
  const { config, datasetList, updateConfig } = useContext( VegaEditorContext )

  // Get the list of valid dataset identifiers so we know if the all of the
  // specified fields still point to valid datasets
  const dataIds = Array.isArray( datasetList ) ? datasetList : []

  // Update the fields to remove any that are now invalid if the
  // associated dataset has been removed.
  useEffect( () => {
    if ( prevDataIds.current && !isEqual( prevDataIds.current, dataIds ) ) {
      let updates = {}

      if ( Array.isArray( toCheck ) && config && dataIds ) {
        toCheck.forEach( ( p ) => {
          if ( config[p] && Array.isArray( config[p] ) ) {

            // The We have an array of fields so check each of them, Removing
            // any that are invalid.
            let newList = config[p].reduce( ( ary, d ) => {
              if ( validField( d, dataIds ) ) {
                ary.push( d )
              }
              return ary
            }, [] )

            // There is a difference so update the configuration property.
            if ( newList.length !== config[p].length ) {
              updates[p] = newList
            }

          } else if ( config[p] && !validField( config[p], dataIds ) ) {

            // The dataset used by this property isn't in the list anylonger
            // so we need to remove the property from the config.
            updates[p] = null
          }
        } )
      }

      updateConfig( updates )
    }
  }, [dataIds] )

  const prevDataIds = useRef()
  useEffect( () => {
    prevDataIds.current = dataIds
  } )
}
