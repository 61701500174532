'use strict'
import { format } from 'd3-format'

let suffix = ['','K','M','B','T']

export function formatNumber( template, val, defaultVal ) {
  let formatted = val

  if ( template ) {

    if ( typeof val === 'number' ) {
      formatted = doFormatting( val, template )
    } else if ( typeof defaultVal === 'number' ) {
      formatted = doFormatting( defaultVal, template )
    } else if ( typeof defaultVal === 'string' ) {
      formatted = defaultVal
    }
  }

  return formatted
}

function doFormatting( val, template ) {
  let formatted = val
  if ( typeof val === 'number' ) {
    let conversion = template[template.length -1]
    if ( conversion === '_' ) {
      let templateArr = template.split( '_' )
      let newTemplate = templateArr[0]
      conversion = newTemplate[newTemplate.length -1]
      formatted = `${templateArr[1]}${getFormat( val, templateArr[0], conversion )}`
    } else {
      formatted = getFormat( val, template, conversion )
    }
  }
  return formatted
}

function getFormat( val, template, conversion ) {
  let formatted = val
  let newTemplate
  switch ( conversion ) {
  case 'm':
    newTemplate = `${template.slice( 0, template.length -1 )}f`
    let testVal = Math.abs( val )

    let exp = [1.0e3, 1.0e6, 1.0e9, 1.0e12, 1.0e15].findIndex( ( d, i ) => {
      return testVal < d
    } )

    formatted = `${format( newTemplate )( val / Math.pow( 1000, exp ) )}${suffix[exp]}`
    break

  case '%':
    newTemplate = `${template.slice( 0, template.length -1 )}f`
    formatted = `${format( newTemplate )( val )}%`
    break

  default:
    formatted = format( template )( val )
  }
  return formatted
}
