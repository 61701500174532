import PropTypes from 'prop-types'
import classNames from 'classnames'
import { View } from "@aws-amplify/ui-react";
import { BsSearch, BsX } from "react-icons/bs";

export const SearchInput = ( { className, value, placeholder, onChange, onSearch } ) => {

  const classes = classNames( 'dp-si', className )

  function onSearchChange( event ) {
    let keyCode = event.keyCode || event.which

    if ( keyCode === 13 ) {
      if ( onSearch && value && value.length ) {

        // Only search if there are some query terms to use in the search.
        onSearch( value )
      }
    }
  }

  return (
    <View className={classes}>
      <BsSearch
        className="dp-si-icn"
        size={14}
      />


          <input
            className="dp-si-val"
            placeholder={placeholder}
            value={value}
            onChange={( event ) => { onChange( event.target.value ) }}
            onKeyPress={onSearchChange}
            onBlur={( event ) => { onSearch( value ) }}
          />

          <BsX
            className="dp-si-icn"
            size={20}
            onClick={() => { onChange( '' ) }}
          />


    </View>
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string
}
