import { getNodeValue } from '@ripedata/datautils'

export function filterData( filters, data, fltValues ) {
  let filtered = data

  if ( Array.isArray( filters ) ) {

    // Iterate through each of the filters to apply them.
    filters.forEach( ( d, i ) => {
      const fld = d.field

      // We have a dataset ID, and it exists within the data.
      if ( fld && fld.datasetId && filtered[fld.datasetId] ) {

        // Do we have a value associated with the filter
        if ( fltValues[i] ) {

          // then apply the filter to the data.
          filtered[fld.datasetId] = filtered[fld.datasetId].filter( r => {
            let cmpval = getNodeValue( r, fld.loc )
            let match = true  // Default to matching.

            if ( fld.type === 'string' ) {

              if ( Array.isArray( fltValues[i] ) && fltValues[i].length ) {
                // It's a match if the record value matches one or more of the
                // filter values.
                match = fltValues[i].includes( cmpval )
              } else {
                // It matches so add it to the filtered dataset.
                match = ( cmpval === fltValues[i] )
              }
            } else if ( fld.type === 'number' ) {

              if ( Array.isArray( fltValues[i] ) && fltValues[i].length >= 2 ) {

                // It's a match if the record value is between the two values.
                match = fltValues[i][0] <= cmpval && fltValues[i][1] >= cmpval
              }
            }

            return match
          } )
        }
      }

    } )

  }

  return filtered
}
