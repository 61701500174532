// Apply a dimension filter using the specified list and operation

// dim - Crossfilter dimension to apply the filter
// list - list of values to use in filtering the dimension
// operation - and|or, match all, or any values from the list
// path - path to the value to check within the dimension value

export function applyFilterList( dim, list, operation, path ) {

  if ( dim && list ) {
    let filter = buildFilterObject( list )

    let filterCount = Object.keys( filter ).length
    let func = ( a, src ) => {
      return a.some( d => {
        let val = path ? getNodeValue( d, path ) : d
        return filter[val] ? true : false
      } )
    }

    // Ensure the operation is either every or some.
    if ( operation === 'and' ) {
      func = ( a ) => {

        let cnt = a.reduce( ( match, d ) => {
          let val = path ? getNodeValue( d, path ) : d
          return filter[val] ? match + 1 : match
        }, 0 )

        return cnt >= filterCount
      }
    }

    if ( filterCount > 0 ) {

      dim.filterFunction( d => {
        let ok = false
        if ( d && Array.isArray( d ) && d.length > 0 ) {
          ok = func( d )
        } else {
          ok = filter[d] ? true : false
        }

        return ok
      } )
    }
  }
}

function buildFilterObject( list ) {
  let filter = {}

  if ( Array.isArray( list ) ) {
    filter = list.reduce( ( selected, d ) => {
      selected[d] = true
      return selected
    }, {} )
  }

  return filter
}
