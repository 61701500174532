import React, { Children, useState } from 'react'
import PropTypes from 'prop-types'
import { View } from "@aws-amplify/ui-react";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";

import { DropField } from './DropField'

export const ConfigField = ( { label, children, fieldProp, field, accept, onDrop, validDataset, restrictions } ) => {
  const [isOpen, setIsOpen] = useState( false )

  return (
    <View className="cp-fc">
      <View
        width="14px"
        height="14px"
        margin="xs"
        style={{cursor: "pointer"}}
        onClick={() => { setIsOpen( !isOpen ) }}
      >
        {
          Children.count(children) === 0 ? (
            <div />
          ): isOpen ? (
            <BsChevronDown size={14} />
          ) : (
            <BsChevronRight size={14} />
          )
        }
      </View>

      <View className="cp-fc-ctr">
        <View className="cp-fc-fld">
          <View  className="cp-cfg-drp-lbl">
            {label}
          </View>

          <DropField
            prop={fieldProp}
            field={field}
            accept={accept}
            onDrop={onDrop}
            validDataset={validDataset}
            restrictions={restrictions}
          />
        </View>

        { isOpen ? children : null }
      </View>
    </View>
  )
}

ConfigField.propTypes = {
  fieldProp: PropTypes.string.isRequired,
  accept: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  validDataset: PropTypes.string.isRequired,
  restrictions: PropTypes.array,
  field: PropTypes.object,
  label: PropTypes.string
}
