/*eslint complexity: ["warn", 30]*/
'use strict'

import { EduSport } from './EduSport'
import { getNodeValue, rehydrateObject, getValue, buildLookup, rehydrateArrayOfArrays } from '../utils'

let enrollmentLabelMap = {
  'All students total' : 'Total',
  'All students, Graduate' : 'Graduate',
  'All students, Undergraduate total': 'Undergraduate'
}

let degMap = {
  'Associate\'s degree' : 'Associate\'s',
  'Bachelor\'s degree or equivalent' : 'Bachelor\'s',
  'Master\'s degree': 'Master\'s',
  'Doctor’s degree|Other': 'Doctorate',
  'Doctor’s degree|Professional practice': 'Doctorate',
  'Doctor’s degree|Research/scholarship': 'Doctorate',
  'Postbaccalaureate certificate' : 'Postbaccalaureate Certificate',
  'Post-master\'s certificate': 'Post-Master\'s Certificate',
  'Postsecondary award, certificate, or diploma of at least one but less than two academic years (at least 900 but less than 1800 contact or clock hours)': 'Certificate',
  'Postsecondary award, certificate, or diploma of at least two but less than four academic years (at least 1800 contact or clock hours)': 'Certificate',
  'Postsecondary award, certificate, or diploma of less than one academic year (less than 900 contact or clock hours)': 'Certificate'
}

let vetMap = {
  'Yellow Ribbon Program': 'Yellow Ribbon',
  'Credit for Military Training': 'Credit for Training',
  'Dedicated point of contact for support services for veterans, military servicemembers, and their families': 'Dedicated Military Contact',
  'Recognized student veteran organization': 'Recognized Organization',
  'Member of Servicemembers Opportunity Colleges': 'Servicemembers Opportunity'
}

export const eduFacetFile = 'edufacets.20170616.gz'

export function parseEduFacets( facetData ) {
  let data = {}

  // Start with the lookup tables so we can then rehydrate
  // the rest of the summary
  if ( facetData.lookup ) {

    data.lookup = facetData.lookup.reduce( ( acum, entry ) => {

      if ( entry.var && entry.code ) {
        if ( acum[entry.var] === undefined ) {
          acum[entry.var] = {}
        }

        acum[entry.var][entry.code] = entry.val
      }

      return acum
    }, {} )
  }

  if ( facetData.values ) {

    let datatable = facetData.values//.slice( 0, 1 )

    // Extract the base occupation information (filter global)
    data.orgs = reconstructOrgs( datatable, data )
  }

  // Build data for sports
  let sports = data.orgs.reduce( ( acum, d ) => {
    if ( d.indSports ) {
      d.indSports.forEach( sport => {
        if ( sport.sport ) {
          acum[sport.sport] = acum[sport.sport] ? acum[sport.sport] + 1 : 1
        }
      } )
    }

    return acum
  }, {} )

  data.sports = Object.keys( sports ).reduce( ( acum, d ) => {
    acum.push( {
      sport: d,
      count: sports[d]
    } )

    return acum
  }, [] )


  // Build data for ROTC
  let rotc = data.orgs.reduce( ( acum, d ) => {
    if ( d.characteristics && d.characteristics.rotc ) {
      d.characteristics.rotc.forEach( service => {
        acum[service] = acum[service] ? acum[service] + 1 : 1
      } )
    }

    return acum
  }, {} )

  data.rotc = Object.keys( rotc ).reduce( ( acum, d ) => {
    acum.push( {
      service: d,
      count: rotc[d]
    } )

    return acum
  }, [] )

  // Build data for control
  let control = data.orgs.reduce( ( acum, d ) => {
    if ( d.characteristics && d.characteristics.control ) {
      let type = d.characteristics.control
      acum[type] = acum[type] ? acum[type] + 1 : 1
    }

    return acum
  }, {} )

  data.ctrl = Object.keys( control ).reduce( ( acum, d ) => {
    acum.push( {
      key: d,
      count: control[d]
    } )

    return acum
  }, [] )

  let degreeSplit = data.orgs.reduce( ( acum, d ) => {
    if ( d.characteristics.highestDegree ) {
      acum[d.characteristics.highestDegree] = acum[d.characteristics.highestDegree] ? acum[d.characteristics.highestDegree] + 1 : 1
    } else {
      acum['unknown'] = acum['unknown'] ? acum['unknown'] + 1 : 1
      //      console.log( 'UNKNOWN DEGREE:', d )
    }

    return acum
  }, {} )
  /*
  console.log( 'HIGHEST DEGREE', degreeSplit )


  let controlValues = data.orgs.reduce( ( acum, d ) => {
    if ( d.characteristics.control ) {
      acum[d.characteristics.control] = acum[d.characteristics.control] ? acum[d.characteristics.control] + 1 : 1
    } else {
      acum['unknown'] = acum['unknown'] ? acum['unknown'] + 1 : 1
//      console.log( 'UNKNOWN DEGREE:', d )
    }

    return acum
  }, {} )

  console.log( 'CONTROL', controlValues )

  let ftRate = data.orgs.reduce( ( acum, d ) => {
    if ( d.characteristics && d.characteristics.ftRetRate ) {
      acum[d.characteristics.ftRetRate] = acum[d.characteristics.ftRetRate] ? acum[d.characteristics.ftRetRate] + 1 : 1
    }
    return acum
  }, {} )

  let ftRateArray = Object.keys( ftRate ).reduce( ( acum, d ) => {
    acum.push( {
      key: d,
      value: ftRate[d]
    } )
    return acum
  }, [] )
  console.log( 'RETENTION RATE:', JSON.stringify( ftRateArray ) )
*/
  let badCount = 0
  let degValues = data.orgs.reduce( ( acum, d ) => {
    if ( Array.isArray( d.characteristics.degLvls ) ) {
      d.characteristics.degLvls.forEach( l => {
        acum[l] = acum[l] ? acum[l] + 1 : 1
      } )

    } else if ( d.characteristics.degLvls ) {
      //      console.log( 'NON ARRAY:', d.ripeID, d.characteristics.degLvls )
      acum[d.characteristics.degLvls] = acum[d.characteristics.degLvls] ? acum[d.characteristics.degLvls] + 1 : 1
      badCount++
    } else {
      acum['unknown'] = acum['unknown'] ? acum['unknown'] + 1 : 1
      //      console.log( 'UNKNOWN DEGREE:', d )
    }

    return acum
  }, {} )

  console.log( 'DEGREE LEVELS:', degValues )
  console.log( 'NON ARRAY VALUES:', badCount )

  //   let studFinCnt = data.orgs.reduce( ( acum, d ) => {  -- REMOVED FROM DATA
  //     if ( !d.studentFinances ) {
  // //      console.log( 'MISSING:', d.name, d.enrollDem && d.enrollDem.Total ? d.enrollDem.Total[0] : '')
  //     }
  //     return d.studentFinances ? acum + 1 : acum
  //   }, 0 )

  // console.log( 'STUDENT FINANCE DATA:', studFinCnt, data.orgs.length )

  return data
}

function reconstructOrgs( datatable, data ) {
  let presentAttrs = {}

  let organizations = datatable.reduce( ( orgs, org ) => {
    let orgData = {}

    Object.keys( org ).forEach( attr => {
      presentAttrs[attr] = presentAttrs[attr] ? presentAttrs[attr] + 1 : 1

      if ( attr === 'acadCosts' || attr === 'vocCosts' ) {
        // This is an array of demographic information

        orgData[attr] = org[attr].reduce( ( acum, d ) => {
          let prop = getValue( 'k', d.k, data.lookup )
          let tmpObj = {}
          rehydrateObject( tmpObj, 'k', ['v','a'], d.a, data.lookup )

          acum[prop] = tmpObj
          return acum

        }, {} )

      } else if ( attr === 'allSports' ) {
        // this is an array of the form allSports: [ {m: [{k,v}], f: [],c:[],a:}]

        orgData[attr] = org[attr].reduce( ( curr, obj ) => {
          let thiskey = Object.keys( obj )[0]
          curr[thiskey] = {}
          rehydrateObject( curr[thiskey], 'k', ['v','a','p'], obj[thiskey], data.lookup )
          return curr
        }, {} )

      } else if ( attr === 'indSports' ) {

        // This is an array of objects so iterate through the Array
        // creating an object for each item.
        orgData[attr] = org[attr].reduce( ( acum, d ) => {
          let tmpObj = {}
          tmpObj.sport = getValue( 'sport', d.sport, data.lookup )
          if ( d.m ) {
            tmpObj.m = {}
            rehydrateObject( tmpObj.m, 'k', ['v','a'], d.m, data.lookup )
          }
          if ( d.f ) {
            tmpObj.f = {}
            rehydrateObject( tmpObj.f, 'k', ['v','a'], d.f, data.lookup )
          }
          if ( d.c ) {
            tmpObj.c = {}
            rehydrateObject( tmpObj.c, 'k', ['v','a'], d.c, data.lookup )
          }

          if ( Object.keys( tmpObj ).length > 0 ) {
            acum.push( tmpObj )
          }

          return acum
        }, [] )

      } else if ( attr === 'debtAtEnterRepay' ) {
        // This example works if incoming data of the form ->  name: [ {k: ###, v: ###}]
        orgData[attr] = {}
        rehydrateObject( orgData[attr], 'k', ['v','a','p'], org[attr], data.lookup )
      } else if ( attr === 'cscCompletions' || attr === 'earnings' || attr === 'parentEd'
           || attr === 'familyIncomeAmt' ) {
        orgData[attr] = rehydrateArrayOfArrays( 'a', 'k',  ['v','a','p'], org[attr], data.lookup )
      } else if ( attr === 'repayment' ) {
        orgData[attr] = Object.keys( org[attr] ).reduce( ( keyObj, key  ) => {
          keyObj[key] = rehydrateArrayOfArrays( 'a', 'k',  ['v','a','p'], org[attr][key], data.lookup )
          return keyObj
        }, {} )
      } else if ( attr === 'onCampusOffenses' ) {
        orgData[attr] = Object.keys( org[attr] ).reduce( ( keyObj, key  ) => {
          keyObj[key] = {}
          rehydrateObject( keyObj[key], 'k',  ['v','a','p'], org[attr][key], data.lookup )
          return keyObj
        }, {} )
      } else if ( attr === 'completionsDemArray' ) {
        // This is an array of demographic information
        orgData[attr] = org[attr]
      } else if ( attr === 'demographicsByAwardLevel' ) {

        orgData[attr] = org[attr]

      } else if ( attr === 'distEdByStudLvl' ) {
        // Only the studLvl field is encoded.
        orgData[attr] = org[attr].map( d => {
          d.studLvl = d.studLvl ? getValue( 'studLvl', d.studLvl, data.lookup ) : ''
          return d
        } )
      } else if ( attr === 'distEdCipsByLvl' ) {
        orgData[attr] = org[attr]
      } else if ( attr === 'enrollDem' ) {

        orgData[attr] = org[attr].reduce( ( acum, d ) => {
          let lvl = enrollmentLabelMap[getValue( 'studLvl', d.studLvl, data.lookup )]
          if ( lvl ) {
            acum[lvl] = d.demArray
          }
          return acum
        }, {} )

      } else if ( attr === 'enrollByAge' ) {

        orgData[attr] = org[attr].reduce( ( acum, d ) => {
          let lvl = getValue( 'studLvl', d.studLvl, data.lookup )
          acum[lvl] = d.detAgeArr
          return acum
        }, {} )

      } else if ( attr === 'ftdsApplicants' ) {

        orgData[attr] = Object.keys( org[attr] ).reduce( ( acum, d ) => {
          acum[d] = {}
          rehydrateObject( acum[d], 'k', ['v','a','p'], org[attr][d], data.lookup )
          return acum
        }, {} )

      } else if ( attr === 'ftftGradRates' ) {
        orgData[attr] = org[attr]
      } else if ( attr === 'gradRateDemographics' ) {

        orgData[attr] = org[attr]
        orgData[attr].forEach( d => {
          d.cat = getValue( 'cat', d.cat, data.lookup )
        } )



        //      } else if ( attr === 'location' ) {
      } else if ( attr === 'programsByAwardLevel' ) {
        // These get copied directly
        orgData[attr] = org[attr]

      } else if ( Array.isArray( org[attr] ) ) {
        let tmpObj = {}

        rehydrateObject( tmpObj, 'k', ['v','a','p'], org[attr], data.lookup )
        orgData[attr] = tmpObj

      } else if ( typeof org[attr] === 'object' ) {

        orgData[attr] = {}
        rehydrateObject( orgData[attr], 'k', ['v','a','p'], org[attr], data.lookup )
      } else {

        orgData[attr] = org[attr]
      }
    } )

    // Now we need to perform some cleanup until we get the changes moved
    // further up the pipeline.
    if ( orgData.characteristics && orgData.characteristics.rotc ) {
      orgData.characteristics.rotc = orgData.characteristics.rotc.split( '|' )
        .filter( d => {
          return d === 'ROTC' ? false : true
        } )
    }

    // Shorten the degree levels and ensure they are arrays.
    if ( orgData.characteristics && Array.isArray( orgData.characteristics.degLvls ) ) {
      let tmp = orgData.characteristics.degLvls.reduce( ( acum, d ) => {
        acum[degMap[d]] = true
        return acum
      }, {} )
      orgData.characteristics.degLvls = Object.keys( tmp ).reduce( ( acum, d ) => {
        acum.push( d )
        return acum
      }, [] )
    } else if ( orgData.characteristics.degLvls ) {
      orgData.characteristics.degLvls = [orgData.characteristics.degLvls]
    }

    if ( orgData.characteristics && Array.isArray( orgData.characteristics.vetPrograms ) ) {
      let tmp = orgData.characteristics.vetPrograms.reduce( ( acum, d )  => {
        acum[vetMap[d]] = true
        return acum
      }, {} )
      orgData.characteristics.vetPrograms= Object.keys( tmp ).reduce( ( acum, d ) => {
        acum.push( d )
        return acum
      }, [] )
    } else if ( orgData.characteristics.vetPrograms ) {
      orgData.characteristics.vetPrograms = [orgData.characteristics.vetPrograms]
    }

    orgs.push( orgData )

    return orgs
  }, [] )

  //  console.log( 'ATTRIBUTES:', presentAttrs )

  return organizations
}
