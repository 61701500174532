import { getCurrentUserInfo } from '../utils';

// Add the authmode based on whether a user is logged in.
export async function addAuthMode( params ) {

    const userInfo = await getCurrentUserInfo();

    if ( !userInfo || !userInfo.username ) {
      // params.authMode = "AWS_IAM"
      params.authMode = "iam"
    }

    return params
}