import { useEffect, useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';

import { Description } from '../Description';
import { DoneButton } from '../DoneButton'
import { shuffleArray } from '../utils';
import { PartitionContainers, unsetID, higherID, lowerID, destinations } from './PartitionContainers';

export const Partition = ( { config, onAnswer } ) => {
    const [items, setItems] = useState({});
    
    let desc = config.description ? config.description : "No Description";
    
    useEffect( () => {
        let totalItems = Object.keys( items ).reduce( ( sum, d ) => {
            sum = sum + items[d].length;
            return sum;
        }, 0 );

        if ( Array.isArray( config.options ) && totalItems !== config.options.length ) {
            let options = Array.isArray( config.options ) ? config.options.slice() : [];

            // We need to run through the list of items and generate
            // a random list of items to use in the game.
            if ( Array.isArray( options ) ) {
                let valCopy = options.slice();

                shuffleArray( valCopy );

                // Setup the initial values.
                let newItems = {
                    [unsetID]: valCopy.map( d => {
                        return {
                            id: d.label,
                            label: d.label,
                            data: d.value
                        }
                    })
                }

                // Create empty arrays for each of the destination containers.
                destinations.forEach( d => {
                    newItems[d] = [];
                });

                setItems( newItems );
            }
        }

    }, [config.options] );

    function checkAnswer() {
        let answer = true

        let answerItems = {
            [higherID]: items[higherID].slice(),
            [lowerID]: items[lowerID].slice()
        };

        // First check the higher list
        answerItems[higherID].forEach( ( d ) => {
            if ( d.data < config.threshold ) {
                answer = false;
            }
        } )

        // Then check the lower list
        answerItems[lowerID].forEach( ( d ) => {
            if ( d.data >= config.threshold ) {
                answer = false;
            }
        } )

        onAnswer( { correct: answer, answer: answerItems } )
    }
      
    return (

        <Flex direction="column" maxWidth="450px" margin="large auto" >

            <Description desc={desc} />

            <DoneButton
                isDisabled={items[unsetID] && items[unsetID].length !== 0}
                checkAnswer={checkAnswer}
            />

            <Flex direction="row" >
                <Flex direction="column" grow={1} shrink={1}>
                    {
                        Object.keys( items ).length > 0 ? (
                            <PartitionContainers
                                items={items}
                                vertical
                                onChange={setItems}
                                dropLabels={{
                                    initial: config.itemLabel,
                                    higher: config.higherLabel,
                                    lower: config.lowerLabel
                                }}
                            />
                        ) : null
                    }
                </Flex>
            </Flex>
            
        </Flex>

    )
  }