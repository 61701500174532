import { Outlet } from 'react-router-dom';
import { View, ThemeProvider } from '@aws-amplify/ui-react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Layout = () => {

  return (
    <ThemeProvider>
      <View backgroundColor="background.primary">
        <Navbar />

        <Outlet />

        <Footer />
      </View>
    </ThemeProvider>
  );
}