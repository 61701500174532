import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Button, Flex, Grid, View} from '@aws-amplify/ui-react';
import { format } from "d3-format"

import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { MouseSensor, TouchSensor } from '../common'
import {Item, SortableItemData} from '../../../dnd';


export function ConnectFourTapContainers({
  columns,
  items: initialItems,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  onChange,
  onSkip,
  onPlacement,
  labels,
  numBins,
  valueFormat
}: Props) {
  const [items, setItems] = useState(
    () =>
      initialItems ?? {}
  );
  const [containers, setContainers] = useState(
    Object.keys(items)
  );
  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);

  const placingItem = Object.keys( items ).reduce( ( placing, type ) => {
    if ( !placing ) {
      placing = items[type].findIndex( d => {
        return d.data.status === 'placing'
      } ) >= 0;
    }
    return placing
  }, false )

  const findContainer = (id) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].findIndex( (item) => item.id === id) >= 0 );
  };

  const getIndex = (id) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].findIndex( (item) => item.id === id);

    return index;
  };

  const skipItem = ( event ) => {
    if ( onSkip ) {
      setItems( onSkip( items ) );
    }
  }

  const itemPlaced = ( event ) => {
    setItems( onPlacement( items ) );
  }

  return (
      <Flex direction="column" width="100%" alignItems="center" justifyContent="center" gap="0px">
        <Flex direction="row" grow={0} shrink={0} width="90%">
          <Button
            size="small"
            onClick={skipItem}
          >
            Skip
          </Button>
              <View
                key="placing"
                id="placing"
                columns={columns}
                items={items.placing}
                scrollable={scrollable}
                style={{minHeight: placingItem ? '75px' : '0px'}}
                unstyled={true}
              >
                  {
                    items.placing.map((value, index) => {
                      return (
                        <SortableItem
                          key={index}
                          disabled={value.data.status !== 'placing'}
                          id={value.id}
                          index={0}
                          style={getItemStyles}
                          wrapperStyle={wrapperStyle}
                          containerId="placing"
                          getIndex={getIndex}
                          value={value}
                          itemPlaced={itemPlaced}
                        />
                      )
                    })
                  }
                
              </View>

        </Flex>

        <Grid gap="0px" templateColumns="1fr 1fr 1fr 1fr" width="100%">
          {
            Array(numBins).fill('').map( ( bin, i ) => {
              return ( <View key={`l${i}`} className="cf-label">{labels ? labels[i] : `Bin ${i + 1}`}</View> )
            } )
          }

          {
            Array(numBins).fill('').map( ( bin, i ) => {
              const binLabel = `bin${i}`
              const placedbinLabel = `placedbin${i}`


              return (
                <Flex
                  key={`bin${i}`}
                  grow={1} shrink={1}
                  direction="column"
                  gap="0px"

                  style={{
                    // display: 'inline-grid',
                    // boxSizing: 'border-box',
                    // padding: 20,
                    // gridAutoFlow: vertical ? 'row' : 'column',
                    // margin: '0px',
                    minHeight: '87px'
                  }}
                >

                      <View
                        key={binLabel}
                        id={binLabel}
                        columns={columns}
                        items={items[binLabel]}
                        scrollable={scrollable}
                        disabled={items[placedbinLabel].length === numBins}
                        style={{flexGrow: 0, flexShrink: 0, minHeight: '50px', minWidth: '50px', width: '100%', margin: '0px', height: '100%'}}
                        unstyled={false}
                      >
                        <div className="cf-connector" />

                          {items[binLabel].map((value, index) => {

                            return (
                              <SortableItem
                                key={index}
                                id={value.id}
                                disabled={value.data.status !== 'placing' }
                                index={index}
                                style={getItemStyles}
                                wrapperStyle={wrapperStyle}
                                containerId={binLabel}
                                getIndex={getIndex}
                                value={value}
                                itemPlaced={itemPlaced}
                                valueFormat={valueFormat}
                              />
                            );
                          })}
                      </View>

                </Flex>
              )
            } )
          }

        </Grid>
      </Flex>
  );
}


function SortableItem({
  disabled,
  index,
  style,
  containerId,
  id,
  getIndex,
  wrapperStyle,
  value,
  valueFormat,
  itemPlaced
}: SortableItemProps) {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id: value.id,
    disabled
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={setNodeRef}
      disabled={disabled}
      value={value.id}
      dragging={isDragging}
      sorting={isSorting}
      index={index}
      wrapperStyle={typeof wrapperStyle === 'function' ? wrapperStyle({index}) : wrapperStyle }
      style={style({
        index,
        value: value.id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={(props) => { return renderOrderItem( { ...props, item: value, containerId, disabled, itemPlaced, valueFormat } )}}
    />
  );
}

function renderOrderItem({
  dragOverlay,
  dragging,
  sorting,
  index,
  fadeIn,
  listeners,
  ref,
  style,
  transform,
  transition,
  value,
  item,
  disabled,
  containerId,
  itemPlaced,
  valueFormat,
  ...props
}) {
  // let disabled = value.status !== 'placing';
  let className = '';

  let liStyle = {
    // ...wrapperStyle,
    // transition: [transition, wrapperStyle?.transition]
    transition: [transition]
      .filter(Boolean)
      .join(', '),
    '--translate-x': transform
      ? `${Math.round(transform.x)}px`
      : undefined,
    '--translate-y': transform
      ? `${Math.round(transform.y)}px`
      : undefined,
    '--scale-x': transform?.scaleX
      ? `${transform.scaleX}`
      : undefined,
    '--scale-y': transform?.scaleY
      ? `${transform.scaleY}`
      : undefined,
    '--index': index,
  }

  if ( item.data.status !== 'placing' ) {
    style.cursor = 'default';
  }

  const readyToPlace = item.data.status === 'placing' && containerId.startsWith( 'bin' ) && !dragging && !dragOverlay
  let statusClassnames = 'cf-dragitem-status'
  let status = ''

  if ( item.data.status === 'placed' ) {
    status = `${valueFormat ? format( valueFormat )( item.data.value ) : item.data.value}`
    statusClassnames = classNames( 'cf-dragitem-status', item.data.correct ? 'game-ak-success' : 'game-ak-error' )
  }

  let rendered = null;

  if ( containerId.startsWith( 'bin' ) || dragOverlay ) {
    rendered = (
      <li
        className={classNames(
          'cf-dragtoken-wrapper',
          fadeIn && 'cf-dragtoken-wrapper-fadein',
          dragOverlay && 'cf-dragtoken-wrapper-dragoverlay'
        )}
        style={liStyle}
        ref={ref}
      >
        <View  width="100%" className="cf-dragitem-token-wrapper" >
          {/*
          <div
            className={classNames(
              disabled && 'cf-dragtoken-disabled',
              'cf-dragitem cf-dragtoken-dragging',
              dragging && 'cf-dragtoken-dragging',
              ( !dragging || dragOverlay ) && 'dragOverlay',
              // handle && 'withhandle',
              dragOverlay && 'cf-dragtoken-wrapper-dragoverlay',
              className
            )}
            style={style}
            data-cypress="draggable-item"
            {...listeners}
            {...props}
            tabIndex={undefined}
          >

            {
              false ? (
                <div
                  className="cf-dragtoken-ttp"
                  onClick={itemPlaced}
                  data-no-dnd
                >
                  Tap to <br /> place
                </div>
              ) : null
            }

          </div>
          */}

          <div className="cf-dragitem-token" {...listeners} {...props} >
            {
              readyToPlace ? (
                <div
                  className="cf-dragitem-ttp"
                  onClick={itemPlaced}
                  data-no-dnd
                >
                  Tap to <br /> place
                </div>
              ) : null
            }
          </div>


        </View>
      </li>
    )
  } else {
    rendered = (
      <li
        className={classNames(
          'cf-dragitem-wrapper',
          fadeIn && 'cf-dragitem-wrapper-fadein',
          sorting && 'cf-dragitem-sorting',
          dragOverlay && 'cf-dragitem-wrapper-dragoverlay'
        )}
        style={liStyle}
        ref={ref}
      >
        <View  width="100%">
          <div
            className={classNames(
              disabled && 'cf-dragitem-disabled',
              'cf-dragitem cf-dragitem-dragging',
              dragging && 'cf-dragitem-dragging',
              ( !dragging || dragOverlay ) && 'dragOverlay',
              // handle && 'withhandle',
              dragOverlay && 'cf-dragitem-wrapper-dragoverlay',
              className
            )}
            style={style}
            data-cypress="draggable-item"
            {...listeners}
            {...props}
            tabIndex={undefined}
          >
            {
              status ? (
                <div
                  className={statusClassnames}
                  onClick={itemPlaced}
                >
                  {status}
                </div>
              ) : null
            }

            <div>
              {`${item.label} (${item.data.value})`}
            </div>

            {
              readyToPlace ? (
                <div
                  className="cf-dragitem-ttp"
                  onClick={itemPlaced}
                  data-no-dnd
                >
                  Tap to place
                </div>
              ) : null
            }

          </div>

        </View>
      </li>
    )
  }

  return  rendered;
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
