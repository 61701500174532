import { Flex, Image, View } from '@aws-amplify/ui-react';

import { Game } from '.';

export const GameView = ( { game, onAnswer, correct, gameStatus }) => {

    return (
        <Flex
            direction="column"
            margin="0 auto"
            height="100%"
            backgroundColor="#fff"
            paddingTop="large"
            width="100%"
            maxWidth="600px"
        >

            <View margin="0px 15px">
                <View padding="small large" fontSize="xxl" fontWeight="bold" textAlign="center">
                    {game.title}
                </View>

                <View margin="0px small" >

                    <Flex margin="0px auto" justifyContent="center">
                        <Image
                            src={game.thumbnail}
                            width="100%"
                            maxWidth="200px"
                            alt=""
                            marginBottom="medium"
                            marginLeft="auto"
                            marginRight="auto"
                        />
                    </Flex>

                    <Game 
                        game={game}
                        onAnswer={onAnswer}
                        correct={correct}
                        gameStatus={gameStatus}
                    />

                </View>
            
            </View>
        </Flex>
    )
}