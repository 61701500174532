import { Flex, Image, Link, View } from "@aws-amplify/ui-react";
import logo from "../../assets/RipeDataLogoVerticalLightBk.svg";

export const Footer = () => {
    return (
        <View padding="large" backgroundColor="#4c4d4e"  color="font.inverse">

            <Flex>
                <Image src={logo} className="App-logo" alt="logo" height={100} />

                <Flex alignItems="center" padding="large">
                    <Link href="/about" color="font.inverse">
                        About
                    </Link >

                    <Link href="/privacy" color="font.inverse">
                        Privacy
                    </Link>

                    <Link href="/tos" color="font.inverse">
                        Terms of Service
                    </Link>
                </Flex>
                
            </Flex>

            <View fontSize="8px" marginTop="30px">
                Site design / logo © 2023 RipeData LLC
            </View>
        </View>
    )
}