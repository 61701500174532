// import React, { useState } from 'react'
import KSUID from "ksuid";
import { Button, Flex, TextAreaField, TextField, View } from "@aws-amplify/ui-react";

export const DataSetAdd = ( { onClose, onAdd } ) => {

  function readFile( filename ) {
    return new Promise(( resolve, reject ) => {
      let fr = new FileReader();
      fr.onload = x=> resolve( fr.result );
      fr.readAsText( filename ); // or readAsDataURL(file) to get raw content
    })
  }

  async function onSubmit( event ) {
    event.preventDefault();

    let datasetId = KSUID.randomSync( Date.now() ).string;
    
    const form = new FormData(event.target);

    const name = form.get( "name" );
    const description = form.get( "description" );
    const source = form.get( "source" );
    const metadataFile = form.get( "metadatafile" );
    const datasetFile = form.get( "datasetfile" );

    // Load the data file
    const records = await readFile( datasetFile )

    let dataRecords = JSON.parse( records );

    // Load the metadata
    const metadata = await readFile( metadataFile )

    let fields = JSON.parse( metadata );

    // Iterate through the list of fields creating the array of fields for the datastore record.

    let fieldList = [];

    try {

      fieldList = fields.reduce( ( ary, d ) => {
        const fieldId = `FLD_${KSUID.randomSync( Date.now() ).string}`;

        let loc = d.loc;
        if ( d.loc.startsWith( "[*]." ) ) {
          loc = d.loc.slice( 4 );
        }

        let newRec = {
          ...d,
          dsId: datasetId,
          fieldId: fieldId,
          loc: loc,
          name: loc,
          description: `Field of type ${d.type}`
        }

        ary.push(newRec)

        return ary
      }, [] )
    } catch( error ) {
      console.log( 'FIELD ERROR:', error )
    }

    if ( fieldList.length === fields.length ) {


      // Now we can update the data source structure and return it to the calling proceedure.
      const update = {
        dsId: datasetId,
        name: name,
        description: description,
        source: source,
        fields: fieldList,
        data: dataRecords
      }

      onAdd && onAdd( update )
    }
  }

  return (
    <Flex direction="column" grow={1} shrink={1} overflow="hidden"  className="dp-sel" >
      <Flex justifyContent="space-between" alignItems="center" margin="medium 0px" className="dp-sel-label-cont" >
        <View className="dp-sel-label" >
          Add a new dataset
        </View>
      </Flex>

      <View grow={1} shrink={1} overflow="auto" className="dp-sel-list">
      <form onSubmit={(onSubmit)}>
        <Flex direction="column">

          <Flex direction="row" alignItems="center">
            <View>
              Name
            </View>
            <TextField
              name="name"
              placeholder="Dataset Name"
              labelHidden
              width="100%"
              required
            />

            <Button
              isDisabled={false}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variation="primary"
              isDisabled={false}
            >
              Add
            </Button>
          </Flex>

          <Flex direction="row">
            <View>
              Description
            </View>
            <TextAreaField
              name="description"
              placeholder="Dataset description"
              labelHidden
              width="100%"
              required
            />
          </Flex>

          <Flex direction="row"  alignItems="center">
            <View>
              Source
            </View>
            <TextField
              name="source"
              placeholder="Source specifier"
              labelHidden
              width="100%"
              required
            />
          </Flex>

          <Flex direction="row">
            <View>
              Metadata file
            </View>
            <View
              name="metadatafile"
              as="input"
              type="file"
              accept=".json"
              required
            />
          </Flex>

          <Flex direction="row">
            <View>
              Data file
            </View>
            <View
              name="datasetfile"
              as="input"
              type="file"
              accept=".json"
              required
            />
          </Flex>

        </Flex>
      
      </form>
      </View>

    </Flex>
  )
}
