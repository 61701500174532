import { getConfigField, makeDisplayable } from './utils'

// Augment the provided specification with a scale and axis
export function addNumericAxis( spec, config, opts ) {

  // Get the field associated with this axis
  let field = getConfigField( config )

  // Create the scale to be used by this axis
  let scale = {
    name: `${opts.prefix}Scale`,
    type: 'linear',
    round: true,
    nice: true,
    zero: true,
    domain: opts.domain,
    range: opts.range
  }

  // Add the scale
  spec.scales.push( scale )

  // If a title is defined use that, otherwise fallback to the field name
  // then to the field location.
  let axisTitle = config.title && config.title.title ? config.title.title : makeDisplayable( field.name ? field.name : field.loc )
  let domain = config.domain ? config.domain : {}
  let grid = config.grid ? config.grid : { show: false }
  let ticks = config.ticks ? config.ticks : {}

  // Now define the axis itself.
  let axis = {
    scale: `${opts.prefix}Scale`,
    grid: !!grid.show,
    domain: !!domain.show,
    format: config.format,
    orient: opts.orient,
    ticks: !!ticks.show,
    tickCount: 5,
    title: axisTitle
  }

  if ( opts.zindex ) {
    axis.zindex = opts.zindex
  }

  if ( opts.offset ) {
    axis.offset = opts.offset
  }

  // Add in the axis
  spec.axes.push( axis )
}
