import React, { useContext, useState } from "react"
import { Flex,View } from "@aws-amplify/ui-react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents";
import Modal from "react-modal";
import { BsGearFill , BsCardImage } from "react-icons/bs";

import { ChartDisplay, VegaEditor, genThumbnail } from "../../Charts";
import { withChartNode } from "./withChartNode";
import { GameContext } from "../GameContext";

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "90%",
      height: "90%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden"
    },
    overlay: {
        background: "#4c4d4e",
        zIndex: 1
    }
  };

export const LexicalChart = ( { chartId, className, format, nodeKey } ) => {
    const { game, onGameChange } = useContext( GameContext )
    const [editor] = useLexicalComposerContext();
    const [editing, setEditing] = useState( false );

    const chart = game.charts && game.charts[chartId] ? game.charts[chartId] : {}
    const chartData = game.datasets ? game.datasets : {}

    // const [chart, setChart] = useChart( storyId, chartId )
    // const [chartData, setChartData] = useDatasets( chart.datasets );

    function closeEditor() {
        setEditing( false )
    }

    function onSave( chartUpdate, dataUpdate ) {

        withChartNode( nodeKey, editor, ( node ) => {
            if ( !game.thumbnailChartId || game.thumbnailChartId === chartId ) {
                // A thumbnail hasn't been set, or it's been set to 
                // this chart so update the thumbnail.
  
                genThumbnail( chartUpdate, chartUpdate.datasets )
                .then( canvas => {

                    let dataurl = canvas.toDataURL( 'image/png' )

                    onGameChange( { thumbnail: dataurl, thumbnailChartId: chartId } )

                } )
                .catch( error => {
                    console.log( 'THUMBNAIL GEN ERROR:', error )
                })
            }

            // Update our state with the new chart specification.
            let newCharts = { ...game.charts };
            newCharts[chartId] = chartUpdate.chart;

            // FIXME - may need to integrate the new dataset into the existing datasets.
            // let newDatasets = { ...game.datasets };
            onGameChange( { charts: newCharts, datasets: chartUpdate.datasets } )

            // Close the editor
            closeEditor()
        } )
    }

    function setThumbnail() {
        if ( game.thumbnailChartId !== chartId ) {
            // A thumbnail hasn't been set, or it's been set to 
            // this chart so update the thumbnail.

            genThumbnail( chart, chartData )
            .then( canvas => {

                let dataurl = canvas.toDataURL( 'image/png' )

                onGameChange( { thumbnail: dataurl, thumbnailChartId: chartId } )

            } )
            .catch( error => {
                console.log( 'THUMBNAIL GEN ERROR:', error )
            })
        }
    }

    // useEffect(() => {
    //     // if ( chart.datasets.id )
    //     // let ids = chart.datasets.reduce( ( obj, d ) => {
    //     //     obj[d.pk] = true
    //     //     return obj
    //     // }, {} )
    //     // console.log( 'UPDATE DATASETS:', chart.datasets, chartData )

    //     setChartData( chart.datasets )

    // }, [chart.datasets] );

    return (
        <BlockWithAlignableContents
            className={className}
            format={format}
            nodeKey={nodeKey}
        >

            <View position="relative">

                <ChartDisplay
                    chartData={chartData}
                    chartConfig={chart.spec ? chart.spec : {}}
                />

                {
                    editor._editable && (
                        <Flex
                            direction="row"
                            position="absolute"
                            bottom="large"
                            left="large"
                            gap="xxxl"
                            justifyContent="space-between"
                        >
                            <Flex
                                alignItems="center"
                                color="#f47e39"
                                gap="xs"
                                fontSize="large"
                                onClick={() => { setEditing( true )}}
                                cursor="pointer"
                            >
                                <BsGearFill
                                    style={{cursor: "pointer"}}
                                    color="#f47e39"

                                />
                                <View style={{cursor: "pointer"}}>
                                    Edit Chart
                                </View>
                            </Flex>

                            <Flex
                                alignItems="center"
                                color={ game.thumbnailChartId === chartId ? "#f47e39" : undefined }
                                gap="xs"
                                fontSize="large"
                                cursor="pointer"
                                onClick={() => { setThumbnail()}}
                            >
                                <BsCardImage
                                    style={{cursor: "pointer"}}
                                    color={ game.thumbnailChartId === chartId ? "#f47e39" : undefined }
                                />
                                <View style={{cursor: "pointer"}}>
                                    Game Image
                                </View>
                            </Flex>
                        </Flex>
                    )
                }

            </View>

            <Modal
                isOpen={editing}
                onRequestClose={closeEditor}
                style={customStyles}
                contentLabel="Chart Editor"
            >
                <VegaEditor
                    chartId={chartId}
                    chart={chart}
                    datasets={chartData}
                    onClose={closeEditor}
                    onSave={onSave}
                />

            </Modal>

        </BlockWithAlignableContents>
    )
}