import { useEffect, useReducer } from "react";
import { generateClient } from "aws-amplify/api";
import { remove } from "aws-amplify/storage";
import { listGames } from "../../graphql/queries";
import { deleteGame as dbDeleteGame } from "../../graphql/mutations";
import { DeletePublished } from "./UpdatePublished";

export const useAllGameList = () => {

  const [games, setGames] = useReducer(
    ( state, update ) => {
      const newState = {...state, ...update }

      // Check to see if the list of games is being updated.
      if ( Array.isArray( update.list ) ) {

        // Compare the new list with the old list to identify any that may have been removed
        let currIds = update.list.reduce( ( obj, d ) => {
          obj[d.gameId] = true

          return obj
        }, {} );

        // Create a list of games that have been removed.
        let rmList = state.list.filter( d => {
          return !currIds[d.gameId]
        } );

        // Now iterate through the list of IDs removing any that have been deleted.
        rmList.forEach( d=> {
          deleteGame( d.gameId )
        } );
      }

      return newState
    }, {
      _loaded: false,
      _fetching: false,
      _error: '',
      list: []
    }
  )

  useEffect(() => {
 
    if ( !games._fetching && !games._loaded ) {

      setGames( { _fetching: true, _error: '' } )

      const client = generateClient();
      client.graphql({ query: listGames })
        .then( response => {
          let items = []
          if ( response.data && response.data.listGames && Array.isArray( response.data.listGames.items ) ) {
            items = response.data.listGames.items
          }

          setGames( { _fetching: false, _loaded: true, list: items } )
        })
        .catch( error => {
          console.log( 'ERROR:', error )
        })
    }
  }, [games] );

  async function deleteGame( game ) {
    const id = game.gameId;

    if ( id ) {

      try {
        // Remove the DB record for the game.
        const client = generateClient();
        await client.graphql({
          query: dbDeleteGame,
          variables: { input: { gameId: id } },
        })

        // The game was removed from the DB so we need to remove it from the list.

        let newList = games.list.filter( d => {
          return d.gameId !== id
        } )

        setGames( { list: newList } );

        // Then remove the file associated with the game.
        remove( { key: `${id}.json.gz` })

        // Go ahead and delete it from the published games table as well.
        DeletePublished( game );


      } catch( error ) {
        console.log( 'GAME DELETE ERROR:', error )
      }

      // Storage.remove( `${id}.json.gz` )
      //   .then( response => {
      //     return API.graphql({
      //       query: dbDeleteGame,
      //       variables: { input: { gameId: id } },
      //     })
      //   })
      //   .then( response => {
      //     // The game was removed from the DB so we need to remove it from the list.

      //     let newList = games.list.filter( d => {
      //       return d.gameId !== id
      //     } )

      //     setGames( { list: newList } );
      //   })
      //   .then( response => {
      //     // Go ahead and delete it from the published games table as well.


      //     DeletePublished( game );
      //   })
      //   .catch(error => {
      //     // console.log( 'STORY DELETE ERROR:', error )
      //   })
    }
  }

  return [games, deleteGame]
};