import React, { useContext } from 'react';
import { CheckboxField, SelectField, View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { FixedCountDataSelector, OrderOptions } from './DataSelector';
import { TextInput } from './TextInput';

const maxOptions = 9;

export const OrderEmConfig = () => {
  const { config, updateConfig, data } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill({label:'', value:0});
  const optionsConfig = config.optionsConfig ? config.optionsConfig : {};
  const topLabel = config.topLabel ? config.topLabel : '';
  const bottomLabel = config.bottomLabel ? config.bottomLabel : '';
  const format = config.format ? config.format : '';

  // sort the options for selection as the initial item.
  const sortedOptions = options.slice().sort( ( a, b ) => {
    let A = a.value;
    let B = b.value;

    let compare
    if ( A !== undefined && B !== undefined ) {
      compare = B < A ? 1 : B > A ? -1 : 0
    } else {
      compare = ( A === undefined && B === undefined ) ? 0 : A === undefined ? -1 : 1
    }

    return compare;
  } );

  // Now we remove what will be the initial value.
  let middleIdx = -1;

  if ( config.initValue ) {
      // An initial value was specified so see if it exists.
      middleIdx = sortedOptions.findIndex( d => { return d.label === config.initValue });
  }

  if ( middleIdx < 0 ) {
      // We don't have a middle value yet so compute it.
      middleIdx = Math.trunc( sortedOptions.length / 2 ) - 1;
  }

  // Now filter the options to remove the initial value so the remaining can
  // be ordered.

  let orderedOptions = options;
  if ( middleIdx >= 0 ) {
    orderedOptions = options.filter( d => { return d.label !== sortedOptions[middleIdx].label });
  }

  function updateOptions( update ) {
    // Add the initial value back into the options list.
    let newOptions = [sortedOptions[middleIdx], ...update];

    // Then push the update into the configuration.
    updateConfig( { options: newOptions } );
  }

  return (
      <View  className="gp-typ" >

        <TextInput
          label="Top Label"
          key="top"
          value={topLabel}
          placeholder={`Top label`}
          onChange={( val ) => { updateConfig({ topLabel: val })}}
          size="small"
        />

        <TextInput
          label="Bottom Label"
          key="bottom"
          value={bottomLabel}
          placeholder={`Bottom label`}
          onChange={( val ) => { updateConfig({ bottomLabel: val })}}
          size="small"
        />

        <TextInput
          label="Value Format"
          key="format"
          value={format}
          placeholder={`Value format`}
          onChange={( val ) => { updateConfig({ format: val })}}
          size="small"
          marginBottom="small"
        />

        <CheckboxField
          value={config.descending ? true : false }
          checked={config.descending ? true : false}
          onChange={() => { updateConfig( { descending: !config.descending })}}
          label="Sort descending"
          size="small"
          marginBottom="large"
        />

        <SelectField
          key="InitialValue"
          label="Initial Value"
          value={config.initValue}
          onChange={( e ) => { updateConfig({ initValue: e.target.value })}}
        >
          <option key="default" value="">
            Default
          </option>
          {
            sortedOptions.length ? sortedOptions.map( d => {
              return(
                <option key={d.label} value={d.label}>
                  {`${d.label} - ${d.value}`}
                </option>
              ) }
            ) : null
          }

        </SelectField>

        <FixedCountDataSelector
          data={data}
          options={options}
          config={optionsConfig}
          numOptions={9}
          onUpdate={( val ) => {updateConfig( val )}}
          valueFormat={config.format}
        />
        
        <OrderOptions
          key={`${sortedOptions[middleIdx].label}`}
          options={orderedOptions}
          config={config}
          onUpdate={updateOptions}
        />

      </View>
  )
}
