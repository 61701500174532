import React from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { AppRoutes } from "./AppRoutes";
import Modal from "react-modal";

// import "@aws-amplify/ui-react/styles.css";
import "./App.css";

const App = () => {
  Modal.setAppElement( "#root" )

  return (
    <Authenticator.Provider>
      <AppRoutes />
    </Authenticator.Provider>
  );
};

export default App;