import { getDataValues } from './getDataValues'

export function initFilterState( filters, chartData ) {

  let newState = { ifSelection: [], ifValues: [], ifKeys: [] }

  if ( Array.isArray( filters ) ) {
    newState = filters.reduce( ( newFilters, d, i ) => {

      if ( d.field.type === 'string' ) {

        // Get the unique values for the specified filter field then add it
        // to the array of selection values.
        let dataValues = getDataValues( chartData, d )

        // Now that we have the selection values, order them based on the
        // values from the field definition.

        let selVal = dataValues
        if ( d.field && d.field.values ) {
          selVal = d.field.values.slice( v => {
            return dataValues.includes( v )
          } )
        }

        newFilters.ifSelection.push( selVal )

        // Use the first value (or default value if one is provided) as the
        // .
        let fltVal = d.dflt ? d.dflt : selVal[0]
        newFilters.ifValues.push( fltVal )

        // Create a key for the component so it gets regenerated if the
        // underlying selection data changes.
        newFilters.ifKeys.push( `ifk-${newFilters.ifSelection[i].length}-${newFilters.ifValues[i]}-${i}` )

      } else if ( d.field.type === 'number' ) {

        // let dataValues = getDataValues( chartData, d )
        let dataValues = [d.field.min, d.field.max]

        // Save the extents
        newFilters.ifSelection.push( dataValues )

        // Set this to the extents
        let dMin = Array.isArray( d.dflt ) && d.dflt.length ? !isNaN( Number( d.dflt[0] ) ) ? Number( d.dflt[0] ) : d.field.min : d.field.min;
        dMin = Math.max( dMin, d.field.min );

        let dMax = Array.isArray( d.dflt ) && d.dflt.length ? !isNaN( Number( d.dflt[1] ) ) ? Number( d.dflt[1] ) : d.field.max : d.field.max;
        dMax = Math.min( dMax, d.field.max );

        newFilters.ifValues.push( [dMin, dMax] )

        // Create a key for the component so it gets regenerated if the
        // underlying selection data changes.
        newFilters.ifKeys.push( `ifk-${i}` )
      }

      return newFilters
    }, newState )
  }

  return newState
}
