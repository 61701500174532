import { useEffect, useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import classNames from 'classnames';

import {Description} from '../Description';
import { DoneButton } from '../DoneButton';
import { shuffleArray } from '../utils';

export const Split = ( { config, onAnswer } ) => {
    const [selectedAnswer, setSelectedAnswer] = useState();
    const [allOptions, setAllOptions] = useState([]);

    let desc = config.description ? config.description : "No Description";

    useEffect( () => {
        let options = Array.isArray( config.options ) ? config.options.slice() : [];
        
        if ( config.shuffle !== false ) {
            shuffleArray( options );
        }

        setAllOptions( options );
    }, [config.options, config.answer] )
    
    return (
        <Flex direction="row" maxWidth="450px" margin="large auto" >
            <Flex direction="column" width="50%">
                <Description desc={desc} />

                <DoneButton
                    isDisabled={!selectedAnswer}
                    checkAnswer={() => onAnswer && onAnswer( { correct: ( selectedAnswer === config.answer ), answer: selectedAnswer })}
                />

            </Flex>

            <Flex className="game-ctr" direction="column" width="50%">
                {
                    allOptions.map( ( d, i ) => {
                        let itmStyle = {};
                        let colorStyle;

                        if ( d === selectedAnswer ) {
                            colorStyle = "game-mc-sel"
                        }

                        let classes = classNames( "game-mc-item", colorStyle )

                        return (
                            <div
                                key={i}
                                className={classes}
                                onClick={() => {setSelectedAnswer(d)}}
                                style={itmStyle}
                            >
                                {d}
                            </div>
                        )
                    })
                }

            </Flex>
        </Flex>

    )
  }