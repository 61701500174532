import { SelectField } from "@aws-amplify/ui-react";

export const GameCategory = ( { category, onChange, ...remaining } ) => {

  return (
    <SelectField
      label="Category"
      labelHidden
      value={category}
      onChange={(event) => { onChange( event.target.value )}}
      size="default"
      placeholder="Set category!"
    >
      <option value="Careers">Careers</option>
      <option value="Education">Education</option>
      <option value="Government">Government</option>
      <option value="Healthcare">Healthcare</option>
      <option value="Households">Households</option>
      <option value="Location">Location</option>
      <option value="Population">Population</option>
    </SelectField>
  )
}