import { getConfigField } from './utils'
import { genTooltip } from './tooltip'
import { addColorScale } from './colorscale'

const aggData = '_aggData_'
const aggVLoc = 'v'
const wedgeData = '_wedgeData_'

export function donut( config, data ) {
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated bar chart specification.',
    usermeta: {embedOptions: {renderer: "svg"}},
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    // height: 600,
    padding: 5,

    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      },
      {
        name: 'height',
        update: '600'
      },
      {
        name: 'radius',
        update: 'min( height, width ) / 2'
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  // The wedge value is defined so we have a chart to define.
  let wField = getConfigField( config.w )
  let vField = getConfigField( config.v )

  // We must have a wedge field in order to do anything.
  if ( wField ) {

    // Push in the base dataset
    spec.data.push( {
      name: wField.datasetId,
      transform: []
    } )

    // Then setup the aggregation dataset.
    let aggDs = {
      name: aggData,
      source: wField.datasetId,
      transform: []
    }

    let aggTx = {}

    if ( vField ) {

      // We have an aggregation field so sum those fields.
      aggTx = {
        type: 'aggregate',
        groupby: [`${wField.loc}`, `${wField.loc}`],
        fields: [`${vField.loc}`],
        valid: true,
        ops: ['sum'],
        as: [aggVLoc]
      }

    } else {

      // No aggregation field specified so just count instances.
      aggTx = {
        type: 'aggregate',
        groupby: [`${wField.loc}`, `${wField.loc}`],
        ops: ['count'],
        as: [aggVLoc]
      }
    }

    if ( wField.sortField ) {
      // Add in the sort field so we can order the colors properly.
      aggTx.groupby.push( wField.sortField )
    }

    aggDs.transform.push( aggTx )

    // Filter for valid wedge values.
    aggDs.transform.push(
      {
        type: 'filter',
        expr: `datum.${wField.loc}`
      }
    )

    // Sort the data based on the sort field if it exists.
    if ( wField.sortField ) {
      // Add in the sort field so we can order the colors properly.
      aggDs.transform.push(
        {
          type: 'collect',
          sort: { field: wField.sortField }
        }
      )
    }

    // And push the dataset definition into the list of data.
    spec.data.push( aggDs )

    // Now generate the layout for the donut
    let wedgeDs = {
      name: wedgeData,
      source: aggData,
      transform: []
    }

    // Add the stack transform to compute the y offsets.
    wedgeDs.transform.push( {
      type: 'pie',
      sort: !!config.w.sortBySize,
      field: aggVLoc,
      startAngle: 0,
      endAngle: 6.29
    } )

    // Push the wedge data into the spec.
    spec.data.push( wedgeDs )

    // First add the scales and axes for the bars...
    // To do this we need to create a field spec that points to the aggregate
    // dataset.
    let aggWField = { ...wField }
    aggWField.datasetId = aggData

    let aggVField = vField ? { ...vField } : { }
    aggVField.datasetId = aggData
    aggVField.name = vField ? vField.name : 'Count'
    aggVField.loc = aggVLoc

    // Add in the color scale based on the stacked field.
    addColorScale( spec, aggWField, 'wedge' )

    // Now we can add in the mark(s)
    let wedgeMark = {
      type: 'arc',
      from: { data: wedgeData },
      encode: {
        enter: {
          x: { signal: 'width / 2'},
          y: { signal: 'height / 2'}
        },
        update: {
          fill: { scale: 'wedge', field: wField.loc },
          startAngle: { field: 'startAngle' },
          endAngle: { field: 'endAngle' },
          padAngle: 0,
          innerRadius: { signal: 'radius * 2 / 3' },
          outerRadius: { signal: 'radius' },
          cornerRadius: 0
        }
      }
    }

    if ( config.tt && config.tt.show ) {
      // A tooltip is requested so create one from the bar and value marks.
      let tt = []

      if ( aggWField ) {
        // Generate the tooltip spec from the existing configuration.
        tt.push( {
          field: aggWField,
          label: config.w.title
        } )

        tt.push( {
          field: aggVField,
          label: config.v.title && config.v.title.title ? config.v.title.title : '',
          format: config.v.format ? config.v.format : config.tt.format ? config.tt.format : undefined
        } )

        // Generate and add the tooltip to the mark.
        wedgeMark.encode.update.tooltip = { signal: genTooltip( tt ) }

        // Now add the hover coloring so we have a visual indication of what
        // the tooltip applies to.
        let hoverClr = config.color && config.color.hover ? config.color.hover : {}

        wedgeMark.encode.hover = {
          fill: { value: hoverClr.fill ? hoverClr.fill : 'red' }
        }
      }
    }

    spec.marks.push( wedgeMark )
  }

  // console.log( 'BAR CONFIG:', config )

  return spec
}
