import { getConfigField } from './utils'
import { genTooltip } from './tooltip'
import { addColorScale } from './colorscale'
import { addNumericAxis } from './numericaxis'
import { addCategoricalAxis } from './categoricalaxis'

const aggData = '_barData_'
const aggVLoc = 'v'
const stackVBase = 'y0'
const stackVLoc = 'y1'
const stackData = '_stackBarData_'

export function stackedbar( config, data ) {
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated bar chart specification.',
    usermeta: {embedOptions: {renderer: "svg"}},
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    height: 600,
    padding: 5,

    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  // The bars and stacks fields are defined so we have a chart to define.
  let bField = getConfigField( config.b )
  let sField = getConfigField( config.s )
  let vField = getConfigField( config.v )

  // We must have a bars field in order to do anything.
  if ( bField && sField ) {

    // Push in the base dataset
    spec.data.push( {
      name: bField.datasetId,
      transform: []
    } )

    // Then setup the aggregation dataset.
    let aggDs = {
      name: aggData,
      source: bField.datasetId,
      transform: []
    }

    let aggTx = {}

    if ( vField ) {

      // We have an aggregation field so sum those fields.
      aggTx = {
        type: 'aggregate',
        groupby: [`${bField.loc}`, `${sField.loc}`],
        fields: [`${vField.loc}`],
        valid: true,
        ops: ['sum'],
        as: [aggVLoc]
      }

      // Only use values that are valid numbers
      aggDs.transform.push( {
        type: 'filter',
        expr: `isNumber( datum.${vField.loc} )`
      } )

    } else {

      // No aggregation field specified so just count instances.
      aggTx = {
        type: 'aggregate',
        groupby: [`${bField.loc}`, `${sField.loc}`],
        ops: ['count'],
        as: [aggVLoc]
      }
    }

    if ( sField.sortField ) {
      // Add in the sort field so we can order the colors properly.
      aggTx.groupby.push( sField.sortField )
    }

    aggDs.transform.push( aggTx )

    // Filter for valid bar values.
    aggDs.transform.push(
      {
        type: 'filter',
        expr: `datum.${bField.loc}`
      }
    )
    aggDs.transform.push(
      {
        type: 'filter',
        expr: `datum.${sField.loc}`
      }
    )

    // And push the aggregated dataset definition into the list of data.
    spec.data.push( aggDs )

    // Now create the stacked data from the aggregated data.
    let stackDs = {
      name: stackData,
      source: aggData,
      transform: []
    }

    // Add the stack transform to compute the y offsets.
    stackDs.transform.push( {
      type: 'stack',
      groupby: [bField.loc],
      sort: { field: sField.sortField ? sField.sortField : sField.loc },
      field: [aggVLoc]
    } )

    // And push the stacked dataset definition into the list of data.
    spec.data.push( stackDs )

    // First add the scales and axes for the bars...
    // To do this we need to create a field spec that points to the aggregate
    // dataset.
    let aggBField = { ...bField }
    aggBField.datasetId = stackData

    let aggSField = { ...sField }
    aggSField.datasetId = stackData

    let stackVField = vField ? { ...vField } : { }
    stackVField.datasetId = stackData
    stackVField.name = vField ? vField.name : 'Count'
    stackVField.loc = stackVLoc

    // let barConfig = { ...config.b, field: aggBField, sort: { field: aggVField.name, order: 'ascending'} }
    let barConfig = { ...config.b, field: aggBField }

    // Now configure the scale and axis for the bars.
    addCategoricalAxis( spec, barConfig, 'b', 'bottom', 'width' )

    let valConfig = { ...config.v, field: stackVField }

    let vOpts = {
      prefix: 'v',
      orient: 'left',
      domain: { data: stackVField.datasetId, field: stackVField.loc },
      range: 'height',
    }

    addNumericAxis( spec, valConfig, vOpts )

    // Add in the color scale based on the stacked field.
    addColorScale( spec, { ...config.s, field: aggSField }, 'stack' )

    // Now we can add in the mark(s)
    let barMark = {
      type: 'rect',
      from: { data: stackData },
      encode: {
        enter: {
          x: { scale: 'bScale', field: aggBField.loc },
          width: { scale : 'bScale', band: 1 },
          y: { scale: 'vScale', field: stackVBase },
          y2: { scale: 'vScale', field: stackVLoc }
        },
        update: {
          fill: { scale: 'stack', field: sField.loc }
        },
        // hover: {
        //   fill: { value: hoverClr.fill ? hoverClr.fill : 'red' }
        // }
      }
    }

    if ( config.tt && config.tt.show ) {
      // A tooltip is requested so create one from the bar and value marks.
      let tt = []

      if ( aggBField ) {
        // Generate the tooltip spec from the existing configuration.
        tt.push( {
          field: aggBField,
          label: config.b.title
        } )

        tt.push( {
          field: aggSField,
          label: config.s.title
        } )

        let stackTipField = { ...stackVField }
        stackTipField.loc = aggVLoc

        tt.push( {
          field: stackTipField,
          label: config.v && config.v.title && config.v.title.title ? config.v.title.title : stackVField.name,
          format: config.tt.format ? config.tt.format : config.v.format ? config.v.format : undefined
        } )

        // Generate and add the tooltip to the mark.
        barMark.encode.update.tooltip = { signal: genTooltip( tt ) }

        // Now add the hover coloring so we have a visual indication of what
        // the tooltip applies to.
        let hoverClr = config.color && config.color.hover ? config.color.hover : {}

        barMark.encode.hover = {
          fill: { value: hoverClr.fill ? hoverClr.fill : 'red' }
        }
      }
    }

    spec.marks.push( barMark )
  }

  // console.log( 'BAR CONFIG:', config )

  return spec
}
