import {
    ConnectFourDrag,
    ConnectFourCategoryTap,
    OrderEm,
    OrderEmDrag,
    Split
} from './components';

require( './Games.scss' )

export const Game = ( { game, onAnswer, gameStatus } ) => {

    const config = game && game.game ? game.game : {}
    let component = null

    if ( config.type === 'connectfour' ) {
        component = ( <ConnectFourDrag gameId={game.gameId} config={config} onAnswer={onAnswer} /> );
    } else if ( config.type === 'connectfourcategory' ) {
        component = ( <ConnectFourCategoryTap gameId={game.gameId} config={config} onAnswer={onAnswer} /> );
    
    } else if ( config.type === 'split' ) {
        component = ( <Split gameId={game.gameId} config={config} onAnswer={onAnswer} /> );
    } else if ( config.type === 'orderem' ) {
        if ( config.interface && config.interface === 'touch' ) {
            component = ( <OrderEm gameId={game.gameId} config={config} onAnswer={onAnswer} /> );
        } else {
            component = ( <OrderEmDrag gameId={game.gameId} config={config} onAnswer={onAnswer} gameStatus={gameStatus} /> );
        }
    } else {
        component = null
    }
   
    return  component
  }