/*eslint complexity: ["warn", 40]*/
import React, { useEffect, useRef, useReducer } from 'react'
import isEqual from 'lodash/isEqual'
import { Vega } from 'react-vega'
import { ErrorBoundary } from "react-error-boundary";
import { Icon, SelectField, View } from '@aws-amplify/ui-react';
import { Range } from '../../Controls/Slider'
import { googleConfig } from './configs'
import { genChartSpec, getVegaData, initFilterState } from './ChartSpecifications'

require( './ChartDisplay.scss' )

export const ChartDisplay = ( { chartConfig, chartData } ) => {

  const [state, setState] = useReducer(
  ( state, stateUpdate ) => {

    const newState = {...state, ...stateUpdate }

    return newState
  }, {
      ifSelection: [],
      ifValues: [],
      ifKeys: []
    },
  )

  // let filters = chartConfig && Array.isArray( chartConfig.iflt ) ? chartConfig.iflt : []
  let filters = chartConfig && chartConfig.iflt ? chartConfig.iflt : undefined

  // Load the selection values from the data if any filters have been
  // defined or if they have changed.
  useEffect( () => {

    // Check to see if the filters have changed...
    if ( !isEqual( prevFilters.current, filters ) ) {

      // Generate the filter values based on the current data.
      let newState = initFilterState( filters, chartData )

      // Update the state values with the new filter specifications.
      setState( newState )
    }
  }, [filters, chartData] )

  const prevFilters = useRef()
  useEffect( () => {
    prevFilters.current = filters
  } )

  // Generate the VEGA specification
  const chartSpec = genChartSpec( chartConfig, chartData )

  let vegaData = getVegaData( chartConfig, chartSpec, chartData, state )

  function updateFilterValue( fltIdx, val ) {
    // console.log( 'UPDATE FILTER:', fltIdx, val )
    let newVals = [...state.ifValues]
    newVals[fltIdx] = val

    setState( { ifValues: newVals } )
  }

  // console.log( 'CHART PROPERTIES:', chartConfig )
  // console.log( 'CHART DATA:', chartData )
  // console.log( 'VEGA SPEC:', chartSpec )
  // console.log( 'VEGA DATA:', vegaData )

  const wrapperStyle = {
    // maxWidth: '500px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    padding: '20px 10px'
  }

  const signalListeners = {
    // click : this.onSelect
  }

  let filtersLoaded = chartConfig && ( !chartConfig.iflt || ( Array.isArray( chartConfig.iflt ) && ( chartConfig.iflt.length === 0 || ( chartConfig.iflt.length && state.ifSelection.length ) ) ) )

  // Count the records so we can regenerate the chart as the data changes.
  let recCnt = Object.keys( vegaData ).reduce( ( cnt, d ) => {
    return cnt + vegaData[d].length;
  }, 0 )

  return (
    <ErrorBoundary fallback={<div>Invalid chart configuration</div>}>
      <div
        className="vcd-container"
        style={wrapperStyle}
      >
        {
          chartConfig && Array.isArray( chartConfig.iflt ) && chartConfig.iflt.length ? (
            <div className="vcd-ctl" >
              {
                chartConfig.iflt.map( ( d, i ) => {
                  let fltCtl = null
                  let fld = d.field ? d.field : {}

                  if ( fld.type === 'string' ) {
                    // console.log( 'FILTER( SELECT ):', d, state.ifSelection[i] )
                    // console.log( 'FS:', state.ifSelection )

                    fltCtl = (
                      <SelectField
                        key={state.ifKeys.length > i ? state.ifKeys[i] : `${i}`}
                        label={d.label ? d.label : fld.name}
                        className="vcd-ctl-itm"
                        options={state.ifSelection.length > i ? state.ifSelection[i] : []}
                        onChange={( event ) => { updateFilterValue( i, event.target.value ) }}
                        value={state.ifValues.length > i ? state.ifValues[i] : ''}
                        placeholder="Search ..."
                      />
                    )
                  } else if ( fld.type === 'number' ) {

                    // console.log( 'FILTER( RANGE ):', d, state.ifSelection[i], state.ifValues[i] )

                    if ( state.ifSelection[i] && state.ifValues[i] ) {
                      const valueRange = state.ifSelection.length > i ? state.ifSelection[i] : [0, 1]
                      const valueStep =  ( valueRange[1] - valueRange[0] ) / 20

                      // compute the mark locations for the range slider.

                      // let markFormat = d.format ? format( d.format ) : ( val ) => { return `${val}` }
                      let markFormat = ( val ) => { return `${val}` }
                      const valueMarks = valueRange.reduce( ( obj, v ) => {
                        obj[v] = markFormat( v )
                        return obj
                      }, {} );
                      
                      let val1 = state.ifValues.length > i ? state.ifValues[i][0] : 0;
                      let val2 = state.ifValues.length > i ? state.ifValues[i][1] : 0;
                      
                      let minVal;
                      let maxVal;

                      if ( val1 < val2 ) {
                        minVal = Math.max( state.ifSelection[i][0], val1 );
                        maxVal = Math.min( state.ifSelection[i][1], val2 );
                      } else {
                        minVal = Math.max( state.ifSelection[i][0], val2 );
                        maxVal = Math.min( state.ifSelection[i][1], val1 );
                      }

                      fltCtl = (
                        <Range
                          key={state.ifKeys.length > i ? state.ifKeys[i] : `${i}`}
                          label={d.label ? d.label : fld.name}
                          min={valueRange[0]}
                          max={valueRange[1]}
                          step={valueStep}
                          marks={valueMarks}
                          markFormat={d.format}
                          value={[minVal, maxVal]}
                          onChange={( val ) => { updateFilterValue( i, val ) }}
                          color="#8bc53f"
                          tipProps={{
                            prefixCls: 'rc-slider-tooltip',
                            placement: 'top'
                          }}
                          tipFormatter={( val ) => { return markFormat( val ) }}
                        />
                      )
                    }
                  }

                  return fltCtl
                } )
              }

            </div>
          ) : null
        }

        {
          chartConfig.type && filtersLoaded ? (
            <>
              {
                chartConfig.title ? (
                  <View className="vcd-title" marginBottom="medium">
                    {chartConfig.title}
                  </View>
                ) : null
              }

              <Vega
                key={`${chartConfig.type}-${recCnt}`}
                className="vcd-chart"
                spec={chartSpec}
                data={vegaData}
                signalListeners={signalListeners}
                actions={false}
                config={googleConfig}
              />
            </>
          ) : (
            <View paddingLeft="large">

              <Icon
                  pathData="M7,11v8c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-8l-2,2L7,11z M0,19 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-8l-6,3V19z M17,7l-3,3v9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1V8.74 C19.74,8.89,19.42,9,19,9C17.08,9,17,7,17,7z M19,1h-4c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h1.59L10.8,8.78L7.45,7.11l0,0.01	C7.31,7.05,7.16,7,7,7S6.69,7.05,6.56,7.11l0-0.01l-6,3l0,0.01C0.23,10.28,0,10.61,0,11c0,0.55,0.45,1,1,1 c0.16,0,0.31-0.05,0.44-0.11l0,0.01L7,9.12l3.55,1.78l0-0.01C10.69,10.95,10.84,11,11,11c0.28,0,0.53-0.11,0.71-0.29L18,4.41V6 c0,0.55,0.45,1,1,1s1-0.45,1-1V2C20,1.45,19.55,1,19,1z"
                  width="100px"
                  height="100px"
                  color="background.disabled"
              />

          </View>
          )
        }
      </div>
    </ErrorBoundary>
  )
}

ChartDisplay.propTypes = {
  // chartData: PropTypes.object.isRequired,
  // chartConfig: PropTypes.object.isRequired
}
