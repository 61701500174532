

// Verify that the fields in the configuration are present in the current
// datasets.
export function getDataId( config, toCheck ) {
  let validId = ''

  if ( Array.isArray( toCheck ) && config ) {
    toCheck.forEach( ( p ) => {
      if ( config[p] && config[p].datasetId ) {
        validId = config[p].datasetId
      } else if ( config[p] && config[p].field && config[p].field.datasetId ) {
        validId = config[p].field.datasetId
      }
    } )
  }

  return validId
}
