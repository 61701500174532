import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { BsX } from "react-icons/bs";
import { Flex, View } from "@aws-amplify/ui-react";

const defaultValue = 'Drop field here!'

export function dropBackgroundStyle( isOver, canDrop ) {
  let style = {}

  if ( isOver && canDrop ) {
    style.backgroundColor = '#189B66'  // Dark green
    style.color= "font.inverse"
  }
  else if (canDrop) {
    style.backgroundColor = '#BAE1D1'  // Light green
    style.color= "font.tertiary"
  }

  return style
}

export const DropField = ( { prop, label, field, accept, onDrop, validDataset, restrictions } ) => {

  const [ { isOver, canDrop }, drop ] = useDrop( {
    accept,
    drop: ( item ) => { onDrop( { [prop]: item } ) },
    canDrop: ( item ) => {
      const datasetOk = ( !validDataset || ( validDataset && validDataset === item.datasetId ) )
      const fieldOk = ( !restrictions || ( restrictions && restrictions.every( f => { return !!item[f] } ) ) )
      return ( datasetOk && fieldOk )
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    } ),
  } )

  let labelStyle = dropBackgroundStyle( isOver, canDrop )

  const dropTypes = accept.join( ' ' )
  const dropLabel = `${defaultValue} Accepts: ${dropTypes}`

  const fname = field && field.name ? field.name : field && field.loc ? field.loc : dropLabel
  const classes = fname === dropLabel ? 'cp-cfg-drp-fld cp-cfg-drp-fld-dflt' : 'cp-cfg-drp-fld'
  const iconColor = fname === dropLabel ? "font.tertiary" : "font.tertiary";

  let rendered = null

  if ( label ) {

    // Old style with incorporated label.
    rendered = (
      <Flex
        borderRadius="xs"
        marginBottom="xs"
        className="cp-cfg-drp"
      >
        <View className="cp-cfg-drp-lbl">
          {label}
        </View>

        <View
          ref={drop}
          className={classes}
          style={labelStyle}
        >
          <View width="100%" className="cp-cfg-drp-fld-val">
            {fname}
          </View>

          <View className="cp-cfg-drp-icn" onClick={() => { onDrop( { [prop]: null } ) }}>
            <BsX color={iconColor} />
          </View>
        </View>

      </Flex>
    )
  } else {

    // New style without the label.
    rendered = (
      <View
        ref={drop}
        className={classes}
        style={labelStyle}
      >
        <View width="100%" className="cp-cfg-drp-fld-val">
          {fname}
        </View>

        <View width="16px" height="16px" margin="xs" right="0px" style={{cursor: "pointer"}} onClick={() => { onDrop( { [prop]: null } ) }}>
          <BsX color={iconColor} />
        </View>
      </View>
    )
  }

  return rendered
}

DropField.propTypes = {
  prop: PropTypes.string.isRequired,
  accept: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  field: PropTypes.object,
  label: PropTypes.string
}
