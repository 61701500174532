import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Flex, View } from "@aws-amplify/ui-react";
import { Select } from '../../../../../../Controls'

const colorPalettes = [
  'ididio','accent','category10','category20','category20b','category20c','dark2','paired','pastel1','pastel2',
  'set1','set2','set3','tableau10','tableau20'
]

export const ColorCategorical = ( { className, label, value, onUpdate, ...remaining } ) => {

  let wrapperClasses = classNames( 'cp-ctl', className )

  return (
    <Flex paddingRight="small" paddingBottom="xs" className={wrapperClasses} alignItems="center">
      {
        label && (
          <View className="cp-ctl-lbl" >
            {label}
          </View>
        )
      }

      <Select
        size="small"
        options={colorPalettes}
        onChange={( event ) => { onUpdate( event.target.value ) }}
        placeholder="Select color palette ..."
        {...remaining}
      />
    </Flex>
  )
}

ColorCategorical.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
}
