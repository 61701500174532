import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from "@aws-amplify/ui-react";

import { TextInput } from '../../../../../../Controls'

export const MetadataEditor = ( { dataset, onUpdate } ) => { 
    const name = dataset && dataset.name ? dataset.name : ''
    const description = dataset && dataset.description ? dataset.description : ''
    const source = dataset && dataset.source ? dataset.source : ''

    return (
        <Flex direction="column" padding="large">
            <TextInput
                className="cp-xs-prp"
                label="Name"
                value={name}
                onUpdate={( val ) => { onUpdate( { name: val } ) }}
            />

            <TextInput
                className="cp-xs-prp"
                label="Source"
                value={source}
                onUpdate={( val ) => { onUpdate( { source: val } ) }}
            />
        </Flex>
    )
}

MetadataEditor.propTypes = {
  dataset: PropTypes.object.isRequired
}