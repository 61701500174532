import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CheckboxField, View } from "@aws-amplify/ui-react";

export const Checkbox = ( { className, label, value, onUpdate, ...remaining } ) => {
  const [isChecked, setIsChecked] = useState( !!value )

  let wrapperClasses = classNames( 'cp-ctl', className )

  function updateState() {
    let newValue = !isChecked
    onUpdate( newValue )
    setIsChecked( newValue )
  }

  return (
    <View className={wrapperClasses}>
      <CheckboxField 
        fontSize="small"
        label={label}
        value={isChecked}
        onChange={updateState}
        {...remaining}
      />

    </View>
  )
}

Checkbox.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
}
