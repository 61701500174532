import React from 'react';
import classNames from 'classnames';

require( './Wrapper.scss' );

interface Props {
  children: React.ReactNode;
  center?: boolean;
  style?: React.CSSProperties;
}

export function Wrapper({children, center, style}: Props) {
  return (
    <div
      className={classNames('dnd-wrapper', center && 'dnd-center')}
      style={style}
    >
      {children}
    </div>
  );
}
