import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {Flex, View} from '@aws-amplify/ui-react';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {Item, Container, ContainerProps, SortableItemData} from '../../../dnd';
import {LineTo} from '../../../LineTo';
import {coordinateGetter as partitionContainersCoordinateGetter} from './matchContainersKeyboardCoordinates';

export const colors = ['#90D8E3', '#C5E29F', '#F8EAA1', '#FCCF8C'];

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({...args, wasDragging: true});

export const leftID = "left";
export const rightID = "right";

export const destinations = [leftID, rightID];

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: SortableItemData[];
  style?: React.CSSProperties;
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
      ( items.findIndex( (item) => item.id === over.id)  >= 0 )
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

export type MatchItems = Record<UniqueIdentifier, SortableItemData[]>;
export type Pair = {
  left: SortableItemData;
  right: SortableItemData;
};

export type ContainerLabels = {
  initial: string;
  higher: string;
  lower: string;
};

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: {index: number}): React.CSSProperties;
  items?: MatchItems;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
  onChange?( pairs:Pair[]): void;
  dropLabels: ContainerLabels;
}

export function MatchContainers({
  adjustScale = false,
  cancelDrop,
  columns,
  handle = false,
  items: initialItems,
  containerStyle,
  coordinateGetter = partitionContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  onChange,
  dropLabels = { initial: '', higher: '', lower: ''}
}: Props) {
  const [items, setItems] = useState<MatchItems>(
    () =>
      initialItems ?? {}
  );
  const [pairs, setPairs] = useState<Pair[]>([]);
  const [containers, setContainers] = useState(
    Object.keys(items) as UniqueIdentifier[]
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  ( containerItems.findIndex( (item) => item.id === container.id)  >= 0 )
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{id: overId}];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{id: lastOverId.current}] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<MatchItems | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].findIndex( (item) => item.id === id) >= 0 );
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].findIndex( (item) => item.id === id);

    return index;
  };

  const getBorderItemStyles = (args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  } ): React.CSSProperties => {

    const containerId = findContainer( args.value );
    const pIdx = pairs.findIndex( d => { return d[containerId].id === args.value});

    let style = {};

    if ( pIdx >= 0 ) {
      style = {
        border: `solid 2px ${colors[pIdx]}`,
        backgroundColor: colors[pIdx],
        zIndex: 102
      }
    }

    return style;
  }

  const getWrapperStyles = () => {
    return {
      zIndex: 110
    }
  }

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({active}) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({active, over}) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }
      }}
      onDragEnd={({active, over}) => {
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if ( activeContainer !== overContainer ) {
          let leftItemID = activeContainer === leftID ? active.id : over.id;
          let rightItemID = activeContainer === rightID ? active.id : over.id;

          // First remove any pairs that connect to the new verticies.
          let newPairs = pairs.filter( d => {
            return !( d.left.id === leftItemID || d.right.id === rightItemID );
          } );

          // Then add in the new pair.
          const leftItem = items[findContainer(leftItemID)][getIndex(leftItemID)];
          const rightItem = items[findContainer(rightItemID)][getIndex(rightItemID)];

          const newPair = {
            left: leftItem,
            right: rightItem
          };

          newPairs.push( newPair );

          setPairs( newPairs );
          onChange && onChange( newPairs );
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <Flex alignItems="flex-start" justifyContent="stretch" gap="0px">

      {
          pairs.map( ( d, i ) => {
            return (
              <LineTo
                key={i}
                from={d.left.id as string}
                fromAnchor="right center"
                to={d.right.id as string}
                toAnchor="left center"
                borderColor={colors[i]}
                borderWidth={5}
                zIndex={101}
              />
            )
          })
        }

        <View grow={1} shrink={1} maxWidth="50%">
          <SortableContext
              items={[...containers]}
              strategy={
                vertical
                  ? verticalListSortingStrategy
                  : horizontalListSortingStrategy
              }
            >
              <DroppableContainer
                key={leftID}
                id={leftID}
                label={dropLabels[leftID] ? dropLabels[leftID] : leftID}
                columns={columns}
                items={items[leftID]}
                scrollable={scrollable}
                style={containerStyle}
                unstyled={false}
              >
                <SortableContext items={items[leftID]} strategy={strategy}>
                  {items[leftID].map((value, index) => {
                    return (
                      <SortableItem
                        disabled={isSortingContainer}
                        key={value.id}
                        id={value.id}
                        label={value.label}
                        index={index}
                        handle={handle}
                        style={getBorderItemStyles}
                        wrapperStyle={getWrapperStyles}
                        renderItem={renderItem}
                        containerId={leftID}
                        getIndex={getIndex}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            </SortableContext>
        </View>

        <View grow={1} shrink={1} maxWidth="50%">
          <SortableContext
              items={[...containers]}
              strategy={
                vertical
                  ? verticalListSortingStrategy
                  : horizontalListSortingStrategy
              }
            >
              <DroppableContainer
                key={rightID}
                id={rightID}
                label={dropLabels[rightID] ? dropLabels[rightID] : rightID}
                columns={columns}
                items={items[rightID]}
                scrollable={scrollable}
                style={containerStyle}
                unstyled={false}
              >
                <SortableContext items={items[rightID]} strategy={strategy}>
                  {items[rightID].map((value, index) => {
                    return (
                      <SortableItem
                        disabled={isSortingContainer}
                        key={value.id}
                        id={value.id}
                        label={value.label}
                        index={index}
                        handle={handle}
                        style={getBorderItemStyles}
                        wrapperStyle={getWrapperStyles}
                        renderItem={renderItem}
                        containerId={rightID}
                        getIndex={getIndex}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            </SortableContext>
        </View>

      </Flex>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {
            activeId ? (
              <Item
                value={items[findContainer(activeId)][getIndex(activeId)].label}
                handle={handle}
                style={getItemStyles({
                  containerId: findContainer(activeId) as UniqueIdentifier,
                  overIndex: -1,
                  index: getIndex(activeId),
                  value: activeId,
                  isSorting: true,
                  isDragging: true,
                  isDragOverlay: true,
                })}
                wrapperStyle={wrapperStyle({index: 0})}
                renderItem={renderItem}
                dragOverlay
              />
            ) : null
          }
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  label: string;
  index: number;
  handle: boolean;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  renderItem(): React.ReactElement;
  wrapperStyle({index}: {index: number}): React.CSSProperties;
}

function SortableItem({
  disabled,
  id,
  label,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      className={id as string}
      value={label}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
      index={index}
      wrapperStyle={wrapperStyle({index})}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
    />
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
