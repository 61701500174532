import React, { useContext } from 'react';
import clone from 'clone';
import { View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { CategoricalDataSelector, OrderOptions } from './DataSelector';
import { TextInput } from './TextInput';

export const numBins = 4
const maxOptions = numBins * numBins;

export const ConnectFourCategoryConfig = () => {
  const { config, updateConfig, data } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill({label:'', value:0});
  const optionsConfig = config.optionsConfig ? config.optionsConfig : {};
  const labels = Array.isArray( config.binLabels ) ? config.binLabels : Array(numBins).fill('');
  const format = config.format ? config.format : '';

  function updateOptions( newOptions ) {
    let updateOptions = clone( newOptions );

    // Check to see if the categories have changed, and if so update the binLabels as well.
    if ( newOptions.optionsConfig ) {
      const newCats = Array.isArray( newOptions.optionsConfig.categories ) ? newOptions.optionsConfig.categories : [];
      const curCats = Array.isArray( optionsConfig.categories ) ? optionsConfig.categories : [];

      if ( curCats.length !== newCats.length ) {
        // We need to reset the binLabels.
        updateOptions.binLabels = [];

      } else {
        // The lengths are the same so we need to check a bit further to see 
        // if the same values are contained in each.
        let matches = newCats.reduce( ( count, d ) => {
          if ( curCats.includes( d ) ) {
            count = count + 1;
          }

          return count
        }, 0 );

        if ( matches !== newCats.length ) {
          // The values don't match so update the labels.
          updateOptions.binLabels = [];
        }
      }
      
    }

    // Then push the update.
    updateConfig( updateOptions )
  }

  function updateBinLabel( bin, label ) {
    if ( bin < labels.length ) {
      let newLabels = labels.slice();
      newLabels[bin] = label;

      updateConfig( { binLabels: newLabels } );
    }
  }

  return (
      <View  className="gp-typ" >

        {
          labels.map( ( d, i ) => {
            return (
              <TextInput
                label={`Bin ${i + 1} Label`}
                key={`bin${i}`}
                value={labels[i]}
                placeholder={`Bin ${i + 1}`}
                onChange={( val ) => { updateBinLabel( i, val )}}
                size="small"
              />
            )
          } )
        }

        <TextInput
          label="Value Format"
          key="format"
          value={format}
          placeholder={`Value format`}
          onChange={( val ) => { updateConfig({ format: val })}}
          size="small"
          marginBottom="small"
        />

        <CategoricalDataSelector
          data={data}
          options={options}
          config={optionsConfig}
          onUpdate={updateOptions}
          valueFormat={config.format}
          numBins={numBins}
          labels={labels}
        />

        <OrderOptions
          options={options}
          config={config}
          onUpdate={( val ) => {updateConfig({ options: val })}}
        />

      </View>
  )
}
