// getNodes traverses the given object and returns a array of matching
// elements in the following structure:
//
//  [
//    {
//      path: 'full path to the element',
//      value: 'value of the element',
//      attr: 'attribute name',
//      parent: 'reference to parent of the element'
//    }
//  ]
//
// The path specifications follow the JSON Path standard.  Examples given the
// following JSON object are:
//
// {
//  "store": {
//    "book": [
//      {
//        "category": "reference",
//        "author": "Nigel Rees",
//        "title": "Sayings of the Century",
//        "price": 8.95
//      }, {
//        "category": "fiction",
//        "author": "Evelyn Waugh",
//        "title": "Sword of Honour",
//        "price": 12.99
//      }, {
//        "category": "fiction",
//        "author": "Herman Melville",
//        "title": "Moby Dick",
//        "isbn": "0-553-21311-3",
//        "price": 8.99
//      }, {
//         "category": "fiction",
//        "author": "J. R. R. Tolkien",
//        "title": "The Lord of the Rings",
//        "isbn": "0-395-19395-8",
//        "price": 22.99
//      }
//    ],
//    "bicycle": {
//      "color": "red",
//      "price": 19.95
//    }
//  }
// }
//
// store.book[*].author	-- The authors of all books in the store
// store.*	All things in store, which are some books and a red bicycle
// Note: * not required to be at leaf
// ..author	-- All authors <--- does not work presently
// store..price	The price of everything in the store
// ..book[2]	The third book via array subscript
// ..book[-1:]	The last book in order
// ..book[0,1]	The first two books via subscript union
// ..book[:2]	The first two books via subscript array slice
// ..*	All members of JSON structure
// $foo  The value 'foo', returns the specified value
//
//////////////////////////////////////////////////////////////////////////////
///////////////////////////////RESTRICTIONS///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// No support for the .. expansion

export function getNodes( obj, path, exclude = undefined ) {
  let pathNodes = []

  if ( obj && path ) {
    // Make sure the specified path(s) are an array, if not turn it into one
    let paths = Array.isArray( path ) ? path : [path]

    pathNodes = paths.reduce( ( nodes, p, i ) => {
      if ( p && p.length > 0 && p[0] === '$' ) {
        let node = {
          value: p.slice( 1 ),
          attr: p.slice( 1 ),
          parent: obj,
          path: ''
        }

        nodes.push( node )
      }      else {
        nodes = nodes.concat( getElements( obj, p, '', exclude ) )
      }

      return nodes
    }, [] )
  }

  return pathNodes
}


export function getNodeValue( o, p ) {
  let val

  if ( o && p ) {
    let nodes = getNodes( o, p )

    if ( nodes.length > 0 ) {
      val = nodes[0].value
    }
  }

  return val
}

// getElements will work from the root to the leaf expanding the
function getElements( obj, path, prefix = '', exclude = undefined ) {
  let nodes = []
  let parts = typeof path === 'string' ? path.split( '\.' ) : []
  let element = parts[0]

  if ( parts.length === 1 ) {
    // We are at the leaf, check to see if it's an array
    if ( element.indexOf( '[' ) >= 0 ) {
      nodes = nodes.concat( processArray( obj, element, prefix, exclude ) )
    } else if ( element.indexOf( '*' ) >= 0 && obj ) {

      // and we have a wildcard, iterate through the attributes to find any
      // that match
      if ( element === '*' ) {

        // We are going to process all of the fields so don't bother with
        // the matching, just return all of them
        Object.keys( obj ).forEach( attr => {
          if ( !exclude || ( exclude && !exclude.has( attr ) ) ) {
            nodes.push( createNode( obj, attr, prefix ) )
          }
        } )
      } else {
        // We need to prefix the wildcard with a . which can't be in the
        // specification since it is a path segment separator.  Difference
        // between regular expressions and globbing.
        element = `^${element.replace( /\*/g, '.*' )}$`

        Object.keys( obj ).forEach( attr => {

          // We need to check whether the attribute matches the src
          // specification.
          let matches = attr.match( element )

          if ( matches && matches.length > 0 && matches[0] !== '' &&
            ( !exclude || ( exclude && !exclude.has( attr ) ) ) ) {
            nodes.push( createNode( obj, attr, prefix ) )
          }
        } )
      }
    }    else if ( element.length === 0 || typeof obj !== 'object' ) {

      // We are at a terminal in the path so add it
      nodes.push( createNode( obj, element, prefix ) )
    }    else if ( obj && obj.hasOwnProperty( element ) ) {

      // Found the specified element so add a node
      nodes.push( createNode( obj, element, prefix ) )
    }
  }  else if ( parts.length > 1 ) {
    if ( element === '$' ) {
      nodes = nodes.concat( getElements( obj, path, '', exclude ) )
    } else if ( element.length === 0 ) {

      // We need to expand everything from here on down to find the
      // remainder of the matching segment
      // FIXME
    } else if ( element.indexOf( '[' ) >= 0 ) {

      // An array has been specified so we will need to expand the appropriate
      // pieces of the array
      nodes = nodes.concat( processArray( obj, path, prefix, exclude ) )
    } else if ( element.indexOf( '*' ) >= 0 ) {

      // An wildcard has been specified so we will need to expand the to capture
      // the appropriate parts of the object

      if ( element === '*' && obj ) {

        // We need to process all of the attributes so don't bother with
        // the match, just return all of them
        Object.keys( obj ).forEach( attr => {
          nodes = nodes.concat( getElements( obj[attr],
            parts.slice( 1 ).join( '.' ),
            createPrefix( attr, prefix ),
            exclude ) )
        } )
      } else {
        // We need to prefix the wildcard with a . which can't be in the
        // specification since it is a path segment separator.  Difference
        // between regular expressions and globbing.
        element = `^${element.replace( /\*/g, '.*' )}$`

        Object.keys( obj ).forEach( attr => {
          // We need to check whether the attribute matches the src
          // specification.
          let matches = attr.match( element )

          if ( matches && matches.length > 0 && matches[0] !== '' ) {
            nodes = nodes.concat( getElements( obj[attr],
              parts.slice( 1 ).join( '.' ),
              createPrefix( attr, prefix ),
              exclude ) )
          }
        } )
      }
    } else if ( obj && obj.hasOwnProperty( element ) ) {
      // A specific attribute has been specified so walk down the path
      // if it exists
      nodes = nodes.concat( getElements( obj[element],
        parts.slice( 1 ).join( '.' ),
        createPrefix( element, prefix ),
        exclude ) )
    }
  }

  return nodes
}

function processArray( obj, element, prefix, exclude ) {
  let nodes = []
  let parts = element.split( '\.' )
  let attr = element.slice( 0, element.indexOf( '[' ) )

  if ( attr.length > 0 && obj && obj.hasOwnProperty( attr ) ||
       attr.length === 0 && Array.isArray( obj ) ) {

    let arrayStart = element.indexOf( '[' ) + 1

    let arrayEnd = element.indexOf( ']' )

    if ( arrayEnd > arrayStart ) {
      let bounds = element.slice( arrayStart, arrayEnd )
      let sliceParams = bounds.split( ':' )
      let begin = parseInt( sliceParams[0] )
      let end

      // consider negative indexing
      if ( begin < 0 ) {
        begin = obj[attr].length + begin
      }

      if ( sliceParams.length > 1 ) {
        end = parseInt( sliceParams[1] )
      } else if ( !isNaN( begin ) ) {
        // Only return the specified element.
        end = begin === -1 ? undefined : begin + 1
      }

      // Correct improper transformations being generous on conversion
      begin = isNaN( begin ) ? 0 : begin
      end = isNaN( end ) ? undefined : end

      let arrayObj

      if ( obj[attr] ) {
        arrayObj = obj[attr]
      } else if ( Array.isArray( obj ) && arrayStart === 1 ) {
        arrayObj = obj
      }

      if ( arrayObj ) {
        // We need to check every item within the array
        nodes = arrayObj.slice( begin, end ).reduce( ( n, e, i ) => {
          let newPath = parts.slice( 1 ).join( '.' )
          let newPrefix = `${createPrefix( attr, prefix )}[${begin+i}]`

          if ( newPath.length === 0 && arrayEnd === ( element.length - 1 ) ) {

            // We are at a terminal in the path so just create the nodes.
            n.push( createNode( arrayObj, begin+i, createPrefix( attr, prefix ) ) )
          } else {
            if ( arrayEnd < element.length && element[arrayEnd+1] === '[' ) {

              // We have a multi-dimensional array so make newPath pick up
              // where the previous array ended.
              newPath = element.slice( arrayEnd+1 )
            }

            n = n.concat( getElements( arrayObj[begin+i], newPath, newPrefix, exclude ) )
          }

          return n
        }, [] )
      }
    }
  }

  return nodes
}

function createNode( obj, element, prefix ) {
  let node = {
    value: obj[element],
    attr: element,
    parent: obj
  }

  if ( Array.isArray( obj ) ) {
    node.path = prefix && prefix.length > 0 ? `${prefix}[${element}]` : element
  } else {
    node.path = createPrefix( element, prefix )
  }

  return node
}

function createPrefix( element, prefix ) {
  let newPrefix = ''

  if ( prefix && prefix.length > 0 ) {
    if ( element && element.length > 0 && element[0] !== '[' ) {
      newPrefix = `${prefix}.${element}`
    } else {
      newPrefix = `${prefix}${element}`
    }
  } else {
    newPrefix = element
  }
  return newPrefix
}

function createArrayPrefix( element, prefix ) {
  return prefix && prefix.length > 0 ? `${prefix}[${element}]` : element
}
