import { RequireAuth } from './utils';
import { Layout, Login, SinglePageLayout } from './components';
import {
  AboutPage,
  ArchivePage,
  GameEditorPage,
  GameSelect,
  LandingPage,
  PrivacyPage
} from './pages';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="archive/:gameId?" element={<ArchivePage />} />
          <Route
            path="gameselect"
            element={
              <RequireAuth>
                <GameSelect />
              </RequireAuth>
            }
          />

          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<SinglePageLayout />}>
          
          <Route
            path="/editor/:gameId?"
            element={
              <RequireAuth>
                <GameEditorPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}