import { Button } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { listPublishedGames } from '../../graphql/queries';
import { deletePublishedGames, createPublishedGames, updatePublishedGames } from '../../graphql/mutations';

const publishedType = 'PUBLISHED';

export const UpdatePublished = ( { games } ) => {

	async function updateDb( toPublish ) {

		// Get the currently published list.
		const client = generateClient();

		client.graphql({ query: listPublishedGames })
			.then( response => {

				let pubList = [];
				if ( response.data && response.data.listPublishedGames && Array.isArray( response.data.listPublishedGames.items ) ) {
					pubList = response.data.listPublishedGames.items;
				}

				// Walk through the lists to reconsile any differences.
				console.log( 'PUBLISHED:', pubList)
				console.log( 'TO PUBLISH:', toPublish)

				// First walk through the toPublish to find any items that haven't yet been published.
				let toPubMap = toPublish.reduce( ( obj, d ) => {
					obj[d.published] = d;
					return obj;
				}, {} );

				// Look through each of the items in the published list to determine what needs to be done.
				pubList.forEach( d => {
					if ( toPubMap[d.published] ) {
						if ( d.gameId !== toPubMap[d.published].gameId || 
							d.title !== toPubMap[d.published].title || 
							d.category !== toPubMap[d.published].category ) {
							
							updateRecord( toPubMap[d.published] )

						}
					} else {

						// The record is in the published list, but not in the to publish list so remove it 
						// from the published list.
						deleteRecord( d );
					}
				} );

				// Now look through the to publish to find any games that haven't yet been published.
				let pubMap = pubList.reduce( ( obj, d ) => {
					obj[d.published] = d;
					return obj;
				}, {} );

				toPublish.forEach( d => {
					if ( !pubMap[d.published] ) {
						// The game hasn't yet been published so add it to the table.
						createRecord( d );
					}
				} );


			} )

	}

	function onUpdate() {
		if ( Array.isArray( games ) ) {
			let today = new Date().toJSON().slice( 0, 10 );

			console.log( 'TODAY:', today )
			let publishedGames = games.reduce( ( ary, d ) => {
				if ( d.published && d.published <= today ) {
					ary.push( d )
				}
				return ary;
			}, [] );

			updateDb( publishedGames );
		}
	}

	return (
		<Button onClick={onUpdate}>
			Update Published
		</Button>
	)
}

export const DeletePublished = ( item ) => {
	if ( item.published ) {
		deleteRecord( item );
	}
}

async function createRecord( game ) {
	const dbRec = createDbRec( game )

	// Update the DynamoDB record.
	const client = generateClient();

	await client.graphql({
	  query: createPublishedGames,
	  variables: { input: dbRec },
	} );
}

async function updateRecord( game ) {
	const dbRec = createDbRec( game )

	// Update the DynamoDB record.
	const client = generateClient();

	await client.graphql({
	  query: updatePublishedGames,
	  variables: { input: dbRec },
	} );
}

async function deleteRecord( item ) {
	const dbKeys = {
		type: publishedType,
		published: item.published
	};

	const client = generateClient();
	await client.graphql({
		query: deletePublishedGames,
		variables: { input: dbKeys },
	});
}

function createDbRec( game ) {
	return {
		type: publishedType,
		published: game.published,
		gameId: game.gameId,
		title: game.title,
		category: game.title
	}
}