import React, { useContext, useEffect, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import clone from 'clone'
import isEqual from 'lodash/isEqual'

import { TextInput } from '../../../../../../Controls'
import { VegaEditorContext } from '../../../../VegaEditorContext'

import { clrDefaultMarkFill } from '../defaultValues'
import { ColorPicker } from './ColorPicker'
import { ConfigGroup } from './ConfigGroup'
import { FieldConfig } from './FieldConfig'

export const StateHighlightConfig = ( { validDataset } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  let highlights = config.highlightStates ? config.highlightStates : {};
  let manualHighlights = Array.isArray( highlights.manual ) ? highlights.manual : [];

  let stateList = Array.isArray( config.highlightStates ) ? config.highlightStates.join(',') : ''

  function updateStateList( update ) {
    let newList = update.split( ',' ).map( d => {
      return d.trim();
    } )

    updateConfig( { highlightStates: newList } )
  }

  return (
    <ConfigGroup label="Highlight States">

      <FieldConfig
        prop="hlf"
        label="States"
        accept={['C']}
        validDataset={validDataset}
      />

      <TextInput
        className="cp-xs-prp"
        label="States"
        value={stateList}
        onUpdate={( val ) => { updateStateList( val ) }}
      />

        {/*
          staticTypes.map( ( d, i ) => {
            return (
                <ColorPicker
                  key={`${d.val}-${i}`}
                  label={d.label}
                  value={colors[d.val] && colors[d.val].fill ? colors[d.val].fill : clrDefaultMarkFill}
                  onUpdate={( clr ) => { setColor( { [d.val]: { fill: clr, stroke: clr } } ) }}
                />
              )
          } )
        */}

    </ConfigGroup>
  )
}

StateHighlightConfig.propTypes = {
  configProp: PropTypes.string,
  label: PropTypes.string,
  staticTypes: PropTypes.array,
  isDynamic: PropTypes.bool
}
