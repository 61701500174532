import { useCallback, useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Divider, Flex, ScrollView, View } from "@aws-amplify/ui-react"
import GameTheme from "./GameTheme";

// import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import GameEditorToolbarPlugin from "./plugins/GameEditorToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";

import YouTubePlugin from "./plugins/YouTubePlugin";
import { YouTubeNode } from "./nodes/YouTubeNode";

import ChartPlugin from "./plugins/ChartPlugin";
import { ChartNode } from "./nodes/ChartNode";

// import {$getRoot, $getSelection} from 'lexical';
import { $getRoot } from 'lexical';

import { GameContext } from './GameContext'

import "./GameEditor.css"

const debounceTime = 5000    // Only fire the onChange() callback once every five seconds.

const Placeholder = () => {
  return <div className="editor-placeholder">Tell your story here...</div>;
}

export const GameEditor = ( { game, readOnly, onChange } ) => {
  // const [editor] = useLexicalComposerContext()
  // const [edState, edUpdate] = useRef(
  //   ( state, update ) => {
  //     const newState = {...state, ...update }
  //     return newState
  //   }, {
  //     focused: editor.getRootElement() === document.activeElement
  //   }
  // )

  // useLayoutEffect(() => {
  //   edUpdate({ focus: editor.getRootElement() === document.activeElement });
  //   return mergeRegister(
  //     editor.registerCommand(FOCUS_COMMAND, () => { console.log( 'FOCUS COMMAND:', edState ) }),
  //     editor.registerCommand(BLUR_COMMAND, () => { console.log( 'BLUR COMMAND:', edState ) }),
  //   );
  // }, [editor]);

  const editorConfig = {
    // Is this a readonly session or an editable session
    editable: readOnly ? false : true,
  
    // The editor theme
    theme: GameTheme,
  
    // Handling of errors during update
    onError(error) {
      console.error( error )
      // throw error;
    },

    // Set the game ID so we can retrieve it later.
    gameId: game.gameId,
  
    // Custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ChartNode,
      YouTubeNode
    ]
  };

  // If a body exist, use it to set the inital state of the editor.
  if ( game.body ) {
    editorConfig.editorState = game.body
    // editorConfig.editorState = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'
  }

  // console.log( 'EDITOR CONFIG:', editorConfig )

  // Setup debounced on change to capture the editor updates.
  function useDebouncedLexicalOnChange( getEditorState, callback, delay ) {
    const lastPayloadRef = useRef(null);
    const callbackRef = useRef(callback);

    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);

    const callCallbackWithLastPayload = useCallback(() => {
      if (lastPayloadRef.current) {
        callbackRef.current?.(lastPayloadRef.current);
      }
    }, []);

    const call = useDebouncedCallback( callCallbackWithLastPayload, delay );

    const onChange = useCallback( ( editorState ) => {
        editorState.read( () => {
          lastPayloadRef.current = getEditorState(editorState);
          call();
        });
      },
      [call, getEditorState]
    );

    return onChange;
  }

  const getEditorState = ( editorState ) => ({
    text: $getRoot().getTextContent( false ),
    stateJson: JSON.stringify( editorState )
  });

  const debouncedOnChange = useCallback((value) => {
    // notify of the change.
    onChange && onChange( { body: value.stateJson } )
  }, []);

  const onEditorChange = useDebouncedLexicalOnChange(
    getEditorState,
    debouncedOnChange,
    debounceTime 
  );

  const gameContext = {
    game: game,
    onGameChange: onChange
  }

  let editorComponents = (
    <>
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        placeholder={<Placeholder />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={onEditorChange} />
      <HistoryPlugin />
      <AutoFocusPlugin />
      <CodeHighlightPlugin />
      <ListPlugin />
      <LinkPlugin />
      <AutoLinkPlugin />
      <ListMaxIndentLevelPlugin maxDepth={7} />
      <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      <ChartPlugin />
      <YouTubePlugin />

      {
        !readOnly && (
          <TreeViewPlugin />
        )
      }
    </>
  )

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Flex className="editor-container" direction="column" grow={1} shrink={1} gap="0px">

        {
          !readOnly && (
            <>
              <GameEditorToolbarPlugin />
              <Divider />
            </>
          )
        }

        <GameContext.Provider value={gameContext}>
          {
            readOnly ? (
              <View className="editor-inner" grow={1} shrink={1}>
                {editorComponents}
              </View>
            ) : (
              <ScrollView className="editor-inner" grow={1} shrink={1} height="100px">
                {editorComponents}
              </ScrollView>
            )
          }
        </GameContext.Provider>
      </Flex>
    </LexicalComposer>
  );
}