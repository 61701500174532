import React, { useContext } from 'react';
import { SelectField, View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { GroupDataSelector, OrderOptions } from './DataSelector';
import { TextInput } from './TextInput';

export const numBins = 4
const maxOptions = numBins * numBins;

export const ConnectFourConfig = () => {
  const { config, updateConfig, data } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill({label:'', value:0});
  const optionsConfig = config.optionsConfig ? config.optionsConfig : {};
  const labels = Array.isArray( config.binLabels ) ? config.binLabels : Array(numBins).fill('');
  const format = config.format ? config.format : '';

  function updateOptions( newOptions ) {
    updateConfig( newOptions )
  }

  function updateBinLabel( bin, label ) {
    if ( bin < labels.length ) {
      let newLabels = labels.slice();
      newLabels[bin] = label;

      updateConfig( { binLabels: newLabels } );
    }
  }

  return (
      <View  className="gp-typ" >

        {
          labels.map( ( d, i ) => {
            return (
              <TextInput
                label={`Bin ${i + 1} Label`}
                key={`bin${i}`}
                value={labels[i]}
                placeholder={`Bin ${i + 1}`}
                onChange={( val ) => { updateBinLabel( i, val )}}
                size="small"
              />
            )
          } )
        }

        <TextInput
          label="Value Format"
          key="format"
          value={format}
          placeholder={`Value format`}
          onChange={( val ) => { updateConfig({ format: val })}}
          size="small"
          marginBottom="small"
        />

        <GroupDataSelector
          data={data}
          options={options}
          config={optionsConfig}
          onUpdate={updateOptions}
          valueFormat={config.format}
          numBins={numBins}
          labels={labels}
        />

        <OrderOptions
          options={options}
          config={config}
          onUpdate={( val ) => {updateConfig({ options: val })}}
        />

      </View>
  )
}
