import React, { useContext } from 'react'

import { VegaEditorContext } from '../../../VegaEditorContext'
import {
  ColorConfig,
  SizeConfig,
  GroupFieldConfig,
  InteractiveFilter,
  NumericAxisConfig,
  TooltipConfig,
  useValidateFields
} from './components'
import { getDataId } from './configUtils'

export const defaultArea = {
  x: {
    domain: {
      show: true
    },
    grid: {
      show: true
    },
    ticks: {
      show: true
    }
  },
  y: {
    domain: {
      show: true
    },
    grid: {
      show: true
    },
    ticks: {
      show: true
    }
  }
}

// Assign labels for each of the items we can configure with color.
const colorTypes = [
  {
    val: 'mark',
    label: 'Areas'
  },
  {
    val: 'hover',
    label: 'Hover'
  }
]

const fieldProps = ['x', 'y', 'color', 'tt', 'iflt']

export const AreaConfig = () => {
  const { config } = useContext( VegaEditorContext )

  const curDataId = getDataId( config, fieldProps )

  // console.log( 'LINE CONFIG:', config )

  // Remove any fields that aren't valid based on referencing an invalid
  // dataset.
  useValidateFields( fieldProps )

  return (
    <div  className="cp-ctls" >

      <NumericAxisConfig
        prop="x"
        label="X Axis"
        defaultProps={defaultArea}
        validDataset={curDataId}
      />

      <NumericAxisConfig
        prop="y"
        label="Y Axis"
        defaultProps={defaultArea}
        validDataset={curDataId}
      />

      <GroupFieldConfig
        prop="g"
        label="Group"
        defaultProps={defaultArea}
        validDataset={curDataId}
      />

      <ColorConfig
        validDataset={curDataId}
        staticTypes={config && config.g && config.g.field ? [colorTypes[1]] : colorTypes }
      />

      <TooltipConfig prop="tt" />

      <InteractiveFilter prop="iflt" />
    </div>
  )
}
//
// ScatterConfig.propTypes = {
//   dataSets: PropTypes.object.isRequired,
//   config: PropTypes.object.isRequired,
//   onUpdate: PropTypes.func.isRequired
// }
