import React, { useContext } from 'react'
import { View } from "@aws-amplify/ui-react";

import { TextInput } from '../../../../Controls'
import { VegaEditorContext } from '../../VegaEditorContext'

export const ChartTitle = () => {
  const { config, updateConfig } = useContext( VegaEditorContext )
  let title = config && config.title ? config.title : ''

  return (
    <View  className="cp-title" >

      <TextInput
        className="cp-xs-prp"
        label="Title"
        value={title}
        onUpdate={( val ) => { updateConfig( { title: val } ) }}
      />
    </View>
  )
}
