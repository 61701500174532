import { getNodeValue } from './paths'

export function tsToPoints( obj, tsX, tsY, copy ) {
  let points = []

  let x = getNodeValue( obj, tsX )
  let y = getNodeValue( obj, tsY )

  if ( Array.isArray( x ) && Array.isArray( y ) && x.length === y.length ) {
    points = x.map( ( d, i ) => {
      let newPoint = {
        x: d,
        y: y[i]
      }

      if ( copy ) {
        if ( Array.isArray( copy ) ) {
          
          // Copy each of the fields that were requested.
          newPoint = copy.reduce( ( p, e ) => {
            p[e] = getNodeValue( obj, e )
            return p
          }, newPoint )

        } else {
          newPoint[copy] = getNodeValue( obj, copy )
        }
      }

      return newPoint
    } )
  }

  return points
}
