

export function getDataset( spec, dataId ) {
  let ds

  if ( spec && dataId ) {
    if ( !Array.isArray( spec.data ) ) {
      spec.data = []
    }

    ds = spec.data.find( ( d ) => {
      return d.name === dataId
    } )

    // The dataset doesn't exist so add it.
    if ( !ds ) {
      spec.data.push( {
        name: dataId,
        transform: []
      } )

      // Now get a reference to the item we just added.
      ds = spec.data[spec.data.length - 1]
    }
  }

  return ds
}


export function getConfigField( config ) {
  let field

  if ( config ) {
    if ( config.datasetId && config.loc ) {
      field = config
    } else if ( config.field && config.field.datasetId && config.field.loc ) {
      field = config.field
    }
  }

  return field
}

export function makeDisplayable( str ) {
  let dispStr = str;

  if ( dispStr ) {
    dispStr = dispStr.replaceAll( '_', ' ' );
  }

  return dispStr;
}
