import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export const getCurrentUserInfo = async () => {
  let results = {}

  try {
    const {
      username,
      userId: id
    } = await getCurrentUser();

    const attributes = await fetchUserAttributes();

    results = {
      id,
      username,
      attributes
    };
  } catch( error ) {
    // console.log( 'GCU ERROR:', error )
  }

  return results;
}