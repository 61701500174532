import React from 'react'
import PropTypes from 'prop-types'
import { View } from "@aws-amplify/ui-react";

import { DataEditorField } from './DataEditorField'

export const DataEditorFieldList = ( { fields, selected, onSelect } ) => { 

  return (
    <View className="dp-flds-lst">
      <View>
        Fields:
      </View>
      {
        fields.map( ( f, i ) => {
            return (<DataEditorField key={i} field={f} selected={i === selected} onSelect={() => { onSelect && onSelect( i )}}/>)
        } )
      }
    </View>
  )
}

DataEditorFieldList.propTypes = {
  fields: PropTypes.array.isRequired
}