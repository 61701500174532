import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Flex, View } from "@aws-amplify/ui-react";
import { BsCheckLg, BsX } from "react-icons/bs";

export const DataSetItem = ( { dataset, onUpdate, onDelete, selected } ) => {

  let sources = dataset.source ? Array.isArray( dataset.source ) ? dataset.source : [dataset.source] : [];

  function onRemove( event ) {
    // Prevent the event from propogating
    event.stopPropagation();

    onDelete && onDelete( dataset );
  }


  return (
    <View>
      <Flex
        padding="small" 
        marginBottom="xs"
        className="dp-sel-item"
        style={{cursor: "pointer"}}
        onClick={() => { onUpdate && onUpdate( dataset ) }}
      >
        <View marginTop="auto" marginBottom="auto" marginRight="xs" className="dp-sel-item-chk">
          {
            selected ? (
              <BsCheckLg size={16} />
            ) : (
              <View width={16}></View>
            )
          }
        </View>

        <View >
          <Flex direction="row">
            <View fontSize="large" fontWeight="bold">
              {dataset.name}
            </View>

            <View marginLeft="auto" onClick={onRemove}>
              <BsX color="#f47e39" size="20px"/>
            </View>
          </Flex>

          <View>
            {dataset.description}
          </View>

          <View>
            {
              sources.map( ( d, i ) => {
                return (
                  <View key={i}>
                    {d}
                  </View>
                )
              })
            }
          </View>
        </View>

      </Flex>
      <Divider />
    </View>
  )
}

DataSetItem.propTypes = {
  onUpdate: PropTypes.func.isRequired
}

