import { useState } from 'react'
import Modal from "react-modal";
import { Button, Flex, View } from "@aws-amplify/ui-react";

import { GameConfig } from '../../components';

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "90%",
    height: "90%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden"
  },
  overlay: {
      background: "#4c4d4e",
      zIndex: 100
  }
};


export const GameSelector = ( { game, onChange } ) => {
  const gameSpec = game.game ? game.game : {};
  const [editing, setEditing] = useState( false );

  function closeEditor() {
    setEditing( false );
  }

  function saveConfig( gc ) {

    if ( gc ) {
      let newConfig = { game: gc };
      onChange && onChange( newConfig );
    }

    closeEditor();
  }

  return (
    <Flex direction="column" alignItems="center"  gap="0px">

      <Button
        onClick={()=> setEditing( true )}
        size="default"
      >
        Configure Game
      </Button>

      {
        !gameSpec.type ? (
          <View color="red" fontSize="small">
            Game not configured.
          </View>

        ) : null
      }

      <Modal
          isOpen={editing}
          onRequestClose={closeEditor}
          style={customModalStyles}
          contentLabel="Game Editor"
      >
          <GameConfig 
            game={gameSpec}
            datasets={game.datasets}
            onClose={closeEditor}
            onSave={saveConfig}
          />

      </Modal>
    </Flex>
  )
}