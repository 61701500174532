import React, { useContext } from 'react'
import { TextAreaField, View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext'

export const GameDescription = () => {
  const { config, updateConfig } = useContext( GameConfigContext )
  let description = config && config.description ? config.description : ''

  // function onInput( event ) {
  //   const keyCode = event.keyCode || event.which

  //   if ( keyCode === 13 ) {

  //     // Update the name since a return was encountered.
  //     onChange( gameTitle )

  //     // Don't process if the pressed key was 'enter'.
  //     event.preventDefault && event.preventDefault()
  //   }
  // }

  function updateDescription( event ) {
    updateConfig && updateConfig({ description: event.currentTarget.value })
  }

  return (
    <View marginTop="large" className="gp-typ" >
      <View className="gp-typ-label" >
        Description
      </View>

      <TextAreaField
        autoComplete="off"
        direction="column"
        hasError={false}
        isDisabled={false}
        isReadOnly={false}
        isRequired={false}
        labelHidden={false} 
        placeholder="Enter game description..."
        rows="3"
        size="small"
        wrap="nowrap"
        rows={10}
        onChange={updateDescription}

        defaultValue={description}
      />

    </View>
  )
}
