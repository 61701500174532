import React from 'react'
import PropTypes from 'prop-types'
import { Flex, View } from "@aws-amplify/ui-react";

export const DataEditorMetadata = ( { dataset, onSelect } ) => { 

  return (
    <Flex direction="column" onClick={() => { onSelect && onSelect( -1 )}} >
      <Flex>
        <View>
          Name:
        </View>

        <View>
          {dataset.name}
        </View>
      </Flex>

      <Flex direction="column" gap="0px">
        <View>
          Description:
        </View>

        <View>
          {dataset.description}
        </View>
      </Flex>

      <Flex>
        <View>
          Source:
        </View>

        <View>
          {dataset.source}
        </View>
      </Flex>

    </Flex>
  )
}

DataEditorMetadata.propTypes = {
  dataset: PropTypes.object.isRequired
}