import { Button, Flex, Icon, Image, Link, View } from "@aws-amplify/ui-react";

export const GameItem = ( { game, onDelete } ) => {
	const title = game.title ? game.title : 'Untitled game'
	const category = game.category ? game.category : 'Unset Category';

	return (
		<Link href={`/editor/${game.gameId}`}>
			<Flex margin="20px" alignItems="center">
				<View>
					{
						game.thumbnail ? (
							<Image
								src={game.thumbnail}
								width="50px"
								alt=""
							/>
						) : (
							<Icon
								pathData="M7,11v8c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-8l-2,2L7,11z M0,19 c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1v-8l-6,3V19z M17,7l-3,3v9c0,0.55,0.45,1,1,1h4c0.55,0,1-0.45,1-1V8.74 C19.74,8.89,19.42,9,19,9C17.08,9,17,7,17,7z M19,1h-4c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1h1.59L10.8,8.78L7.45,7.11l0,0.01	C7.31,7.05,7.16,7,7,7S6.69,7.05,6.56,7.11l0-0.01l-6,3l0,0.01C0.23,10.28,0,10.61,0,11c0,0.55,0.45,1,1,1 c0.16,0,0.31-0.05,0.44-0.11l0,0.01L7,9.12l3.55,1.78l0-0.01C10.69,10.95,10.84,11,11,11c0.28,0,0.53-0.11,0.71-0.29L18,4.41V6 c0,0.55,0.45,1,1,1s1-0.45,1-1V2C20,1.45,19.55,1,19,1z"
								width="50px"
								height="50px"
								margin="0 auto"
								color="background.disabled"
							/>
						)
					}
				</View>

				<View grow={1} shrink={1} marginRight="large" >
						{title}
				</View>

				<View marginRight="large">
						{category}
				</View>

				<View marginRight="large">
						{game.author}
				</View>

				<View marginRight="large">
					{game.published}
				</View>

				<Button
					size="small"
					onClick={( event ) => {
						event.preventDefault()
						onDelete && onDelete( game )
					}}
					disabled={!onDelete}
				>
						Delete
				</Button>
			</Flex>
		</Link>
	)
}