import React, { useContext } from 'react';
import { Flex, View, Text } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { TextInput } from './TextInput';

const maxOptions = 4;

export const PartitionConfig = () => {
  const { config, updateConfig } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill({label:'', value:0});
  const threshold = config.threshold ? config.threshold : '';
  const itemLabel = config.itemLabel ? config.itemLabel : '';
  const higherLabel = config.higherLabel ? config.higherLabel : '';
  const lowerLabel = config.lowerLabel ? config.lowerLabel : '';

  function updateOption( idx, val ) {
    let newOptions = options.slice();
    newOptions[idx] = val;
    updateConfig( { options: newOptions } )
  }

  function updateThreshold( value ) {
    let threshold = Number( value );
    updateConfig( { threshold })
  }

  return (
      <View  className="gp-typ" marginTop="large" >

        
        <TextInput
          label="Threshold"
          key="threshold"
          value={threshold}
          placeholder={`Enter threshold value...`}
          onChange={updateThreshold}
          marginBottom="large"
        />

        <TextInput
          label="Items"
          key="items"
          value={itemLabel}
          placeholder={`What is being partitioned...`}
          onChange={( val ) => { updateConfig({ itemLabel: val })}}
          marginBottom="large"
        />

        <TextInput
          label="Higher Label"
          key="higher"
          value={higherLabel}
          placeholder={`Label for higher container...`}
          onChange={( val ) => { updateConfig({ higherLabel: val })}}
          marginBottom="large"
        />

        <TextInput
          label="Lower Label"
          key="lower"
          value={lowerLabel}
          placeholder={`Label for lower container...`}
          onChange={( val ) => { updateConfig({ lowerLabel: val })}}
          marginBottom="large"
        />

        {
          options.map( ( d, i ) => {
            return (
              <Flex key={`p${i}`}>
                <TextInput
                  key={`l${i}`}
                  value={d.label}
                  placeholder={`Enter label ${i+1}`}
                  onChange={(val)=>{updateOption( i, {...d, label: val} )}}
                />
                <TextInput
                  key={`v${i}`}
                  value={d.value}
                  placeholder={`Enter value ${i+1}`}
                  onChange={(val)=>{updateOption( i, {...d, value: Number( val )} )}}
                />
              </Flex>
            )
          } )

        }

      </View>
  )
}
