import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { VegaEditorContext } from '../../../VegaEditorContext'
import {
  CategoricalAxisConfig,
  ColorConfig,
  InteractiveFilter,
  NumericAxisConfig,
  WedgesConfig,
  useValidateFields
} from './components'

import { getDataId } from './configUtils'
import {
  clrDefaultMarkFill,
  clrDefaultMarkStroke,
  clrDefaultHoverFill,
  clrDefaultHoverStroke,
} from './defaultValues'

export const defaultDonut = {
  w: {},
  v: {},
  tt: {
    show: true,
    format: ',.0f'
  },
  color: {
    hover: {
      stroke: clrDefaultHoverStroke,
      fill: clrDefaultHoverFill
    }
  }
}

// Assign labels for each of the items we can configure with color.
const colorTypes = [
  {
    val: 'hover',
    label: 'Hover'
  }
]

const fieldProps = ['w', 'v', 'iflt']

export const DonutConfig = () => {
  const { config, updateConfig } = useContext( VegaEditorContext )
  const curDataId = getDataId( config, fieldProps )

  useValidateFields( fieldProps )

  return (
    <div  className="cp-ctls" >
      <WedgesConfig
        prop="w"
        label="Wedges"
        defaultProps={defaultDonut}
        validDataset={curDataId}
      />

      {/*
        // Use this to restrict numeric fields to those that seem appropriate
        // for aggregation.
        <NumericAxisConfig
          prop="v"
          label="Values"
          defaultProps={defaultBar}
          restrictions={['canAggregate']}
          validDataset={curDataId}
        />
      */}

      <NumericAxisConfig
        prop="v"
        label="Values"
        defaultProps={defaultDonut}
        validDataset={curDataId}
        noConfig
      />

      <ColorConfig
        validDataset={curDataId}
        staticTypes={colorTypes}
      />

      <InteractiveFilter prop="iflt" />

    </div>
  )
}
//
// BarConfig.propTypes = {
//   dataSets: PropTypes.object.isRequired,
//   config: PropTypes.object.isRequired,
//   onUpdate: PropTypes.func.isRequired
// }
