import { useNavigate } from 'react-router-dom';
import { Flex, Image, Link, View, useAuthenticator, Divider } from "@aws-amplify/ui-react";

import { useGroups } from "../utils"

import logo from "../../assets/RipeDataLogoHorizontalLightBk.svg";

export const Navbar = () => {
    const [groups] = useGroups();
    
    const { signOut, authStatus } = useAuthenticator((context) => [
        context.route,
        context.signOut,
    ]);

    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate('/');
    }

    return (
        <View backgroundColor="background.primary">
            <Flex alignItems="flex-start" width="100%" padding="5px" justifyContent="center">
                <Link href="/" height="24px">
                    <Image src={logo} className="App-logo" alt="logo" height={24} />
                </Link>

                <View grow="1" shrink="1" />

                {
                    authStatus === 'authenticated' && groups.includes( 'authors' ) ? (
                        <View onClick={() => navigate('/gameselect')}>
                            Questions
                        </View>
                    ) : null
                }

                <View onClick={() => navigate('/archive')}>Archives</View>
             
                {
                    authStatus !== 'authenticated' ? (
                        <View onClick={() => navigate('/login')}>Login</View>
                    ) : (
                        <View onClick={() => logOut()}>Logout</View>
                    )
                }
            </Flex>
            <Divider size="small" />
        </View>
    )
}