import { Button, Flex } from '@aws-amplify/ui-react';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaRandom } from 'react-icons/fa';

const buttonSize = 30;
const disabledOpacity = 0.3;
const buttonColor = "#f05a28"

export const NavButtons = ({first, previous, random, next, latest, onSelect}) => {

    function btnClick( val ) {
        onSelect && onSelect( val )
    }

    return (
        <Flex direction="row" alignContent="space-between" marginLeft="auto" marginRight="auto">
            <Button
                variation="link"
                size="small"
                color={buttonColor}
                onClick={()=>{btnClick(first)}}
                disabled={!first}
                ariaLabel="First"
                title={ first && first.title ? first.title : "First"}
            >
                <FaAngleDoubleLeft size={buttonSize}  opacity={first ? 1.0 : disabledOpacity}/>
            </Button>

            <Button
                variation="link"
                size="small"
                color={buttonColor}
                onClick={()=>{btnClick(previous)}}
                disabled={!previous}
                ariaLabel="Previous"
                title={ previous && previous.title ? previous.title : "Previous"}
            >
                <FaAngleLeft size={buttonSize} opacity={previous ? 1.0 : disabledOpacity} />
            </Button>

            <Button
                variation="link"
                size="small"
                color={buttonColor}
                onClick={()=>{btnClick(random)}}
                disabled={!random}
                ariaLabel="Random"
                title={ random && random.title ? random.title : "Random"}
            >
                <FaRandom size={buttonSize}  opacity={random ? 1.0 : disabledOpacity} />
            </Button>

            <Button
                variation="link"
                size="small"
                color={buttonColor}
                onClick={()=>{btnClick(next)}}
                disabled={!next}
                ariaLabel="Next"
                title={ next && next.title ? next.title : "Next"}
            >
                <FaAngleRight size={buttonSize}  opacity={next ? 1.0 : disabledOpacity} />
            </Button>

            <Button
                variation="link"
                size="small"
                color={buttonColor}
                onClick={()=>{btnClick(latest)}}
                disabled={!latest}
                ariaLabel="Latest"
                title={ latest && latest.title ? latest.title : "Latest"}
            >
                <FaAngleDoubleRight size={buttonSize}  opacity={latest ? 1.0 : disabledOpacity} />
            </Button>
        </Flex>
    );
}