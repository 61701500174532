import { useEffect, useReducer } from "react";
import { generateClient } from 'aws-amplify/api';

import { listPublishedGames } from "../graphql/queries";
import { addAuthMode } from "./AmplifyUtils";

export const useArchive = ( current ) => {

  const [archive, setArchive] = useReducer(
    ( state, update ) => {
      const newState = {...state, ...update }

      return newState
    }, {
      _loaded: false,
      _fetching: false,
      _error: '',
      _list: [],
      current: null,
      first: null,
      previous: null,
      random: null,
      next: null,
      latest: null
    }
  )

  useEffect(() => {
 
    if ( !archive._fetching && !archive._loaded && archive._list.length === 0 ) {

      setArchive( { _fetching: true, _error: '' } )

      addAuthMode( {
        query: listPublishedGames,
        variables: {
          type: 'PUBLISHED',
          sortDirection: 'DESC'
        }
      } )
        .then( apiParams => {

          const client = generateClient();

          return client.graphql(apiParams)
        })
        .then( response => {
          let items = []
          if ( response.data && response.data.listPublishedGames && Array.isArray( response.data.listPublishedGames.items ) ) {
            items = response.data.listPublishedGames.items
          }

          let archiveUpdate = { _fetching: false, _loaded: true, _list: items }

          if ( Array.isArray( items ) ) {

            // Compute the new pointers based on the published list and current setting.
            let newSettings = setPointers( items, current );

            // Add the updated pointers to the archive settings.
            archiveUpdate = { ...archiveUpdate, ...newSettings };
          }         

          setArchive( archiveUpdate )

        })
        .catch( error => {
          console.log( 'ERROR:', error )
        })
    }
  }, [archive._fetching, archive._loaded, current] );

  function setPointers( list, current ) {
    let games = { current: null, first: null, previous: null, random: null, next: null, latest: null }

    if ( Array.isArray( list ) && list.length ) {
      if ( current ) {
        // We have a current item so find it in the list 
        let curIdx = list.findIndex( d => {
          return d.gameId === current;
        });

        if ( curIdx >= 0 ) {
          games.current = current;

          // Not the first so set first and previous pointers.
          if ( curIdx < list.length - 1 ) {
            games.first = list[list.length - 1].gameId;
            games.previous = list[curIdx + 1].gameId;
          }

          // Not the last so set the next and latest pointers.
          if ( curIdx > 0 ) {
            games.next = list[curIdx - 1].gameId;
            games.latest = list[0].gameId;
          }
        }
      }

      if ( !games.current ) {
        // Either no game was specified, or the specified game isn't in the list so set current to the latest.
        games.current = list[0].gameId;
        if ( list.length > 1 ) {
          games.first = list[list.length-1].gameId;
          games.previous = list[1].gameId;
        }
      }

      // And set the random pointer.
      games.random = list[Math.floor( Math.random() * list.length )].gameId;
    }

    return games
  }

  function setCurrent( newCurrent ) {
    if ( archive._loaded && archive._list.length && newCurrent ) {
      let newPointers = setPointers( archive._list, newCurrent );

      setArchive( newPointers );
    }
  }

  return [archive, setCurrent]
};