import React from 'react';
import {useDroppable, UniqueIdentifier} from '@dnd-kit/core';
import classNames from 'classnames';

import {droppable} from './droppable-svg';
require( './Droppable.scss' );

interface Props {
  children: React.ReactNode;
  dragging: boolean;
  id: UniqueIdentifier;
}

export function Droppable({children, id, dragging}: Props) {
  const {isOver, setNodeRef} = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        'dnd-droppable',
        isOver && 'dnd-droppable-over',
        dragging && 'dnd-droppable-dragging',
        children && 'dnd-droppable-dropped'
      )}
      aria-label="Droppable region"
    >
      {children}
      {droppable}
    </div>
  );
}
