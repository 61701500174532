import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { format } from "d3-format"
import { Range } from "react-range"
import { Flex, View } from "@aws-amplify/ui-react";

export const Slider = ( { className, min, max, step, label, value, onUpdate, marks, markFormat } ) => {

  let wrapperClasses = classNames( 'slider-ctl', className )

  let fmtFunc = markFormat ? format( markFormat ) : ( val ) => { return `${val}` }

  const valueMarks = marks.reduce( ( obj, v ) => {
    obj[v] = fmtFunc( v )
    return obj
  }, {} )

  return (
    <Flex
      padding="medium small small xs"
      alignItems="center"
      className={wrapperClasses}
    >
      {
        label ? (
          <View className="slider-label" >
            {label}
          </View>
        ) : null
      }

      <View width="100%" >
        <Range
          min={min}
          max={max}
          step={step}
          marks={valueMarks}
          values={[value]}
          onChange={( vals ) => { onUpdate( vals[0] ) }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '4px',
                width: '100%',
                backgroundColor: '#ccc'
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                borderRadius: '8px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-22px',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '10px',
                  padding: '2px 4px',
                  borderRadius: '3px',
                  backgroundColor: '#f47e39'
                }}
              >
                {value}
              </div>
              <div
                style={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '4px',
                  backgroundColor: isDragged ? '#f47e39' : '#CCC'
                }}
              />
            </div>
          )}
          tipProps={{
            prefixCls: 'rc-slider-tooltip',
            placement: 'top'
          }}
          tipFormatter={( vals ) => { return fmtFunc( vals[0] ) }}
        />
      </View>
    </Flex>
  )
}

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  marks: PropTypes.array,
  markFormat: PropTypes.string
}
