import React from 'react'
import classNames from 'classnames'
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css'

require( './Slider.scss' )
// require( '../Controls.scss' )

export const Range = ( props ) => {
  let {
    defaultValue,
    min,
    max,
    marks,
    dots,
    step,
    value,
    reverse,
    allowCross,
    pushable,
    onChange,
    vertical,
    className,
    trackStyle,
    handleStyle,
    color,
    backgroundColor,
    inline,
    label,
    labelClassName,
    tipProps,
    tipFormatter,
    ...remaining
  } = props

  let classes = classNames( className, 'slider', inline ? 'slider-inline' : '' )
  let labelClasses = classNames( labelClassName, 'slider-label', inline ? 'slider-label-inline' : '' )
  let containerClasses = classNames( 'slider-container', 'rdcc-text', className )
  let track = trackStyle ? trackStyle : {}
  let handle = handleStyle ? handleStyle : {}

  if ( color ) {
    track.backgroundColor = color
    handle.borderColor = color
  }

  if ( backgroundColor ) {
    handle.backgroundColor = backgroundColor
  }

  // const SliderComponent = ( tipProps || tipFormatter ) ? createSliderWithTooltip(Slider) : Slider

  return (
    <div className={classes} {...remaining}>
      {
        label ? (
          <div className={labelClasses}>
            {label}
          </div>
        ) : null
      }

      <div className={containerClasses} {...remaining} >

        <Slider
          range
          vertical={vertical}
          min={min}
          max={max}
          marks={marks}
          dots={dots ? true : false}
          dotStyle={{}}
          step={step}
          allowCross={allowCross}
          pushable={pushable}
          reverse={reverse}
          defaultValue={value ? value : defaultValue}
          value={value}
          trackStyle={[track]}
          handleStyle={[handle,handle]}
          activeDotStyle={handle}
          onChange={onChange}
          tipFormatter={tipFormatter}
          tipProps={tipProps}
        />

      </div>
    </div>
  )
}
