import { useEffect, useState } from 'react';
import clone from 'clone';
import { Flex } from '@aws-amplify/ui-react';

import { numBins } from '../../GameConfig/components/ConfigPanel/ConnectFourConfig'
import { Description } from '../Description';
import { ExploreDataButton } from '../ExploreDataButton';
import { ConnectFourContainers } from './ConnectFourContainers';
import { ConnectFourProgress } from './ConnectFourProgress'

require( './ConnectFour.scss' );

export const ConnectFourDrag = ( { gameId, config, onAnswer, gameStatus } ) => {
    const optConfig = config && config.optionsConfig ? config.optionsConfig : {};
    const breaks = Array.isArray( optConfig.breaks ) ? optConfig.breaks : [];
    const status = gameStatus ? JSON.parse( gameStatus ) : {};

    const [items, setItems] = useState(status.answer ? clone( status.answer ) : {});
    const [answers, setAnswers] = useState(Array.isArray( status.status ) ? clone( status.status ) : []);
    const [checkedAnswer, setCheckedAnswer] = useState( status.checked ? status.checked : {});

    let desc = config.description ? config.description : "No Description";
    
    useEffect( () => {
        let totalItems = Object.keys( items ).reduce( ( sum, d ) => {
            sum = sum + items[d].length;
            return sum;
        }, 0 );

        if ( Array.isArray( config.options ) && totalItems !== config.options.length ) {
            let options = Array.isArray( config.options ) ? config.options.slice() : [];

            // generate the structure we need for the game.
            let gameItems = options.map( ( d, i ) => {
                return {
                    id: d.label,
                    label: d.label,
                    data: {
                        value: d.value,
                        status: 'unset'
                    }
                }
            } )

            // If we don't have the right number of items then we can't play.
            if ( gameItems.length === ( numBins * numBins ) ) {
                let newItems = {
                    placing: [gameItems[0]],
                    toPlace: gameItems.slice( 1 )
                }

                // Add in the drop bins.
                for( let idx = 0; idx < numBins; idx++ ) {
                    newItems[`bin${idx}`] = []
                }

                // and the placed bins.
                for( let idx = 0; idx < numBins; idx++ ) {
                    newItems[`placedbin${idx}`] = []
                }

                // Update the status of the various types.
                newItems.placing.forEach( d => {
                    d.data.status = 'placing'
                });

                setItems( newItems );
                setAnswers( [...Array(gameItems.length).keys()].map( d => { return 0 }) );
            }
        }

    }, [config.options, items, answers] );

    function onPlace( update ) {
        let newItems = { ...update }
        let newAnswers = answers.slice();

        let bin = -1;

        for ( let bNum = 0; bNum < numBins; bNum++ ) {
            if ( newItems[`bin${bNum}`].length ) {
                bin = bNum;
            }
        }

        // bNum = bNum >= numBins ? -1 : bNum;

        // Now that we know where the item was dropped we can move it to the 
        // proper placed location and 

        if ( bin >= 0 ) {
            let binId = `bin${bin}`;
            let placedId = `placedbin${bin}`;
            let checkItem = newItems[binId][0];

            // Determine whether the item was placed in the correct bin.
            let pBin = getBin( checkItem.data.value );
            let correct = pBin === bin ? true : false;

            // console.log( 'CORRECT:', correct, binId, pBin, checkItem.data.value, checkItem, breaks )

            // Update the status
            checkItem.data = {
                ...checkItem.data,
                status: 'placed',
                correct
            }

            newItems[placedId].push( checkItem );
            newItems[binId] = [];

            // Then update the answers array.
            newAnswers[(bin * numBins) + newItems[placedId].length-1] = correct ? 1 : -1;
        }

        const checked = checkAnswers( newAnswers );

        if ( checked.correct ) {

            // The answer is correct so we are done.
            onAnswer( { answer: newItems, status: newAnswers, checked: checked } );
            setCheckedAnswer( checked );
        } else {

            // Still more to go so we check to see if there are any more items to be placed.
            if ( newItems.toPlace.length ) {
                newItems.placing = [{...newItems.toPlace[0]}];
                newItems.placing[0].data.status = 'placing';

                newItems.toPlace = newItems.toPlace.slice(1);
            }

            // If we have no more items to place then we are done so record the results.
            if ( newItems.placing.length === 0 ) {
                onAnswer( { answer: newItems, status: newAnswers, checked: checked } );
                setCheckedAnswer( checked );
            }
        }

        // Update our state.
        setItems( newItems );
        setAnswers( newAnswers );

        return newItems
    }

    const getBin = ( val ) => {
        let bin = 3;
    
        if ( val < breaks[0] ) {
          bin = 0
        } else if ( val < breaks[1] ) {
          bin = 1
        } else if ( val < breaks[2] ) {
          bin = 2
        }
    
        return bin;
    }

    const checkAnswers = ( answerArray ) => {
        let correct = false;
        let dir = '';
        let val = -1;

        // First check the horizontal positions.
        let sum = 0;
        for ( let idx = 0; idx < numBins && sum !== numBins ; idx++ ) {
            sum = 0;
            for ( let bin = 0; bin < numBins; bin++ ) {
                sum = sum + answerArray[( bin * numBins ) + idx];
            }
            
            // Remember which row we are processing.
            val = idx;
        }

        correct = sum === numBins;

        if ( correct ) {
            dir = 'h';
        } else {

            // then the vertical positions.
            let sum = 0;
            for ( let bin = 0; bin < numBins && sum !== numBins ; bin++ ) {
                sum = 0;
                for ( let idx = 0; idx < numBins; idx++ ) {
                    sum = sum + answerArray[( bin * numBins ) + idx];
                }

                // Remember which bin we are processing.
                val = bin;
            }

            correct = sum === numBins;

            if ( correct ) {
                dir = 'v';
            } else {
                // Now look diagionally.
            }
        }

        return { correct, dir, val };
    }
      
    return (

        <Flex direction="column" maxWidth="450px" margin="large auto" >

            <Description desc={desc} />

            {
                Array.isArray( items.placing ) && items.placing.length === 0 ? (
                    <ExploreDataButton gameId={gameId} />
                ) : null
            }

            <Flex direction="column" grow={1} shrink={1}>
                {
                    Object.keys( items ).length > 0 ? (
                        <ConnectFourContainers
                            key=""
                            items={items}
                            onPlacement={onPlace}
                            labels={config.binLabels}
                            valueFormat={config.format}
                            numBins={numBins}
                        />
                    ) : null
                }
            </Flex>
            <ConnectFourProgress answers={answers} numBins={numBins} checkedAnswer={checkedAnswer} />
            
        </Flex>

    )
  }