
import { addCategoricalAxis } from './categoricalaxis'
import { addColorScale } from './colorscale'
import { addNumericAxis } from './numericaxis'
import { genTooltip } from './tooltip'
import { getDataset, getConfigField } from './utils'

const defaultDiameter = 8

export function beeswarm( config, data ) {

  // Pull out the symbol
  let symbol = config.symbol ? config.symbol : { size: defaultDiameter }

  // Set the default diameter if the symbol object exists but the size doesn't
  symbol.size = symbol.size ? symbol.size : defaultDiameter

  // Generate the generic spec with the default signals and sizing.
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated beeswarm chart specification.',
    autosize: { type: 'fit-x', resize: false, contains: 'padding' },
    height: 600,
    padding: 5,
    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      },
      { name: 'cx', update: 'width / 2' },
      {
        name: 'diameter',
        update: `width >= 300 ? ${symbol.size} : ${symbol.size}/2`
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  // The bars value value is defined so we have a chart to define.
  let bField = getConfigField( config.b )
  let vField = getConfigField( config.v )

  // We must have a bin field in order to do anything.
  if ( bField ) {

    let ds = getDataset( spec, config.b.datasetId )

    if ( ds ) {
      ds.transform.push( {
        type: 'filter',
        expr: `datum.${config.b.loc} != null`
      } )
    }

    // Now configure the scale and axis for the bars.
    addCategoricalAxis( spec, config.b, 'b', 'bottom', 'width' )
  }

  // The Y axis value is defined so add in the requsite filters, scales and axes
  if ( vField ) {
    let ds = getDataset( spec, vField.datasetId )

    if ( ds ) {
      ds.transform.push(
        {
          type: 'filter',
          expr: `isNumber( datum.${vField.loc} )`
        }
      )
    }

    let vOpts = {
      prefix: 'v',
      orient: 'left',
      domain: { data: vField.datasetId, field: vField.loc },
      range: 'height',
    }

    addNumericAxis( spec, config.v, vOpts )
  }

  // Add the color configuration to the specification
  addColorScale( spec, config.color, 'color' )

  if ( bField && vField ) {

    // Get the static color for the mark if it exists.
    let markClr = config.color && config.color.mark ? config.color.mark : {}

    let chartMark = {
      name: 'marks',
      type: 'symbol',
      from: { data: bField.datasetId },
      encode: {
        update: {
          xfocus: { signal: 'cx' },
          yfocus: { scale: 'vScale', field: vField.loc },

          size: { signal: `pow(2 * diameter, 2)` },
          
          fill: { value: markClr.fill ? markClr.fill : 'steelblue' },
          stroke: { value: 'white' },
          strokeWidth: { value: 1 },
          zindex: { value : 0 }
        }
      },
      transform: [
        {
          type: 'force',
          iterations: 50,
          static: true,
          forces: [
            { force: 'collide', iterations: 1, radius: { signal: 'diameter' } },
            { force: 'x', x: 'xfocus', strength: 0.2 },
            { force: 'y', y: 'yfocus', strength: 0.1 }
          ]
        }
      ]
    }

    if ( bField ) {
      chartMark.encode.update.xfocus = { scale: 'bScale', field: bField.loc, band: 0.5}
    }

    let colorField = getConfigField( config.color )
    if ( colorField ) {
      chartMark.encode.update.fill = { scale: 'color', field: colorField.loc }
      chartMark.encode.update.stroke = { scale: 'color', field: colorField.loc }

      // Find out if the 'selected' dataset has been created, if so then we 
      // have an interactive legend.
      let colorDataset = spec.data.find( d => {
        return d.name === 'selected'
      })

      if ( colorDataset ) {

        chartMark.encode.update.opacity = [
          { test: `(!length(data('selected')) || indata('selected', 'value', datum.${colorField.loc}))`, value: 1.0 },
          { value: 0.05 }
        ]
      }
    } else {

    }

    // If a tooltip is specified...
    if ( Array.isArray( config.tt ) ) {
      chartMark.encode.update.tooltip = { signal: genTooltip( config.tt ) }

      // Now add the hover coloring so we have a visual indication of what
      // the tooltip applies to.
      let hoverClr = config.color && config.color.hover ? config.color.hover : {}

      chartMark.encode.hover = {
        fill: { value: hoverClr.fill ? hoverClr.fill : 'gray' },
        stroke: { value: hoverClr.stroke ? hoverClr.stroke : 'red' },
        strokeWidth: { value : 3 },
        zindex: { value: 1 }
      }
    }

    spec.marks.push( chartMark )
  }

  return spec
}
