import React, { useContext } from 'react'
import { SelectField, View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext'

export const GameType = () => {
  const { config, updateConfig } = useContext( GameConfigContext )
  let gameType = config && config.type ? config.type : ''

  return (
    <View  className="gp-typ" >
      <View className="gp-typ-label" >
        Game Type
      </View>

      <SelectField
        label="game type selection"
        labelHidden

        value={gameType}
        onChange={(event) => { updateConfig( { type: event.target.value } )}}
        size="default"
      >
        <option value="">Not set</option>
        <option value="connectfour">Connect Four Numeric</option>
        <option value="connectfourcategory">Connect Four Category</option>
        <option value="orderem">Order Values</option>
      </SelectField>

    </View>
  )
}
