// Convert an object into an array where the property names become the key
// and the associated value becomes the value.
// Converts:
//    { foo: a, bar: b }
//
// Into:
//    [{k: foo, v: a}, {k: bar, v: b}]

export function objToArray( obj ) {
  return Object.keys( obj ).reduce( ( ary, d ) => {
    ary.push( {
      k: d,
      v: obj[d]
    } )
    return ary
  }, [] )
}

// Set a value in an object
export function setValue( obj, path, value ) {
  if ( obj && path ) {
    let ref = obj;
    let parts = path.split( '.' );

    // Walk the object, creating the path as necessary.
    for ( let idx = 0; idx < parts.length - 1; idx++ ) {
      if ( typeof ref[parts[idx]] !== 'object' ) {
        ref[parts[idx]] = {};
      }

      ref = ref[parts[idx]];
    }

    // When we reach the terminus add the value.
    ref[parts[parts.length - 1]] = value;
  }
}
