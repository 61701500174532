import { getConfigField } from './utils'
import { genTooltip } from './tooltip'
import { addNumericAxis } from './numericaxis'
import { addCategoricalAxis } from './categoricalaxis'

export function usstates( config, data ) {
  let spec = {
    $schema: "https://vega.github.io/schema/vega/v5.json",
    description: "A configurator generated US States choropleth specification.",
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    height: 500,
    padding: 5,
  
    data: [
      {
        name: "states-topojson",
        url: "https://datasets.ripedata.com/us-10m.json",
        format: { type: "topojson", feature: "states" },
        transform: [
        ]
      }
    ],
  
    "projections": [
      {
        name: "projection",
        type: "albersUsa",
        fit: {signal: "data('states-topojson')"},
        size: {signal: "[width, height]" }
      }
    ],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      }
    ],
  
    "scales": [
    ],
  
    "legends": [
    ],
  
    "marks": [
      {
        type: "shape",
        from: {data: "states-topojson"},
        encode: {
          // "enter": { "tooltip": {"signal": "format(datum.rate, '0.1%')"}},
          // update: { fill: {scale: "color", field: "rate"} },
          update: { fill: {value: "#5ba3cf"}, stroke: {value: "white"} },
          hover: { fill: {value: "red"}, stroke: {value: "white"} }
        },
        transform: [
          { type: "geoshape", projection: "projection" }
        ]
      }
    ]
  }

  // console.log( 'US STATES:', JSON.stringify( spec, null, 2 ) )

  return spec
}
