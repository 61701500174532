import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { TextInput } from '../../../../../../Controls'
import { VegaEditorContext } from '../../../../VegaEditorContext'

import { ConfigField } from './ConfigField'
import { Checkbox } from './Checkbox'

export const CategoricalAxisConfig = ( { label, prop, validDataset, defaultProps } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const myConfig = config[prop] ? config[prop] : defaultProps[prop] ? defaultProps[prop] : {}
  const myDomain = config[prop] && config[prop].domain ? config[prop].domain : {}
  const myGrid = config[prop] && config[prop].grid ? config[prop].grid : {}
  const myTicks = config[prop] && config[prop].ticks ? config[prop].ticks : {}
  const myTitle = config[prop] && config[prop].title ? config[prop].title : {}

  function updateAxes( update ) {
    let newConfig = { ...myConfig, ...update }

    updateConfig( { [prop]: newConfig } )
  }

  function updateDomain( update ) {
    let newDomain = { ...myDomain, ...update }
    updateAxes( { domain: newDomain } )
  }

  function updateGrid( update ) {
    let newGrid = { ...myGrid, ...update }
    updateAxes( { grid: newGrid } )
  }

  function updateTicks( update ) {
    let newTicks = { ...myTicks, ...update }
    updateAxes( { ticks: newTicks } )
  }

  function updateTitle( update ) {
    let newTitle = { ...myTitle, ...update }
    updateAxes( { title: newTitle } )
  }

  return (
    <ConfigField
      label={label}
      fieldProp="field"
      field={myConfig.field ? myConfig.field : null}
      accept={['C']}
      onDrop={updateAxes}
      validDataset={validDataset}
    >

      <TextInput
        className="cp-xs-prp"
        label="Label"
        value={myTitle.title}
        onUpdate={( val ) => { updateTitle( { title: val } ) }}
      />

      <Checkbox
        className="cp-xs-prp"
        label="Show axis"
        value={!!myDomain.show}
        onUpdate={( val ) => { updateDomain( { show: val } ) }}
      />

      <Checkbox
        className="cp-xs-prp"
        label="Show ticks"
        value={!!myTicks.show}
        onUpdate={( val ) => { updateTicks( { show: val } ) }}
      />

      <Checkbox
        className="cp-xs-prp"
        label="Show grid"
        value={!!myGrid.show}
        onUpdate={( val ) => { updateGrid( { show: val } ) }}
      />

      <Checkbox
        className="cp-xs-prp"
        label="Sort alphabetically"
        value={!!myDomain.sort}
        onUpdate={( val ) => { updateDomain( { sort: val } ) }}
      />

    </ConfigField>
  )
}


CategoricalAxisConfig.propTypes = {
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired
}
