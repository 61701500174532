import { useEffect, useReducer, useState } from "react";
import { uploadData, downloadData } from 'aws-amplify/storage';
import { generateClient } from 'aws-amplify/api';
import { useAuthenticator } from "@aws-amplify/ui-react";
import isEqual from 'lodash/isEqual'

import {
  createGame as createGameMutation,
  updateGame as updateGameMutation
} from "../graphql/mutations";

const pako = require('pako');

const dbFields = ["gameId", "author", "title", "category", "thumbnail", "published"];
const stateFields = ["_loaded", "_fetching", "_storing", "_error"];

export const useGame = ( gameId ) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const [game, setGame] = useReducer(
    ( state, update ) => {
      const newState = {...state, ...update }

      // Set the gameId if we don't have one.
      newState.author = newState.author ? newState.author : user && user.username ? user.username : '';
      newState.gameId = newState.gameId ? newState.gameId : gameId ;

      if ( state._loaded ) {
        let fldCnt = Object.keys( newState ).reduce( ( cnt, d ) => {

          if ( newState[d] !== undefined && !stateFields.includes( d ) ) {

            if ( !isEqual( newState[d], state[d] ) ) {
              // console.log( 'DIFFERENT:', d, newState[d], state[d] )
              cnt++
            }
          }
          return cnt
        }, 0 )

        // Check to see if there have been any changes, if so,
        // perform the update.
        if ( fldCnt > 0 ) {

          // Then update the DynamoDB record.
          if ( state.body ) {
            updateGame( newState )
          } else {
            createGame( newState )
          }
        }
      }

      return newState
    }, {
      _loaded: false,
      _fetching: false,
      _storing: false,
      _error: ''
    } )

  useEffect(() => {
    if ( gameId ) {

      setGame( { _loaded: false, _fetching: true } );

      downloadData({ key: getObjectName( gameId ), options: { level: "guest", download: true } }).result
        .then( response => {
          return response.body.text();
        })
        .then( response => {
          const game = JSON.parse( response );

          // We need to empty all of the fields so we don't carry over any values 
          // that are missing from the current game.
          let emptyGame = dbFields.reduce( ( obj, d ) => {
            obj[d] = null;
            return obj;
          }, {});

          setGame( { _loaded: true, _fetching: false, _error: '', ...emptyGame, ...game } );
        })
        .catch( error => {
          if ( error.name === "NoSuchKey" ) {
            setGame( { _loaded: true, _fetching: false, _error: '' } );
          } else {
            console.log( 'GAME RETRIEVAL ERROR:', typeof _error, error );
            setGame( { _loaded: false, _fetching: false, _error: error } );
          }
        })
    }

  }, [gameId]);

  async function createGame( game ) {

    if ( game && game.gameId ) {
      // First seralizerize the game 
      const deflated = pako.gzip(JSON.stringify( createStorageRec( game ) ), {to: 'string'});
      
      try {

        // Then store the game to the file.
        const result = await uploadData({
          key: getObjectName( game.gameId ),
          data: deflated.buffer,
          options: {
            accessLevel: 'guest',
            contentEncoding: "gzip",
            contentType: "application/json"
          }
        }).result;

        // Create the database record from the game.
        const dbRec = createDbRec( game );

        // Update the DynamoDB record.

        const client = generateClient();

        client.graphql({
          query: createGameMutation,
          variables: { input: dbRec },
        } )
          .then( resp => {
            // Now update the current state.
            setGame( game );
          });
        
      } catch( error ) {
        console.log( 'STORAGE ERROR:', error )
      }
    }
  }

  async function updateGame( game ) {

    if ( game && game.gameId ) {
      // First seralizerize the game 
      const deflated = pako.gzip(JSON.stringify( createStorageRec( game ) ), {to: 'string'});

      // Then store the game to the file.
      try {
        // Then store the game to the file.
        const result = await uploadData({
          key: getObjectName( game.gameId ),
          data: deflated.buffer,
          options: {
            accessLevel: 'guest',
            contentEncoding: "gzip",
            contentType: "application/json"
          }
        } ).result;

        // Create the database record from the game.
        const dbRec = createDbRec( game );

        // Update the DynamoDB record.

        const client = generateClient();

        client.graphql({
          query: updateGameMutation,
          variables: { input: dbRec },
        } )
        .then( resp => {
          // Now update the current state.
          setGame( game );
        } )
        
      } catch( error ) {
        console.log( 'STORAGE ERROR:', error )
      }
    }
  }

  function createStorageRec( game ) {
    let sRec = {};

    if ( game && game.gameId ) {
      sRec = Object.keys( game ).reduce( ( obj, d ) => {
        if ( game[d] !== undefined && !stateFields.includes( d ) ) {
          obj[d] = game[d]
        }
        return obj
      }, {} );
    }

    return sRec
  }

  function createDbRec( game ) {
    let dbRec = {};

    if ( game && game.gameId ) {
      dbRec = dbFields.reduce( ( obj, d ) => {
        if ( game[d] !== undefined ) {
          obj[d] = game[d]
        }
        return obj
      }, {} );
    }

    return dbRec
  }

  function getObjectName( id ) {
    return `${id}.json.gz`
  }

  return [game, setGame];
};

