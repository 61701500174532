import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { View } from "@aws-amplify/ui-react";

import { VegaEditorContext } from '../../../../VegaEditorContext'
import { dropBackgroundStyle } from './DropField'
import { MultiFieldItem } from './MultiFieldItem'

// Generates tooltip configurations stored in the configuration based on the
// provided prop.
//
// A tooltip configuration is an array of tooltip item structures which look
// like this:
// {
//    id: <a unique identifier (currently the field location>
//    field: <A field structure defining the source of information>
//    format: <The format to be used if it's a numeric field>
//    label: <The label to be used for this item in the tooltip, it defaults
//            to the field name>
// }

export const MultiFieldConfig = ( { prop, accept, placeholder, validDataset } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const mfFields = Array.isArray( config[prop] ) ? config[prop].map( d => {
    return d.id ? d.id : d.field.loc
  } ) : []

  // Handle drop of fields to create tooltip item.
  const [ { isOver, canDrop }, drop ] = useDrop( {
    accept,
    canDrop: ( item ) => { return !mfFields.includes( item.loc ) },
    drop: ( item ) => {

      // A new field has been dropped so add it to the list of fields.
      let newMf = config && Array.isArray( config[prop] ) ? [...config[prop]] : []

      newMf.push( {
        id: item.loc,
        field: item
      } )

      updateConfig( { [prop]: newMf } )
    },
    collect: (monitor) => ( {
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    } ),
  } )

  function removeItem ( idx ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length ) {
      let newMf = [...config[prop].slice( 0, idx ), ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newMf } )
    }
  }

  function updateItem ( idx, item ) {
    if ( Array.isArray( config[prop] ) && idx >= 0 && idx <= config[prop].length && item ) {
      let newMf = [...config[prop].slice( 0, idx ), item, ...config[prop].slice( idx + 1 )]
      updateConfig( { [prop]: newMf } )
    }
  }

  function moveItem ( from, to ) {
    if ( Array.isArray( config[prop] ) ) {
      let mfi = config[prop][from]
      let rmMf = [...config[prop].slice( 0, from ), ...config[prop].slice( from + 1 )]
      let newMf = [...rmMf.slice( 0, to ), mfi, ...rmMf.slice( to )]

      updateConfig( { [prop]: newMf } )
    }
  }

  const dzClasses = Array.isArray( config[prop] ) && !config[prop].length ? 'cp-mf-dz cp-cfg-drp-fld-dflt' : 'cp-mf-dz'

  let labelStyle = dropBackgroundStyle( isOver, canDrop )

  return (
    <View className="cp-mf">
      <div
        ref={drop}
        className={dzClasses}
        style={labelStyle}
      >
        {
          Array.isArray( config[prop] ) && config[prop].length ? (
            config[prop].map( ( d, i )=> {
              return (
                <MultiFieldItem
                  key={d.id}
                  item={d}
                  idx={i}
                  accept={accept}
                  onRemove={removeItem}
                  onUpdate={updateItem}
                  onMove={moveItem}
                />
              )
            } )
          ) : (
            <View>
              {placeholder ? placeholder : 'Drop fields here'}
            </View>
          )
        }

      </div>
    </View>
  )
}


MultiFieldConfig.propTypes = {
  accept: PropTypes.array.isRequired,
  prop: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}
