export function percentileRank( allVals, val, makeObj ) {
  makeObj = makeObj !== undefined ? true : false
  let ptileRank = null
  if ( allVals && val !== undefined ) {
    if ( allVals.indexOf( val ) === -1 ) { //val is not from comparison data
      allVals = allVals.concat( val )
      allVals = allVals.sort( ( a, b ) => {
        return a < b ? -1 : a > b ? 1 : 0
      } )
    }
    let indices = allVals.reduce( ( arr, v, idx ) => {
      return v === val ? arr.concat( idx ) : arr
    }, [] )

    if ( indices.length ) {
      let ptiles = indices.map( i  => {
        return 100 * ( i + 0.5 ) / allVals.length  // adjusted to + because js starts arrays at 0
      } )

      let sum = ptiles.reduce( ( tot, v ) => {
        return tot + v
      } )

      ptileRank = Math.round( sum / indices.length * 10 ) / 10
    }
  }

  let obj = {}

  if ( makeObj && Array.isArray( allVals ) ) {
    let indxLarger = null
    let indxSmaller = null

    let areLarger = allVals.some( ( v, idx ) => {
      indxLarger = idx
      return Math.round( v ) > Math.round( val )
    } )

    let areSmaller = allVals.some( ( v, idx ) => {
      indxSmaller = allVals.length - idx - 1
      return Math.round( allVals[indxSmaller] ) < Math.round( val )
    } )

    let nLarger = allVals.length - indxLarger
    let nSmaller = indxSmaller + 1
    let pLarger = Math.round( nLarger * 100 / allVals.length )
    let pSmaller = Math.round( nSmaller * 100 / allVals.length )

    if ( ptileRank > 99 ) {
      ptileRank = 99
    } else if ( ptileRank < 1 ) {
      ptileRank = 1
    }

    obj = {
      ptile: ptileRank,
      pLarger,
      pSmaller,
      nLarger,
      nSmaller,
      n: allVals.length
    }
  }



  return makeObj ? obj : ptileRank
}

// return value corresponding to the pth percentile
export function percentile( list, p ) {
  list = list.sort( ( a, b ) => {
    return a < b ? -1 : a > b ? 1 : 0
  } )
  let whole = p * ( list.length + 1 ) / 100
  let position = Math.floor( whole )
  let value
  if ( position < 1 ) {
    value = list[0]
  } else if ( position > list.length - 1 ) {
    value = list[list.length - 1]
  } else {
    value = list[position -1] + ( whole - position ) * ( list[position] - list[position-1] )
  }
  return value
}

export function average( list ) {
  let calc = list.reduce( ( curr, e ) => {
    curr[0] += e
    curr[1] += 1
    return curr
  }, [0,0] )
  return calc[1] ? calc[0]/calc[1] : null
}


export function sixnumberdata( list ) {
  return [
    average( list ),
    percentile( list, 10 ),
    percentile( list, 25 ),
    percentile( list, 50 ),
    percentile( list, 75 ),
    percentile( list, 90 )
  ]
}
