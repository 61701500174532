import React, { useContext } from 'react';
import { Flex, View, Text } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { TextInput } from './TextInput';

const maxOptions = 4;

export const MatchConfig = () => {
  const { config, updateConfig } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill({label:'', value:''});
  const leftLabel = config.leftLabel ? config.leftLabel : '';
  const rightLabel = config.rightLabel ? config.rightLabel : '';

  function updateOption( idx, val ) {
    let newOptions = options.slice();
    newOptions[idx] = val;
    updateConfig( { options: newOptions } )
  }

  function updateThreshold( value ) {
    let threshold = Number( value );
    updateConfig( { threshold })
  }

  return (
      <View  className="gp-typ" marginTop="large" >

        <TextInput
          label="Left Label"
          key="left"
          value={leftLabel}
          placeholder={`Label for left container...`}
          onChange={( val ) => { updateConfig({ leftLabel: val })}}
          marginBottom="large"
        />

        <TextInput
          label="Right Label"
          key="right"
          value={rightLabel}
          placeholder={`Label for right container...`}
          onChange={( val ) => { updateConfig({ rightLabel: val })}}
          marginBottom="large"
        />

        {
          options.map( ( d, i ) => {
            return (
              <Flex key={`p${i}`}>
                <TextInput
                  key={`l${i}`}
                  value={d.label}
                  placeholder={`Enter value ${i+1}`}
                  onChange={(val)=>{updateOption( i, {...d, label: val} )}}
                />
                <TextInput
                  key={`v${i}`}
                  value={d.value}
                  placeholder={`Enter matching value ${i+1}`}
                  onChange={(val)=>{updateOption( i, {...d, value: val } )}}
                />
              </Flex>
            )
          } )

        }

      </View>
  )
}
