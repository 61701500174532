import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { VegaEditorContext } from '../../../../VegaEditorContext'

import { ColorCategorical } from './ColorCategorical'
import { ConfigField } from './ConfigField'

export const StacksConfig = ( { label, prop, validDataset, defaultProps } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const myConfig = config[prop] ? config[prop] : defaultProps[prop] ? defaultProps[prop] : {}
  const colorScheme = myConfig.scheme ? myConfig.scheme : ''

  function updateStacks( update ) {
    let newConfig = { ...myConfig, ...update }

    updateConfig( { [prop]: newConfig } )
  }

  return (
    <ConfigField
      label={label}
      fieldProp="field"
      field={myConfig.field ? myConfig.field : null}
      accept={['C']}
      onDrop={updateStacks}
      validDataset={validDataset}
    >

      <ColorCategorical
        marginTop="xs"
        label="Color"
        value={colorScheme}
        onUpdate={( clr ) => { updateStacks( { scheme: clr } ) }}
      />
    </ConfigField>
  )
}


StacksConfig.propTypes = {
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired
}
