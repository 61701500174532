import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import clone from "clone"
import { Button, Flex, Loader, View } from "@aws-amplify/ui-react";
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import { ChartDisplay } from "../ChartDisplay"

import { ConfigPanel, DataPanel, defaultConfig } from "./components"
import { VegaEditorContext } from "./VegaEditorContext"

require( "./VegaEditor.scss" )

export const VegaEditor = ( { chart, datasets, onClose, onSave } ) => {
  const [savingChanges, setSavingChanges] = useState( false );
  const [editConfig, setEditConfig] = useState( chart.spec ? clone( chart.spec ) : {} );
  const [editChartDatasets, setEditChartDatasets] = useState( Array.isArray( chart.datasets ) ? chart.datasets.slice() : []);
  const [editDatasets, setEditDatasets] = useState( datasets ? { ...datasets } : {} );

  function saveChart() {

    // Indicate we are saving the changes
    setSavingChanges( true );

    // Update both the chart spec and datasets for the chart.
    onSave( { chart: { spec: editConfig, datasets: editChartDatasets }, datasets: editDatasets } );
  }

  useEffect( () => {
    if ( savingChanges ) {
      // We are currently saving changes and the thumbnail has been saved so
      // go ahead and close the dialog.
      setSavingChanges( false );
      onClose();
    }

  }, [savingChanges] );

  // Update a property in the chart configuration
  function onConfigUpdate( updates ) {

    if ( updates ) {
      let newConfig;

      if ( updates.type && updates.type !== editConfig.type ) {

        // We are changing the type so remove the current configuration and
        // use only the updates as a starting point.
        const defCfg = defaultConfig[updates.type] ? defaultConfig[updates.type] : {};

        newConfig = { ...defCfg, ...updates };
      } else {

        // If we aren't chaning the type then clone the existing configuration.
        newConfig = { ...editConfig, ...updates };
      }

      setEditConfig( newConfig, updates );
    }
  }

    // Update the datasets
  function onChartDatasetUpdate( update ) {

    // We have an array of updates so proceess each one.
    if ( Array.isArray( update ) ) {

      // Update the data for display
      setEditChartDatasets( update );
    }
  }

  // Update the datasets
  function onDatasetUpdate( update ) {

    // Replace the dataset in our current set.
    if ( update ) {
      const newDatasets = { ...update }

      // Update the data for display
      setEditDatasets( newDatasets );
    }
  }

  const veContext = {
    config: clone( editConfig ),
    updateConfig: onConfigUpdate,
    chartDatasets: editChartDatasets,
    updateChartData: onChartDatasetUpdate,
    data: editDatasets,
    updateData: onDatasetUpdate
  };

  let chartData = editChartDatasets.reduce( ( obj, d ) => {
    if ( editDatasets[d] ) {
      obj[d] = editDatasets[d];
    }
    return obj
  }, {} );

  return (
    <View className="ved">

      {
        savingChanges ? (
          <Loader className="rdloader" ariaLabel="Saving..."/>
        ) : (
          <DndProvider backend={HTML5Backend}>
            <VegaEditorContext.Provider value={veContext}>
              <Flex justifyContent="flex-end" marginBottom="small" marginRight="small">
                <Button onClick={onClose}>
                  Cancel
                </Button>

                <Button variation="primary" onClick={saveChart}>
                  Save
                </Button>
              </Flex>

              {
                editChartDatasets.length === 0 ? (
                  <DataPanel />
                ) : (
                  <View className="ved-wksp">
                    <View width="300px">
                      <DataPanel />
                    </View>

                    <View width="300px" >
                      <ConfigPanel />
                    </View>

                    <ChartDisplay
                      chartData={chartData}
                      chartConfig={editConfig}
                    />

                  </View>
                )
              }
                
            </VegaEditorContext.Provider>
          </DndProvider>
        )
      }
    </View>
  )
}

VegaEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  chartData: PropTypes.array,
  chartConfig: PropTypes.object
}
