import React from 'react';

require( './Grid.scss' );

export interface Props {
  size: number;
  step?: number;
  onSizeChange(size: number): void;
}

export function Grid({size}: Props) {
  return (
    <div
      className={'dnd-grid'}
      style={
        {
          '--grid-size': `${size}px`,
        } as React.CSSProperties
      }
    />
  );
}
