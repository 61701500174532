import { getNodeValue } from '../utils'

export function rehydrateObject( obj, kPath, vPaths, values, lookup ) {

  if ( Array.isArray( values ) ) {
    values.forEach( d => {
      let prop = getValue( kPath, d[kPath], lookup )
      let propVal

      if ( Array.isArray( vPaths ) ) {

        // Array of possible path values so we need to search until we find one
        propVal = vPaths.reduce( ( val, vp ) => {
          if ( val === null ) {
            if ( Object.keys( d ).length > 2 ) {
              // We need to build out an Object
              val = {}
            }
          }

          let pVal = getValue( vp, d[vp], lookup )

          if ( vp && pVal !== undefined ) {
            if ( val && typeof val === 'object' ) {
              val[vp] = pVal
            } else {
              val = pVal
            }
          }

          if ( Array.isArray( val ) ) {
            // We have encountered an array so process that separately.
            val = val.map( e => {
              return getValue( vp, e, lookup )
            } )
          }

          return val
        }, null )
      } else {

        // Single value path provided so just get it if it exists
        propVal = getValue( vPath, d[vPath], lookup )
      }

      if ( prop && propVal !== undefined ) {
        obj[prop] = propVal
      }
    } )
  } else if ( typeof values === 'object' ) {

    // Iterate through each of the attributes and copy or rehydrate depending
    // on the type
    obj = Object.keys( values ).reduce( ( acum, d ) => {
      if ( Array.isArray( values[d] ) ) {
        rehydrateObject( acum, kPath, vPaths, values[d], lookup )
      } else {
        acum[d] = values[d]
      }
      return acum
    }, obj )
  }

}

export function getValue( key, value, lookups ) {
  let lookupValue = value

  if ( key && value && lookups ) {
    if ( lookups[key] && lookups[key][value] !== undefined ) {
      lookupValue = lookups[key][value]
    }
  }

  return lookupValue
}

export function buildLookup( data, path ) {
  return data.reduce( ( lkup, d ) => {

    let val = getNodeValue( d, path )

    if ( val && lkup[val] === undefined ) {
      lkup[val] = Object.keys( lkup ).length
    }

    return lkup
  }, {} )
}

// to rehydrate data of the format ->  name: [{a: [{k: ####, v: ###}]}]
// TODO - should incorporate into rehydrate
export function rehydrateArrayOfArrays ( keyNm, kPath, vPaths, values, lookup ) {
  return values.reduce( ( acum, d ) => {
    let newobj = {}
    rehydrateObject( newobj, kPath, vPaths, d[keyNm], lookup )
    acum.push( newobj )
    return acum
  },[] )
}
