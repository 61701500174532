import React, { useEffect, useState } from "react";
import { Column, SortDirection, Table } from "react-virtualized";
import Draggable from "react-draggable";

import "react-virtualized/styles.css";

export const SelectTable = ( { recs, columns, onSelect, height, width, sortBy, sortDirection, onSort, rowClassname } ) => {
  const [colWidths, setColWidths] = useState([]);

  useEffect( () => {

    if ( columns.length ) {
      let newWidths = columns.reduce( ( obj, d ) => {
        obj[d.loc] = 1 / columns.length;
        return obj;
      }, {} )

      setColWidths( newWidths );
    }

  }, [columns, width]);

  const resizeRow = ({ dataKey, deltaX }) => {

    const percentDelta = deltaX / width;

    const dkIdx = columns.findIndex( d => {
      return dataKey === d.loc;
    } )

    if ( dkIdx >= 0 ) {
      const nextDataKey = columns[dkIdx+1].loc;

      const newWidths = {
        ...colWidths,
        [dataKey]: colWidths[dataKey] + percentDelta,
        [nextDataKey]: colWidths[nextDataKey] - percentDelta
      }

      setColWidths( newWidths );
    }
    
  };

  const headerRenderer = ({
    columnData,
    dataKey,
    disableSort,
    label,
    sortBy,
    sortDirection
  }) => {
    return (
      <React.Fragment key={dataKey}>
        <div className="ReactVirtualized__Table__headerTruncatedText">
          {label}
        </div>
        <Draggable
          axis="x"
          defaultClassName="DragHandle"
          defaultClassNameDragging="DragHandleActive"
          onDrag={(event, { deltaX }) =>
            resizeRow({
              dataKey,
              deltaX
            })
          }
          position={{ x: 0 }}
          zIndex={999}
        >
          <span className="DragHandleIcon">⋮</span>
        </Draggable>
      </React.Fragment>
    );
  };

  function onSortRequest( props ) {
    onSort && onSort( props )
  }

  function onRowSelect( { rowData } ) {
    onSelect && onSelect( rowData );
  }

  return recs && recs.length ? (
    <Table
      width={width}
      height={height}
      headerHeight={20}
      rowHeight={30}
      rowCount={recs.length}
      rowGetter={({ index }) => recs[index]}
      onRowClick={onRowSelect}
      sort={onSortRequest}
      sortBy={sortBy}
      sortDirection={sortDirection}
      rowClassName={rowClassname}
    >
      {
        columns.map( ( d, i ) => {
          return colWidths[d.name] ? (
            <Column
              key={d.loc}
              headerRenderer={d < columns.length ? headerRenderer : undefined}
              dataKey={d.loc}
              label={d.name}
              width={colWidths[d.name] * width}
            />
          ) : null;
        })
      }

    </Table>
  ) : null;

}
