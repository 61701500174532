import React, {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import classNames from 'classnames';
import {Flex, View} from '@aws-amplify/ui-react';
import { format } from "d3-format"
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  Modifiers,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {CSS} from '@dnd-kit/utilities';
import { MouseSensor, TouchSensor } from '../common'
import {Item, Container, ContainerProps, SortableItemData} from '../../../dnd';

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({...args, wasDragging: true});

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: SortableItemData[];
  style?: React.CSSProperties;
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
      ( items.findIndex( (item) => item.id === over.id)  >= 0 )
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      listStyle={{
        gridGap: '20px'
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

export type PartitionItems = Record<UniqueIdentifier, SortableItemData[]>;
export type ContainerLabels = {
  initial: string;
  higher: string;
  lower: string;
};

interface Props {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;
  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;
  wrapperStyle?(args: {index: number}): React.CSSProperties;
  items?: PartitionItems;
  handle?: boolean;
  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  minimal?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
  onChange?( items:PartitionItems): void;
  onPlacement?( items:PartitionItems): PartitionItems;
  topLabel?: string;
  bottomLabel?: string;
  valueFormat?: string;
}

export function OrderEmContainers({
  adjustScale = false,
  cancelDrop,
  columns,
  handle = false,
  items: initialItems,
  containerStyle,
  coordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers = [restrictToVerticalAxis, restrictToWindowEdges],
  renderItem,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  onChange,
  onPlacement,
  topLabel = 'Before',
  bottomLabel = 'After',
  valueFormat
}: Props) {
  const [items, setItems] = useState<PartitionItems>(
    () =>
      initialItems ?? {}
  );
  const [containers, setContainers] = useState(
    Object.keys(items) as UniqueIdentifier[]
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);

  const placingItem = Object.keys( items ).reduce( ( placing, type ) => {
    if ( !placing ) {
      placing = items[type].findIndex( d => {
        return d.data.status === 'placing'
      } ) >= 0;
    }
    return placing
  }, false )

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  ( containerItems.findIndex( (item) => item.id === container.id)  >= 0 )
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{id: overId}];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{id: lastOverId.current}] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<PartitionItems | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].findIndex( (item) => item.id === id) >= 0 );
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = items[container].findIndex( (item) => item.id === id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  const itemPlaced = ( event ) => {
    setItems( onPlacement( items ) );
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({active}) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({active, over}) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          function updateItems( items ) {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.findIndex( (item) => item.id === overId);
            const activeIndex = activeItems.findIndex( (item) => item.id === active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                  over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item.id !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            };
          }

          const newItems = updateItems(items);
          setItems( newItems );
          onChange && onChange( newItems )
        }
      }}
      onDragEnd={({active, over}) => {
        if (active.id in items && over?.id) {
          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].findIndex( (item) => item.id === active.id);
          const overIndex = items[overContainer].findIndex( (item) => item.id === overId);

          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex
              ),
            }));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <Flex direction="column" width="100%" alignItems="center" justifyContent="stretch" gap="0px">
        <View grow={0} shrink={0} width="90%">
          <SortableContext
              items={[...containers]}
              strategy={
                vertical
                  ? verticalListSortingStrategy
                  : horizontalListSortingStrategy
              }
            >
              <DroppableContainer
                key="placing"
                id="placing"
                columns={columns}
                items={items.placing}
                scrollable={scrollable}
                style={{minHeight: placingItem ? '75px' : '0px'}}
                unstyled={true}
              >

                <SortableContext items={items.placing} strategy={strategy}>
                  {
                    items.placing.map((value, index) => {
                      return (
                        <SortableItem
                          key={index}
                          disabled={value.data.status !== 'placing'}
                          id={value.id}
                          index={0}
                          style={getItemStyles}
                          wrapperStyle={wrapperStyle}
                          containerId="placing"
                          getIndex={getIndex}
                          value={value}
                          itemPlaced={itemPlaced}
                        />
                    
                      )
                    })
                  }
                </SortableContext>
                
              </DroppableContainer>
            </SortableContext>
        </View>
        <Flex
          grow={1} shrink={1}
          direction="column"
          gap="0px"
          width="90%"
          style={{
            // display: 'inline-grid',
            // boxSizing: 'border-box',
            // padding: 20,
            // gridAutoFlow: vertical ? 'row' : 'column',

          }}
        >
          <SortableContext
            items={[...containers]}
            strategy={
              vertical
                ? verticalListSortingStrategy
                : horizontalListSortingStrategy
            }
          >

              <DroppableContainer
                key="placed"
                id="placed"
                columns={columns}
                items={items.placed}
                scrollable={scrollable}
                style={{minHeight: '60%'}}
                unstyled={false}
              >
                <View className="oe-label">{topLabel}</View>
                <div className="oe-connector" />

                <SortableContext items={items.placed} strategy={strategy}>
                  {items.placed.map((value, index) => {

                    return (
                      <SortableItem
                        key={index}
                        id={value.id}
                        disabled={value.data.status !== 'placing' }
                        index={index}
                        style={getItemStyles}
                        wrapperStyle={wrapperStyle}
                        containerId="placed"
                        getIndex={getIndex}
                        value={value}
                        itemPlaced={itemPlaced}
                        valueFormat={valueFormat}
                      />
                    );
                  })}
                </SortableContext>

                <View className="oe-label">{bottomLabel}</View>
              </DroppableContainer>

          </SortableContext>
        </Flex>
      </Flex>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId ? renderSortableItemDragOverlay(activeId) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    let containerId = findContainer(id) as UniqueIdentifier;
    let index = getIndex(id);
    let item = items[containerId][index]
    return (
      <Item
        value={id}
        style={getItemStyles({
          containerId: findContainer(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({index: 0})}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }
}

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  disabled?: boolean;
  style(args: any): React.CSSProperties;
  getIndex(id: UniqueIdentifier): number;
  wrapperStyle({index}: {index: number}): React.CSSProperties;
  value: SortableItemData;
  valueFormat?: string;
  itemPlaced?( event: any): void;
}

function SortableItem({
  disabled,
  index,
  style,
  containerId,
  id,
  getIndex,
  wrapperStyle,
  value,
  valueFormat,
  itemPlaced
}: SortableItemProps) {
  const {
    setNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id: value.id,
    disabled
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <Item
      ref={setNodeRef}
      disabled={disabled}
      value={value.id}
      dragging={isDragging}
      sorting={isSorting}
      index={index}
      wrapperStyle={typeof wrapperStyle === 'function' ? wrapperStyle({index}) : wrapperStyle }
      style={style({
        index,
        value: value.id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={(props) => { return renderOrderItem( { ...props, item: value, containerId, disabled, itemPlaced, valueFormat } )}}
    />
  );
}

function renderOrderItem({
  dragOverlay,
  dragging,
  sorting,
  index,
  fadeIn,
  listeners,
  ref,
  style,
  transform,
  transition,
  value,
  item,
  disabled,
  containerId,
  itemPlaced,
  valueFormat,
  ...props
}) {
  // let disabled = value.status !== 'placing';
  let className = '';

  let liStyle = {
    // ...wrapperStyle,
    // transition: [transition, wrapperStyle?.transition]
    transition: [transition]
      .filter(Boolean)
      .join(', '),
    '--translate-x': transform
      ? `${Math.round(transform.x)}px`
      : undefined,
    '--translate-y': transform
      ? `${Math.round(transform.y)}px`
      : undefined,
    '--scale-x': transform?.scaleX
      ? `${transform.scaleX}`
      : undefined,
    '--scale-y': transform?.scaleY
      ? `${transform.scaleY}`
      : undefined,
    '--index': index,
  }

  if ( item.data.status !== 'placing' ) {
    style.cursor = 'default';
  }

  const readyToPlace = item.data.status === 'placing' && containerId === 'placed' && !dragging
  let statusClassnames = 'oe-dragitem-status'
  let status = ''
  let whereString = ''

  if ( item.data.status === 'initial' ) {
    status = `${valueFormat ? format( valueFormat )( item.data.value ) : item.data.value}`
    statusClassnames = classNames( 'oe-dragitem-status oe-dragitem-status-initial' )
  } else if ( item.data.status === 'placed' ) {
    status = `${valueFormat ? format( valueFormat )( item.data.value ) : item.data.value}`
    statusClassnames = classNames( 'oe-dragitem-status', item.data.correct ? 'game-ak-success' : 'game-ak-error' )
  }

  if ( item.data.status === 'placed' && !item.data.correct ) {
    whereString = `You placed this ${item.data.dir} ${item.data.refNodeLabel}`
  }

  return (
  <li
    className={classNames(
      'oe-dragitem-wrapper',
      fadeIn && 'oe-dragitem-wrapper-fadein',
      sorting && 'oe-dragitem-sorting',
      dragOverlay && 'oe-dragitem-wrapper-dragoverlay'
    )}
    style={liStyle}
    ref={ref}
  >
    <View  width="100%">
      <div
        className={classNames(
          disabled && 'oe-dragitem-disabled',
          'oe-dragitem oe-dragitem-dragging',
          dragging && 'oe-dragitem-dragging',
          ( !dragging || dragOverlay ) && 'dragOverlay',
          // handle && 'withhandle',
          dragOverlay && 'oe-dragitem-wrapper-dragoverlay',
          className
        )}
        style={style}
        data-cypress="draggable-item"
        {...listeners}
        {...props}
        tabIndex={undefined}
      >
        {
          status ? (
            <div
              className={statusClassnames}
              onClick={itemPlaced}
            >
              {status}
            </div>
          ) : null
        }

        <div>
          {item.label}
        </div>

        {
          readyToPlace ? (
            <div
              className="oe-dragitem-ttp"
              onClick={itemPlaced}
              data-no-dnd
            >
              Tap to place
            </div>
          ) : null
        }

      </div>

      {
        whereString ? (
          <div
            className="oe-dragitem-msg game-ak-error-text"
            onClick={itemPlaced}
            data-no-dnd
          >
            {whereString}
          </div>
        ) : null
      }
    </View>
  </li>
);
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
