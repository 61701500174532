import classNames from 'classnames';
import { Flex, Grid, View } from '@aws-amplify/ui-react'

require( './ConnectFourCategory.scss' )

export const ConnectFourProgress = ( { answers, numBins, checkedAnswer } ) => { 
    const dir = checkedAnswer && checkedAnswer.dir ? checkedAnswer.dir : '';
    const val = checkedAnswer && checkedAnswer.val !== undefined ? checkedAnswer.val : -1;
       
    return (
        <View >

            <Grid gap="0px" templateColumns="1fr 1fr 1fr 1fr" width="100%">
                {
                    Array(numBins).fill('').map( ( rdata, col ) => {

                        return (
                            <Flex
                                key={`bin${col}`}
                                grow={1} shrink={1}
                                direction="column"
                                gap="10px"
                            >

                                {
                                    Array(numBins).fill('').map( ( cdata, row ) => {
                                        let color = answers[ col * numBins + ( numBins - row ) - 1 ];
                                        let colorClass = color === 1 ? 'game-ak-success' : color === -1 ? 'game-ak-error' : ''
                                        let answerClass = '';

                                        if ( checkedAnswer.correct !== undefined ) {

                                            if ( !dir || ( dir === 'h' && ( numBins - row - 1 ) !== val ) || ( dir === 'v' && col !== val ) ) {
                                                answerClass = 'cf-prg-token-notanswer';
                                            }
                                        }

                                        let tokClasses = classNames( 'cf-prg-token', colorClass, answerClass );

                                        return ( <div key={row} className={tokClasses} /> );
                                    })
                                }
                            </Flex>
                        )
                    })
                }

            </Grid>
            
        </View>
    )
}