import { Flex, Heading, View } from "@aws-amplify/ui-react";

export const Masthead = () => {
    return (
        <Flex
            backgroundColor="#4c4d4e"
            padding="30px"
            alignItems="center"
            justifyContent="center"
            wrap="wrap"
            gap="30px"
        >
            <View>
                <Heading color="font.inverse" level={1} fontWeight="medium" padding="0 20px" textAlign="center">
                    Perspectives
                </Heading>
            </View>

            <View color="font.inverse" fontSize={24} paddingLeft="20px" textAlign="center" maxWidth={["180px", "400px"]}>
                How well do you know the country outside your bubble?
            </View>
        </Flex>
    )
}