import { Sortable } from '../../../../../dnd';
import { Panel } from '../Panel';

export const OrderOptions = ( { options, onUpdate } ) => {
    
    const items = options.map( d => {
        let item = {
            id: d.label,
            label: `${d.label} - ${d.value}`,
            data: d.value
        }

        if ( d.category ) {
            item.label = `${d.category} - ${d.label} - ${d.value}`
            item.data = {
                value: d.value,
                cat: d.category
            }
        }

        return item;
    } )

    function onPlace( update ) {
        // Create the new options array.
        const newOptions = update.map( d => {
            let newItem = {
                label: d.id
            }

            if ( d.data.value ) {
                newItem.value = d.data.value;
                newItem.category = d.data.cat;
            } else {
                newItem.value = d.data;
            }

            return newItem;
        });

        onUpdate && onUpdate( newOptions );
    }
      
    return options.length > 0 ? (
        <Panel label="Order Selected Data Items">
            <Sortable
                items={items}
                vertical
                onChange={onPlace}
            />
        </Panel>

    ) : null
  }