
// Generate the tooltip specification from the configuration
export function genTooltip( ttconfig, from ) {
  let labels = {}
  let tt

  if ( Array.isArray( ttconfig ) && ttconfig.length ) {
    from = from ? from : 'datum'

    let fldspecs = ttconfig.map( ( d ) => {
      let label = d.label && d.label.title ? d.label.title : d.field.name ? d.field.name : d.field.loc
      let value = d.format ? `format( ${from}.${d.field.loc}, '${d.format}')` : `${from}.${d.field.loc}`

      // Check to see if we've used this label before.
      let count = 1
      let baseLabel = label

      while( labels[label] ) {
        label = `${baseLabel} (${count})`
      }

      // Keep track of the labels that have been used so we don't duplicate'
      labels[label] = true

      return `'${label}': ${value}`
    } )

    tt = `{ ${ fldspecs.join() }}`
  }

  return tt
}
