import { Button } from '@aws-amplify/ui-react';

export const DoneButton = ( { isDisabled, checkAnswer }) => {

    return (
        <Button
            isDisabled={isDisabled}
            onClick={checkAnswer}
            marginTop="large"
        >
            Did I get it?
        </Button>
    )
}