import { useEffect, useReducer, useRef } from "react";
import { generateClient } from 'aws-amplify/api';
import { useAuthenticator } from "@aws-amplify/ui-react";

import { getUserStats } from '../../src/graphql/queries';
import { createUserStats } from '../../src/graphql/mutations';

const dbFields = ["userId", "gameId", "category", "gameType", "correct", "answer"];
const stateFields = ["_loaded", "_fetching", "_storing", "_error"];

export const useUserStats = ( gameId ) => {
  const { user, authStatus } = useAuthenticator((context) => [context.user]);

  const [userStats, setUserStats] = useReducer(
    ( state, update ) => {
      const newState = { ...state, ...update }

      // Get the current user to ensure it's the same as the author.
      // If it's not then copy the game for the new user.

      if ( state._loaded ) {
        let fldCnt = Object.keys( newState ).reduce( ( cnt, d ) => {

          if ( newState[d] !== undefined && !stateFields.includes( d ) ) {

            if ( newState[d] !== state[d] ) {
              cnt++
            }
          }
          return cnt
        }, 0 )

        // Check to see if there have been any changes, if so,
        // perform the update.

        if ( user && fldCnt > 0 ) {

          // Add in the required fields before creating the record.
          newState.userId = user.username;
          newState.gameId = gameId;
          createUserStat( newState );
        }
      }

      return newState
    }, {
      _loaded: false,
      _fetching: false,
      _storing: false,
      _error: ''
    } )

  useEffect(() => {
    if ( gameId && gameId !== prevGameId.current && authStatus === 'authenticated') {
      setUserStats( { _loaded: false, _fetching: true } )

        const client = generateClient();

        client.graphql({
              query: getUserStats,
              variables: {
                userId: user.username,
                gameId: gameId
              }
          })
          .then( response => {

              let newStats = response.data.getUserStats ? response.data.getUserStats : getEmptyState( userStats );

              // Set the rest of the status values.
              setUserStats( { ...newStats, _loaded: true, _fetching: false, _error: '' } );
          })
          .catch( error => {
            if ( error.name === "NoSuchKey" ) {
              // No key was found so we need to remove any keys that aren't part of our state.
              let emptyUpdate = getEmptyState( userStats );

              // Then update the user stats.
              setUserStats( { _loaded: true, _fetching: false, _error: '', ...emptyUpdate } );
            } else {

              setUserStats( { _loaded: true, _fetching: false, _error: error } );
            }
          })
      }

  }, [gameId]);

  function getEmptyState( curState ) {
    return Object.keys( curState ).reduce( ( obj, d ) => {
      obj[d] = null;
      return obj
    }, {} )
  }

  const prevGameId = useRef()
  useEffect( () => {
    prevGameId.current = gameId
  } )

  async function createUserStat( game ) {

    if ( game && game.gameId && authStatus === 'authenticated') {

      // Set the userId before creating the record.
      game.userId = user.username;

      // Create the database record from the game.
      const dbRec = createDbRec( game );

      const client = generateClient();

      client.graphql({
          query: getUserStats,
          variables: {
            userId: user.username,
            gameId: gameId
          }
      })
        .catch( error => {
          console.log( 'DB ERROR:', error )
        })
    }
  }

  function createDbRec( game ) {
    let dbRec = {};

    if ( game && game.gameId ) {
      dbRec = dbFields.reduce( ( obj, d ) => {
        if ( game[d] !== undefined ) {
          obj[d] = game[d]
        }
        return obj
      }, {} );
    }

    return dbRec
  }

  return [userStats, setUserStats];
};

