import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Flex, View } from "@aws-amplify/ui-react";
import { Select } from '../../../../../../Controls'
import { SliderInput } from './SliderInput'

const colorSchemes = [
  'blues','tealblues','teals','greens','browns','oranges','reds','purples','warmgreys','greys',
  'viridis','magma','inferno','plasma','cividis','turbo','bluegreen','bluepurple','goldgreen','goldorange','goldred','greenblue','orangered',
  'purplebluegreen','purpleblue','purplered','redpurple','yellowgreenblue','yellowgreen','yelloworangebrown','yelloworangered',
  'darkblue','darkgold','darkgreen','darkmulti','darkred',
  'lightgreyred','lightgreyteal','lightmulti','lightorange','lighttealblue',
  'blueorange','brownbluegreen','purplegreen','pinkyellowgreen','purpleorange','redblue','redgrey','redyellowblue','redyellowgreen','spectral',
  'rainbow','sinebow'
]

export const ColorScheme = ( { className, label, value, onUpdate } ) => {

  let wrapperClasses = classNames( 'cp-ctl', className )

  function updateScheme( scheme ) {
    let newColor = { ...value, scheme: scheme }
    onUpdate( newColor )
  }

  function updateBins( bins ) {
    let newColor = { ...value, bins: bins }
    onUpdate( newColor )
  }

  return (
    <Flex direction="column" paddingRight="small" paddingBottom="xs" className={wrapperClasses} gap="0px">
      {
        label && (
          <View className="cp-ctl-lbl" >
            {label}
          </View>
        )
      }

      <Flex direction="column" paddingLeft="small">
        <Flex alignItems="center">

          <View className="cp-ctl-lbl" >
            Scheme
          </View>

          <Select
            size="small"
            options={colorSchemes}
            onChange={( event ) => { updateScheme( event.target.value ) }}
            value={value.scheme ? value.scheme : ''}
            placeholder="Select color scheme ..."
          />
        </Flex>

        <SliderInput
          label="Bins"
          min={2}
          max={13}
          step={1}
          marks={[3,5,7,9,11]}
          markFormat=".0f"
          value={value.bins ? value.bins : 8}
          onUpdate={updateBins}
        />
      </Flex>

    </Flex>
  )
}

ColorScheme.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.object,
  label: PropTypes.string,
}
