/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      gameId
      author
      title
      category
      thumbnail
      published
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      gameId
      author
      title
      category
      thumbnail
      published
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      gameId
      author
      title
      category
      thumbnail
      published
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserStats = /* GraphQL */ `
  mutation CreateUserStats(
    $input: CreateUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    createUserStats(input: $input, condition: $condition) {
      userId
      gameId
      category
      gameType
      correct
      answer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserStats = /* GraphQL */ `
  mutation UpdateUserStats(
    $input: UpdateUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    updateUserStats(input: $input, condition: $condition) {
      userId
      gameId
      category
      gameType
      correct
      answer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserStats = /* GraphQL */ `
  mutation DeleteUserStats(
    $input: DeleteUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    deleteUserStats(input: $input, condition: $condition) {
      userId
      gameId
      category
      gameType
      correct
      answer
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGameStats = /* GraphQL */ `
  mutation CreateGameStats(
    $input: CreateGameStatsInput!
    $condition: ModelGameStatsConditionInput
  ) {
    createGameStats(input: $input, condition: $condition) {
      gameId
      category
      correct
      incorrect
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGameStats = /* GraphQL */ `
  mutation UpdateGameStats(
    $input: UpdateGameStatsInput!
    $condition: ModelGameStatsConditionInput
  ) {
    updateGameStats(input: $input, condition: $condition) {
      gameId
      category
      correct
      incorrect
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGameStats = /* GraphQL */ `
  mutation DeleteGameStats(
    $input: DeleteGameStatsInput!
    $condition: ModelGameStatsConditionInput
  ) {
    deleteGameStats(input: $input, condition: $condition) {
      gameId
      category
      correct
      incorrect
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPublishedGames = /* GraphQL */ `
  mutation CreatePublishedGames(
    $input: CreatePublishedGamesInput!
    $condition: ModelPublishedGamesConditionInput
  ) {
    createPublishedGames(input: $input, condition: $condition) {
      type
      published
      gameId
      title
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePublishedGames = /* GraphQL */ `
  mutation UpdatePublishedGames(
    $input: UpdatePublishedGamesInput!
    $condition: ModelPublishedGamesConditionInput
  ) {
    updatePublishedGames(input: $input, condition: $condition) {
      type
      published
      gameId
      title
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePublishedGames = /* GraphQL */ `
  mutation DeletePublishedGames(
    $input: DeletePublishedGamesInput!
    $condition: ModelPublishedGamesConditionInput
  ) {
    deletePublishedGames(input: $input, condition: $condition) {
      type
      published
      gameId
      title
      category
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
