import { genTooltip } from './tooltip'
import { addColorScale } from './colorscale'
import { getConfigField, makeDisplayable } from './utils'

export function ushexstates( config, data ) {
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    usermeta: {embedOptions: {renderer: "svg"}},
    description: 'A configurator generated US State hex cartogram specification.',
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    // height: 300,
    height: window.innerWidth > 768 ? 300 : 450,
    padding: 5,

    data: [],
    axes: [],
    legends: [],
    scales: [],
    marks: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      }
    ],

    projections: [
      {
        name: "projection",
        type: "mercator",
        fit: {signal: "data('hexstatesgeojson')"},
        size: {signal: "[width, height]"}
      }
    ],
  
  }

  let hexData = {
    name: "hexstatesgeojson",
    url: "https://datasets.ripedata.com/us_states_hexgrid.json",
    transform: [
      {
        type: "geojson",
        fields: ["longitude", "latitude"],
      }

    ]
  }
  
  let labelData = {
    name: "labelprops",
    source: "hexstatesgeojson",
    transform: [
      {
        type: "formula",
        as: "centroid",
        expr: "geoCentroid('projection', datum)"
      },
      {
        type: "formula",
        as: "bounds", 
        expr: "geoBounds('projection', datum)"
      },
              {
        type: "formula",
        as: "size", 
        expr: "( datum.bounds[1][1] - datum.bounds[0][1] ) * 0.25"
      }
    ]
  }

  const hoverColor = config.color && config.color.hover && config.color.hover.fill ? config.color.hover.fill : 'red'

  let hexMark = {
    type: "shape",
    from: { data: "hexstatesgeojson"},
    encode: {
      update: { fill: { value: "#5ba3cf" }, stroke: { value: 'white' }, strokeWidth: { value: 1 } },
      hover: { fill: { value: hoverColor }, stroke: { value: 'white' } }
    },
    transform: [
      { type: "geoshape", projection: "projection" }
    ]
  }

  const labelColor = config.color && config.color.label && config.color.label.fill ? config.color.label.fill : 'white'
  
  let labelMark = {
    type: "text",
    interactive: false,
    from: { "data": "labelprops" },
    encode: {
      enter: {
        align: {  value: "center" },
        baseline: { value: "middle" },
        fontSize: { field: "size"},
        text: { field: "properties.iso3166_2" },
        fill: { value: labelColor }
      },
      update: {
        x: { field: "centroid[0]" },
        y: { field: "centroid[1]" }
      }
    }
  }

  let sField = getConfigField( config.s )
  let vField = getConfigField( config.v )

  if ( sField && vField ) {

    // Add the source data to the hex data.
    spec.data.push( {
      name: sField.datasetId,
      transform: []
    } )

    // Add the augmenting data into the hex states.
    hexData.transform.push({
      type: 'lookup',
      from: sField.datasetId,
      key: sField.loc,
      fields: ['properties.name'],
      as: ['data'],
      default: {}
    })

    // Filter the states?
    // hexData.transform.push( { "type": "filter", "expr": `datum.data.${vField.loc} !== undefined` })

    // Create the color scale based on the specified value.
    const statesColor = config.v.color && config.v.color.scheme ? config.v.color.scheme : 'warmgreys'
    const statesBins = config.v.color && config.v.color.bins ? config.v.color.bins : 7
    const legendLabel = config.v.label && config.v.label.title ? config.v.label.title : makeDisplayable( vField.name ? vField.name : vField.loc )

    let colorScale = {
      name: "color",
      type: "quantize",
      domain: [vField.min, vField.max],
      range: {scheme: statesColor, count: statesBins}
    }
    spec.scales.push( colorScale )

    // Then the color legend.
    let colorLegend = {
      fill: "color",
      orient: window.innerWidth > 768 ? 'bottom' : 'bottom',
      direction: window.innerWidth > 768 ? 'horizontal' : 'horizontal',
      title: legendLabel,
      format: config.v.format ? config.v.format : 's'
    }
    spec.legends.push( colorLegend )

    // Now use the color scale to fill the hexograms.
    hexMark.encode.update.fill = { scale: "color", field: `data.${vField.loc}`}

    // and the tooltip
    let tt = [ config.s, config.v ]

    if ( Array.isArray( config.tt ) ) {
      // If the user specified some tooltip fields then add them here.
      tt = tt.concat( config.tt )
    }

    hexMark.encode.update.tooltip = {signal: genTooltip( tt, 'datum.data' )}
  }

  spec.data.push( hexData )
  spec.data.push( labelData )

  spec.marks.push( hexMark )
  spec.marks.push( labelMark )

  if ( config.hlf ) {
    let hlfField = getConfigField( config.hlf )

    if ( hlfField ) {
      // We need to create a filtered dataset to include only those items that are being highlighted.
      const hlDataName = '_HighlightData_'

      let hlData = {
        name: hlDataName,
        source: "hexstatesgeojson",
        transform: []
      }

      // Filter for valid highlight values.
      hlData.transform.push(
        {
          type: 'filter',
          expr: `datum.data.${hlfField.loc}`
        }
      )
      
      // And push the dataset definition into the list of data.
      spec.data.push( hlData )

      // Now we need to create a field definition that points to
      // the filtered dataset.
      let filteredField = hlfField ? { ...hlfField } : { }
      filteredField.datasetId = hlDataName;

      // Remove the empty value if it exists.
      if ( Array.isArray( hlfField.values ) ) {
        filteredField.values = hlfField.values.filter( d => { return d } );
      }

      // then add the color scale.
      addColorScale( spec, filteredField, 'hlf' );

      let fieldHighlight = {
        type: "shape",
        from: { data: hlDataName},
        encode: {
          update: { stroke: { scale: 'hlf', field: `data.${filteredField.loc}` }, strokeWidth: { value: 2 } }
        },
        transform: [
          { type: "geoshape", projection: "projection" },
        ]
      }

      spec.marks.push( fieldHighlight );
    }
  }

  if ( Array.isArray( config.highlightStates ) ) {

    // If we have any states that are to be highlighted add the source and update the stroke specification.
    spec.data.push( {
      name: "highlight",
      values: config.highlightStates.map( d => { return { st: d }} )
    } )

    let hexHighlight = {
      type: "shape",
      from: { data: "hexstatesgeojson"},
      encode: {
        update: { stroke: [{ test: "indata('highlight', 'st', datum.properties.iso3166_2)", value: '#f05a28' }], strokeWidth: { value: 2 } },
      },
      transform: [
        { type: "geoshape", projection: "projection" },
      ]
    }

    spec.marks.push( hexHighlight )
  }

  // const tt = [{field: {pos: 'properties.iso3166_2' }, label: 'State'}]

  // spec.marks[0].encode.enter = { tooltip: { signal: genTooltip( tt )} }

  // console.log( 'US HEX STATES:', config, spec )

  return spec
}
