import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { VegaEditorContext } from '../../../../VegaEditorContext'

import { ColorCategorical } from './ColorCategorical'
import { ConfigField } from './ConfigField'
import { Checkbox } from './Checkbox'

export const WedgesConfig = ( { label, prop, validDataset, defaultProps } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  const myConfig = config[prop] ? config[prop] : defaultProps[prop] ? defaultProps[prop] : {}

  function updateWedges( update ) {
    let newConfig = { ...myConfig, ...update }

    updateConfig( { [prop]: newConfig } )
  }

  return (
    <ConfigField
      label={label}
      fieldProp="field"
      field={myConfig.field ? myConfig.field : null}
      accept={['C']}
      onDrop={updateWedges}
      validDataset={validDataset}
    >

      <ColorCategorical
        marginTop="xs"
        label="Scheme"
        value={myConfig.scheme}
        onUpdate={( clr ) => { updateWedges( { scheme: clr } ) }}
      />

      <Checkbox
        className="cp-xs-prp"
        label="Order by size"
        value={!!myConfig.sortBySize}
        onUpdate={( val ) => { updateWedges( { sortBySize: val } ) }}
      />

    </ConfigField>
  )
}


WedgesConfig.propTypes = {
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired
}
