import { useEffect, useState } from "react";
import { generateClient } from 'aws-amplify/api';
import { Loader, View, useAuthenticator } from "@aws-amplify/ui-react";

import { listPublishedGames } from "../../graphql/queries";
import { Masthead, GameWrapper } from "../../components";

export const LandingPage = () => {
    const [gameId, setGameId] = useState( null );
    const { authStatus } = useAuthenticator((context) => [
        context.user
    ]);

    useEffect(() => {
        let clientParams;
        let apiParams = {
            query: listPublishedGames,
            variables: {
                type: 'PUBLISHED',
                sortDirection: 'DESC',
                limit: 1
            }
        }

        if ( authStatus !== 'authenticated' ) {
            apiParams.authMode = 'iam';
            clientParams = { authMode: 'iam' };
        }

        const client = generateClient( clientParams );

        client.graphql(apiParams)
            .then( response => {
                let items = []
                if ( response.data && response.data.listPublishedGames && Array.isArray( response.data.listPublishedGames.items ) ) {
                    items = response.data.listPublishedGames.items
                }

                if ( Array.isArray( items ) && items.length ) {
                    // We have the list (or at least the first item in the list)
                    setGameId( items[0].gameId );
                }         
            })
            .catch( error => {
                console.log( 'ERROR:', error )
            })
    }, [authStatus] );

    return (
        <View>
            <Masthead />

            <View maxWidth="1200px" margin="0 auto">
                {
                    gameId ? (
                        <GameWrapper gameId={gameId} />
                    ) : (
                        <Loader className="rdloader"/>
                    )
                }

            </View>

        </View>
    )
}