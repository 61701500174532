import React, { useContext } from 'react'

import { FIELDTYPE_NUMERIC } from '../../../../../../../hooks'
import { VegaEditorContext } from '../../../VegaEditorContext'

import {
  ColorConfig,
  InteractiveFilter,
  MultiFieldConfig,
  TooltipConfig,
  useValidateFields
} from './components'
import { getDataId } from './configUtils'

import {
  clrDefaultMarkFill,
  clrDefaultMarkStroke,
  clrDefaultHoverFill,
  clrDefaultHoverStroke,
} from './defaultValues'

export const defaultParallelCoordinates = {
  axis: {
    domain: {
      show: true
    },
    grid: {
      show: true
    },
    ticks: {
      show: true
    }
  },
  color: {
    mark: {
      stroke: clrDefaultMarkStroke,
      fill: clrDefaultMarkFill
    },
    hover: {
      stroke: clrDefaultHoverStroke,
      fill: clrDefaultHoverFill
    }
  }
}

const fieldProps = ['mf', 'color', 'tt', 'iflt']

// Assign labels for each of the items we can configure with color.
const colorTypes = [
  {
    val: 'mark',
    label: 'Lines'
  },
  {
    val: 'hover',
    label: 'Hover'
  }
]


export const ParallelCoordinatesConfig = () => {
  const { config, data, updateConfig } = useContext( VegaEditorContext )
  const dataIds = data ? Object.keys( data ) : []
  const curDataId = getDataId( config, fieldProps )

  // console.log( 'PARALLEL COORDINATES CONFIG:', config )

  useValidateFields( fieldProps )

  return (
    <div  className="cp-ctls" >

      <MultiFieldConfig
        prop="pa"
        accept={[FIELDTYPE_NUMERIC]}
        label="Parallel Axis"
        placeholder="Drop axis fields here!"
        validDataset={curDataId}
      />

      <ColorConfig
        isDynamic
        validDataset={curDataId}
        staticTypes={colorTypes}
      />

      <TooltipConfig prop="tt" />

      <InteractiveFilter prop="iflt" />
    </div>
  )
}
//
// ScatterConfig.propTypes = {
//   dataSets: PropTypes.object.isRequired,
//   config: PropTypes.object.isRequired,
//   onUpdate: PropTypes.func.isRequired
// }
