import { useState } from 'react'
import { CheckboxField, Flex, TextField, View } from "@aws-amplify/ui-react";

export const TextOption = ( { value, isAnswer, onSetAnswer, onChange, ...remaining } ) => {
  const [option, setOption] = useState( value ? value : "" )

  function onKeyPress( event ) {
    const keyCode = event.keyCode || event.which

    if ( keyCode === 13 ) {

      // Update the name since a return was encountered.
      onChange( option )

      // Don't process if the pressed key was 'enter'.
      event.preventDefault && event.preventDefault()
    }
  }

  function setAnswer( event ) {
    onSetAnswer( event.target.value )
  }

  return (
    <Flex direction="row" >
      <View width="15%">
        <CheckboxField
          value={option}
          checked={isAnswer}
          onChange={setAnswer}
          margin="auto"
          marginTop="15px"
        />
      </View>
      <TextField shrink={1} grow={1}
        variation="quiet"
        value={option}
        onBlur={( event )=>{onChange( option )}}
        onKeyPress={onKeyPress}
        onChange={( event ) => {setOption( event.target.value )}}
        {...remaining}
      />
    </Flex>
  )
}