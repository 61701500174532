import { getNodes } from './paths'

export function createDimension( cf, path ) {
}

export function createArrayDimension( cf, path ) {
  let dim = cf.dimension( d => {
    let nodes = getNodes( d, path )
    let vals = []

    nodes.forEach( n => {
      vals.push( n.value )
    } )

    // Need to order the values otherwise they won't be reported properly
    return vals.sort()
  }, true )

  return dim
}

// Return a list of items at the location specified by the path.
export function getList( cfDim, path ) {
  let vals = []

  if ( cfDim && path ) {
    vals = Object.keys( cfDim.groupAll().reduce(
      ( obj, v ) => {
        return addToList( obj, path, v )
      },
      ( obj, v ) => {
        return removeFromList( obj, path, v )
      },
      () => {
        return {}
      }
    ).value() ).sort()
  }

  return vals
}

function addToList( obj, p, v ) {
  let nodes = getNodes( v, p )

  if ( nodes && nodes.length > 0 ) {
    nodes.forEach( n => {
      if ( Array.isArray( n.value ) ) {
        n.value.forEach( n2 => {
          addItem( obj, n2 )
        } )
      }
      if ( typeof n.value !== 'object' ) {
        addItem( obj, n.value )
      }
    } )
  }

  return obj
}

function addItem( obj, v ) {
  if ( obj[v] === undefined ) {
    obj[v] = 1
  } else {
    obj[v]++
  }
}


function removeFromList( obj, p, v ) {
  let nodes = getNodes( v, p )

  if ( nodes && nodes.length > 0 ) {
    nodes.forEach( n => {
      if ( Array.isArray( n.value ) ) {
        n.value.forEach( n2 => {
          removeItem( obj, n2 )
        } )
      }
      if ( typeof n.value !== 'object' ) {
        removeItem( obj, n.value )
      }
    } )
  }

  return obj
}

function removeItem( obj, v ) {
  if ( obj[v] !== undefined ) {
    obj[v]--

    if ( obj[v] === 0 ) {
      delete obj[v]
    }
  }
}
