import React, { useContext } from 'react';
import { CheckboxField, Flex, View } from "@aws-amplify/ui-react";

import { GameConfigContext } from '../../GameConfigContext';
import { TextOption } from './TextOption';

const maxOptions = 4;

export const SplitConfig = () => {
  const { config, updateConfig } = useContext( GameConfigContext )
  const options = Array.isArray( config.options ) ? config.options : Array(maxOptions).fill('');

  // If the options array are not filled for some reason, add in the additional items.
  let missingOptions = maxOptions - options.length;
  while ( missingOptions > 0 ) {
    options.push( '' );
    missingOptions--;
  }

  function updateOption( idx, val ) {
    let newOptions = options.slice();
    newOptions[idx] = val;

    updateConfig( { options: newOptions } )
  }

  function setAnswer( val ) {
    updateConfig( { answer: val } )
  }

  function setShuffle() {
    updateConfig( { shuffle: !config.shuffle } )
  }

  return (
      <View  className="gp-typ" margin="small">

        <CheckboxField
          value={config.shuffle === false ? false : true }
          checked={config.shuffle === false ? false : true}
          onChange={setShuffle}
          label="Shuffle Answers"
        />

        <Flex direction="row" marginTop="large">
          <View shrink={0} grow={0} textAlign="center">Answer</View>
          <View shrink={1} grow={1} textAlign="center">Label</View>
        </Flex>

        {
          options.map( ( d, i ) => {
            return (
              <TextOption
                key={i}
                value={d}
                isAnswer={config.answer === d }
                onSetAnswer={setAnswer}
                placeholder={`Enter option ${i+1}`}
                onChange={(val)=>{updateOption( i, val )}}
              />
            )
          } )

        }

      </View>
  )
}
