import { Flex, View } from '@aws-amplify/ui-react'

require( './OrderEm.scss' )

export const OrderEmProgress = ( { answers } ) => { 

    const completed = answers.reduce( ( cnt, d ) => {
        return cnt + ( d === 0 ? 0 : 1 );
    }, 0 )

    const correct = answers.reduce( ( cnt, d ) => {
        return cnt + ( d > 0 ? 1 : 0 );
    }, 0 )

    const current = answers.reduce( ( first, d, i ) => {
        return first >= 0 ? first : d === 0 ? i : -1;
    }, -1 )
       
    return (
        <Flex className="oe-prg" direction="row" gap="10px" justifyContent="center" alignItems="center">

            <View className="oe-prg-label" >
                {`${completed} of ${answers.length}`}
            </View>

            <Flex direction="row" gap="10px" justifyContent="center" alignItems="center" grow={1} shrink={1}>
            {
                answers.map( ( d, i ) => {
                    let className=`oe-prg-item ${d > 0 ? 'game-ak-success' : d < 0 ? 'game-ak-error' : ''} ${current === i ? 'oe-prg-item-current' : ''}`
                    return ( <div key={i} className={className} grow={1} shrink={1}/> )
                })
            }
            </Flex>

            <View className="oe-prg-label"  >
                {`${correct} correct`}
            </View>
            
        </Flex>
    )
}