import React from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { View } from "@aws-amplify/ui-react";
import { FIELDTYPE_TEXT, FIELDTYPE_CATEGORICAL, FIELDTYPE_NUMERIC } from '../../../../../../hooks'

export const DataField = ( { field, datasetId } ) => { 
  let typeValue = fieldType( field )

  const [ { opacity }, dragRef ] = useDrag( () => ( {
    type: typeValue.ftype,
    item: { ...field, datasetId },
    collect: ( monitor ) => ( {
      opacity: monitor.isDragging() ? 0.4 : 1,
    } ),
  } ), [typeValue.fname, typeValue.ftt] )

  return (
    <div
      ref={dragRef}
      role="Field"
      className="dp-fld"
      style={{opacity}}
      title={typeValue.ftt}
    >
      <View className="dp-fld-type" >
        {typeValue.ftype}
      </View>

      <View className="dp-fld-name" >
        {typeValue.fname}
      </View>
    </div>
  )
}

DataField.propTypes = {
  field: PropTypes.object.isRequired
}

export function fieldType( field ) {
  let tooMany = field.valuesExceeded || field.valueLenExceeded
  let ftype = ''
  let ftt = ''

  switch( field.type ) {
    case 'number':
      ftype = FIELDTYPE_NUMERIC
      ftt = 'The field is numeric'
      break;

    case 'string':
    default:
      ftype = tooMany ? FIELDTYPE_TEXT : FIELDTYPE_CATEGORICAL
      ftt = `The field is ${tooMany ? 'a string' : 'categorical'}`
      break;
  }

  return {
    fname: field.name ? field.name : field.loc,
    type: field.type,
    ftype,
    ftt
  }
}