import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { Flex, View } from "@aws-amplify/ui-react";

import { BsX, BsChevronRight, BsChevronDown } from "react-icons/bs";

import { TextInput } from '../../../../../../Controls'
import { Checkbox } from './Checkbox'
import { Select } from './Select'

const fltItemType = 'iflt'

export const FilterItem = ( { item, idx, onMove, onRemove, onUpdate } ) => {
  const [isOpen, setIsOpen] = useState( false )
  const dragRef = useRef(null);
  const [{ handlerId }, drop] = useDrop( {
      accept: fltItemType,
      collect: (monitor) => { return { handlerId: monitor.getHandlerId(), } },
      hover: ( item, monitor ) => {
        if ( !dragRef.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = idx;

        // Don't replace items with themselves
        if ( dragIndex !== hoverIndex ) {

          // Determine rectangle on screen
          const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

          // Get vertical middle
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

          // Determine mouse position
          const clientOffset = monitor.getClientOffset()

          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return
          }

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return
          }

          // Move the item
          onMove( dragIndex, hoverIndex );
        }

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
  })

  const [{ isDragging }, drag] = useDrag( {
    type: fltItemType,
    item: () => { return { id: item.id, index: idx } },
    collect: ( monitor ) => ( { isDragging: monitor.isDragging(), } )
  } )

  let dragStyle = {}
  if ( isDragging ) {
    dragStyle.opacity = 0
  }

  // The tooltip item is both a drag and drop component.
  drag( drop( dragRef ) )

  // Setup the default if it's been set.
  let selVals = item && item.field && Array.isArray( item.field.values ) ? item.field.values : null

  let fname = item.field && item.field.name ? item.field.name : item.field.loc;

  return (
    <View
      ref={dragRef}
      data-handler-id={handlerId}
      style={dragStyle}
      className="cp-fc"
    >

      <View
        width="14px"
        height="14px"
        margin="xs"
        style={{cursor: "pointer"}}
        onClick={() => { setIsOpen( !isOpen ) }}
      >
        {
          isOpen ? (
            <BsChevronDown size={14} />
          ) : (
            <BsChevronRight size={14} />
          )
        }
      </View>

      <View className="cp-fc-ctr">
        <View
          className="cp-cfg-drp-fld"
        >
          <View
            className="cp-cfg-drp-fld-val"
          >
            {fname}
          </View>

          <View width="16px" height="16px" margin="xs" right="0px" style={{cursor: "pointer"}} onClick={() => {onRemove( idx )}} >
            <BsX />
          </View>
        </View>

        {
          isOpen ? (
            <View>
              <TextInput
                className="cp-fc-prp"
                label="Label"
                value={item.label}
                onUpdate={( val ) => { onUpdate( idx, {...item, label: val } ) }}
              />

              {
                item.field.type === 'string' ? (
                  <View>
                    <Checkbox
                      className="cp-fc-prp"
                      label="Allow multiple selections"
                      value={!!item.isMulti}
                      onUpdate={( val ) => { onUpdate( idx, {...item, isMulti: val ? true : false } ) }}
                    />

                    <Select
                      label="Default Value"
                      labelClassName="cp-ctl-lbl"
                      values={selVals}
                      onSelect={( val ) => { onUpdate( idx, {...item, dflt: val } ) }}
                      current={item.dflt ? item.dflt : selVals.length ? selVals[0] : ''}
                      placeholder="Search ..."
                      inline
                    />
                  </View>
                ) : item.field.type === 'number' ? (
                  <View>
                    <TextInput
                      className="cp-fc-prp"
                      label="Format"
                      value={item.format}
                      onUpdate={( val ) => { onUpdate( idx, {...item, format: val } ) }}
                    />
                    <Flex direction="row" className="cp-fc-prp" gap="15px">
                      <View>
                        Default Value
                      </View>
                      <View>
                        <TextInput
                          className="cp-fc-nbr"
                          label="Min"
                          value={Array.isArray(item.dflt) && item.dflt[0] !== undefined && item.dflt[0] !== null ? item.dflt[0].toString() : item.field.max ? item.field.min.toString() : "" }
                          onUpdate={( val ) => { onUpdate( idx, {...item, dflt: [Number(val), Array.isArray( item.dflt ) && item.dflt[1] ? item.dflt[1] : undefined] } ) }}
                        />
                        <TextInput
                          className="cp-fc-nbr"
                          label="Max"
                          value={Array.isArray(item.dflt) && item.dflt[1] !== undefined && item.dflt[1] !== null ? item.dflt[1].toString() : item.field.max ? item.field.max.toString() : "" }
                          onUpdate={( val ) => { onUpdate( idx, {...item, dflt: [Array.isArray( item.dflt ) && item.dflt[0] ? item.dflt[0] : undefined, Number(val)] } ) }}
                        />
                      </View>
                    </Flex>
                  </View>
                ) : null
              }

            </View>
          ) : null
        }
      </View>
    </View>
  )
}

FilterItem.propTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}
