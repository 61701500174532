// Apply a dimension filter using the specified range.  this
// range filter treats the range as inclusive (that is x >= range <= x)
// vs the crossfilter dimension range filter which requires that the value
// is within the range ( x > range < x )

// dim - Crossfilter dimension to apply the filter
// range - range to apply

export function applyFilterRange( dim, range ) {

  if ( dim && range ) {
    if ( Array.isArray( range ) && range.length > 0 ) {

      let low = range[0]
      let high = range.length > 1 ? range[1] : low

      dim.filterFunction( d => {
        return d !== undefined && d >= low && d <= high ? true : false
      } )
    }
  }

}
