import React, { useContext } from 'react'

import { VegaEditorContext } from '../../../VegaEditorContext'
import { TextInput } from '../../../../../Controls'
import {
  FieldConfig,
  ColorConfig,
  ColorScheme,
  StateHighlightConfig,
  TextColorConfig,
  TooltipConfig,
  useValidateFields
} from './components'

import { getDataId } from './configUtils'
import {
  clrDefaultHoverFill,
  clrDefaultHoverStroke,
} from './defaultValues'

export const defaultUSHexStates =  {
  s: {},
  v: {},
  hlf: {},
  hlm: [],
  tt: {},
  color: {
    labels: {
      stroke: 'white',
      fill: 'white'
    },
    hover: {
      stroke: clrDefaultHoverStroke,
      fill: clrDefaultHoverFill
    }
  }
}

// Assign labels for each of the items we can configure with color.
const colorTypes = [
  {
    val: 'label',
    label: 'Labels'
  },
  {
    val: 'hover',
    label: 'Hover'
  }
]

const fieldProps = ['s', 'v', 'iflt']

export const USHexStatesConfig = () => {
  const { config, updateConfig } = useContext( VegaEditorContext )
  const curDataId = getDataId( config, fieldProps )

  const valueConfig = config && config.v ? config.v : {}
  const colorConfig = valueConfig.color ? valueConfig.color : {}

  useValidateFields( fieldProps )

  function updateValueFormat( format ) {
    let newConfig = { ...valueConfig, format }

    updateConfig( { v: newConfig } )
  }

  function updateValueColors( scheme ) {
    let newConfig = { ...config.v, color: scheme }

    updateConfig( { v: newConfig } )
  }

  return (
    <div  className="cp-ctls" >
      <FieldConfig
        prop="s"
        label="States"
        accept={['C']}
        defaultProps={defaultUSHexStates}
        validDataset={curDataId}
      />

      <FieldConfig
        prop="v"
        label="Values"
        accept={['#']}
        defaultProps={defaultUSHexStates}
        validDataset={curDataId}
      >
        <TextInput
          className="cp-xs-prp"
          label="Format"
          value={valueConfig.format}
          onUpdate={updateValueFormat}
        />

        <ColorScheme
          value={colorConfig}
          onUpdate={updateValueColors}
        />
      </FieldConfig>

      <ColorConfig
        validDataset={curDataId}
        staticTypes={colorTypes}
      />

      <StateHighlightConfig
        validDataset={curDataId}
      />

      <TooltipConfig prop="tt" />

    </div>
  )
}
//
// BarConfig.propTypes = {
//   dataSets: PropTypes.object.isRequired,
//   config: PropTypes.object.isRequired,
//   onUpdate: PropTypes.func.isRequired
// }
