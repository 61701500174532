import { useState } from 'react'
import { TextField } from "@aws-amplify/ui-react";

export const GameTitle = ( { title, onChange, ...remaining } ) => {
  const [gameTitle, setGameTitle] = useState( title ? title : "" )

  function onKeyPress( event ) {
    const keyCode = event.keyCode || event.which

    if ( keyCode === 13 ) {

      // Update the name since a return was encountered.
      onChange( gameTitle )

      // Don't process if the pressed key was 'enter'.
      event.preventDefault && event.preventDefault()
    }
  }

  return (
    <TextField
      placeholder="Game Title"
      variation="quiet"
      value={gameTitle}
      onBlur={( event )=>{onChange( gameTitle )}}
      onKeyPress={onKeyPress}
      onChange={( event ) => {setGameTitle( event.target.value )}}
      {...remaining}
    />
  )
}