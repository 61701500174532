import { useEffect, useState } from 'react';
import clone from 'clone';
import { Flex } from '@aws-amplify/ui-react';

import { Description } from '../Description';
import { ExploreDataButton } from '../ExploreDataButton';
import { shuffleArray } from '../utils';
import { OrderEmContainers } from './OrderEmContainers';
import { OrderEmProgress } from './OrderEmProgress'

export const OrderEmDrag = ( { gameId, config, onAnswer, gameStatus } ) => {
    const status = gameStatus ? JSON.parse( gameStatus ) : {};

    const [items, setItems] = useState(status.answer ? clone( status.answer ) : {});
    const [answers, setAnswers] = useState(Array.isArray( status.status ) ? clone( status.status ) : []);

    let desc = config.description ? config.description : "No Description";
    
    useEffect( () => {
        let totalItems = Object.keys( items ).reduce( ( sum, d ) => {
            sum = sum + items[d].length;
            return sum;
        }, 0 );

        if ( Array.isArray( config.options ) && totalItems !== config.options.length ) {
            let options = Array.isArray( config.options ) ? config.options.slice() : [];

            // Start by finding the middle item
            const sorted = options.slice().sort( (a, b) => {
                return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
            })

            let middleIdx = -1;

            if ( config.initValue ) {
                // An initial value was specified so see if it exists.
                middleIdx = sorted.findIndex( d => { return d.label === config.initValue });
            }

            if ( middleIdx < 0 ) {
                // We don't have a middle value yet so compute it.
                middleIdx = Math.trunc( sorted.length / 2 ) - 1;
            }

            // Now remember the middle item so we can place it.
            let placedItem = sorted[middleIdx];
            
            // Filter the items to remove the middle item.
            let gameItems = options.filter( d => {
                return d.label !== placedItem.label;
            } ).map( ( d, i ) => {
                return {
                    id: d.label,
                    label: d.label,
                    data: {
                        value: d.value,
                        status: 'unset'
                    }
                }
            } );

            // If we don't have more than two items then we really don't have a game.
            if ( gameItems.length > 2 ) {
                let newItems = {
                    placed: [{
                        id: placedItem.label,
                        label: placedItem.label,
                        data: {
                            value: placedItem.value,
                            status: 'initial'
                        }
                    }],
                    placing: [gameItems[0]],
                    toPlace: gameItems.slice( 1 )
                }

                newItems.placing.forEach( d => {
                    d.data.status = 'placing'
                });

                setItems( newItems );
                setAnswers( [...Array(gameItems.length).keys()].map( d => { return 0 }) );
            }
        }

    }, [config.options, items, answers] );

    function onPlace( update ) {
        let newItems = { ...update }
        let newAnswers = answers.slice();

        // Find the item that was placed.
        let placingIdx = newItems.placed.findIndex( d => {
            return d.data.status === 'placing'
        } )

        if ( placingIdx >= 0 ) {
            let refNodeId = '';
            let refNodeLabel = '';
            let dir = '';
            let correct = true;

            // We found it, so let's figure out where it was placed.
            if ( placingIdx < newItems.placed.length - 1) {
                dir = 'before';
                refNodeId = newItems.placed[placingIdx + 1].id;
                refNodeLabel = newItems.placed[placingIdx + 1].label;
            } else {
                dir = 'after';
                refNodeId = newItems.placed[placingIdx - 1].id;
                refNodeLabel = newItems.placed[placingIdx - 1].label;
            }

            // Always sort the items so we know they are in the correct position.
            const sorted = newItems.placed.slice().sort( ( a, b ) => {
                const A = a.data.value;
                const B = b.data.value;

                return ( ( A < B ? -1 : A > B ? 1 : 0 ) * ( config.descending ? -1 : 1 ) );
            } )

            // Find the item that was placed.
            let checkIdx = sorted.findIndex( d => {
                return d.id === newItems.placed[placingIdx].id
            } )

            if ( checkIdx !== placingIdx ) {
                correct = false;
                newAnswers[newItems.placed.length - 2] = -1;
            } else {
                newAnswers[newItems.placed.length - 2] = 1;
            }

            // And update the status.
            newItems.placed[placingIdx].data = {
                ...newItems.placed[placingIdx].data,
                status: 'placed',
                refNodeId,
                refNodeLabel,
                dir,
                correct
            }

            // Update the placed items to ensure they are sorted properly.
            newItems.placed = sorted
        }

        // Now check to see if there are any more items to be placed.
        if ( newItems.toPlace.length ) {
            newItems.placing = [{...newItems.toPlace[0]}];
            newItems.placing[0].data.status = 'placing';

            newItems.toPlace = newItems.toPlace.slice(1);
        }

        // Update our state.
        setItems( newItems );
        setAnswers( newAnswers );

        // If we have no more items to place then we are done so record the results.
        if ( newItems.placing.length === 0 ) {
            onAnswer( { answer: newItems, status: newAnswers } );
        }

        return newItems
    }
      
    return (

        <Flex direction="column" maxWidth="450px" margin="large auto" >

            <Description desc={desc} />

            <OrderEmProgress answers={answers} />

            {
                Array.isArray( items.placing ) && items.placing.length === 0 ? (
                    <ExploreDataButton gameId={gameId} />
                ) : null
            }

            <Flex direction="column" grow={1} shrink={1}>
                {
                    Object.keys( items ).length > 0 ? (
                        <OrderEmContainers
                            key=""
                            items={items}
                            vertical
                            onPlacement={onPlace}
                            topLabel={config.topLabel}
                            bottomLabel={config.bottomLabel}
                            valueFormat={config.format}
                        />
                    ) : null
                }
            </Flex>
            
        </Flex>

    )
  }