

export function getUsedData( spec ) {
  let valid = []

  if ( spec && Array.isArray( spec.data ) ) {
    let chkData = spec.data.reduce( ( obj, d ) => {
      obj[d.name] = true

      if ( d.src ) {
        obj[d.src] = true
      }

      return obj
    }, {} )

    valid = Object.keys( chkData )
  }

  return valid
}
