import { Heading, View } from "@aws-amplify/ui-react";

export const AboutPage = () => {

    return (
        <View padding="30px" >
            <Heading level={1} paddingLeft="large" maxWidth="992px" margin="0 auto">About RipeData</Heading>
            <View padding="large" maxWidth="992px" margin="0 auto">

                Long winded way of saying that it's fun and sometimes enlightening to play with data.
            </View>
        </View>
    )
}