import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Flex, Loader, View, useAuthenticator } from "@aws-amplify/ui-react";

import { GameEditor } from '../../components'
import { useGame } from '../../hooks'
import { GameCategory } from './GameCategory'
import { GamePreview } from './GamePreview'
import { GamePublish } from './GamePublish'
import { GameSelector } from './GameSelector'
import { GameTitle } from './GameTitle'

require( './GameEditorPage.scss' );

export const GameEditorPage = () => {
  const { gameId } = useParams();
  const [game, setGame] = useGame( gameId )
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [context.route]);

  let component = null 

  if ( route === 'authenticated' ) {
    component = (
      <View margin="large" grow={1} shrink={1}>
  
        <Flex
          direction="column"
          maxWidth="992px"
          margin="0 auto"
          height="100%"
          backgroundColor="#fff"
        >

          {
            game._loaded ? (
              <>
                <Flex paddingBottom="large">
                  <GameTitle
                    title={game.title}
                    onChange={( title )=>{setGame( { title } ) }}
                    grow="1"
                    shrink="1"
                    marginRight="large"
                  />

                  <GameCategory
                    category={game.category}
                    onChange={( category )=>{setGame( { category } ) }}
                    marginRight="large"
                  />

                  <GameSelector
                    game={game}
                    onChange={( gameSpec )=>{ setGame( gameSpec )}}
                  />

                  <GamePublish
                    game={game}
                    onChange={( pubdate )=>{ setGame( { published: pubdate } )}}
                  />

                  <GamePreview
                    game={game}
                  />
                </Flex>
                

                <GameEditor
                  game={game}
                  onChange={( update ) => { setGame( update )}}
                />
              </>
            ) : (
              <Loader className="rdloader" />
            )
          }

        </Flex>
  
      </View>
  ) } else {
    navigate(`/`)
  }
  
  return component
}