import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { Flex } from '@aws-amplify/ui-react'
import { verticalListSortingStrategy } from '@dnd-kit/sortable';

import { Sortable } from '../../../dnd';
import {Description} from '../Description';
import { DoneButton } from '../DoneButton';
import { shuffleArray } from '../utils';

export const OrderEm = ( { config, onAnswer } ) => {
    const [items, setItems] = useState([])
    
    let desc = config.description ? config.description : "No Description";

    useEffect( () => {
        if ( Array.isArray( config.options ) && !isEqual( config.options, items ) ) {
            let options = Array.isArray( config.options ) ? config.options.slice() : [];

            // We need to run through the list of items and generate
            // a random list of items to use in the game.
            if ( Array.isArray( options ) ) {
                let valCopy = options.map( ( d, i ) => {
                    return {
                        id: i + 1,
                        label: d.label,
                        data: d.value
                    }
                });

                shuffleArray( valCopy );

                setItems( valCopy.slice());
            }
        }

    }, [config.options, items.length] );

    function checkAnswer() {
        let answer = true

        let answerItems = items.map( d => {
            return {
                label: d.label,
                value: d.data
            }
        })

        for ( let idx = 1; idx < answerItems.length && answer ; idx++ ) {

            if ( Number( answerItems[idx].value ) > Number( answerItems[idx-1].value ) ) {
                answer = false;
            }
        }
        
        onAnswer( { correct: answer, answer: answerItems } )
    }
       
    return (
    
        <Flex direction="column" maxWidth="450px" margin="large auto" >

            <Description desc={desc} />

            <DoneButton
                checkAnswer={checkAnswer}
            />

            {
                items.length ? (
                    <Sortable stragegy={verticalListSortingStrategy} items={items} onChange={setItems}/>
                ) : null
            }
            
        </Flex>
    )
  }