import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const TextInput = ( { className, label, value, placeholder, onUpdate } ) => {

  let wrapperClasses = classNames( 'cp-ctl', className )

  return (
    <div className={wrapperClasses}>
      {
        label ? (
          <div className="cp-ctl-lbl" >
            {label}
          </div>
        ) : null
      }

      <input
        className="cp-ctl-val"
        placeholder={placeholder}
        defaultValue={value}
        onBlur={( event ) => { onUpdate( event.target.value ) }}
      />
    </div>
  )
}

TextInput.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string
}
