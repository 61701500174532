import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { VegaEditorContext } from '../../../VegaEditorContext'

import { defaultArea, AreaConfig } from './AreaConfig'
import { defaultBar, BarConfig } from './BarConfig'
import { defaultBeeSwarm, BeeSwarmConfig } from './BeeSwarmConfig'
import { defaultDonut, DonutConfig } from './DonutConfig'
import { defaultLine, LineConfig } from './LineConfig'
import { defaultParallelCoordinates, ParallelCoordinatesConfig } from './ParallelCoordinatesConfig'
import { defaultScatter, ScatterConfig } from './ScatterConfig'
import { defaultStackedBar, StackedBarConfig } from './StackedBarConfig'
import { defaultUSHexStates, USHexStatesConfig } from './USHexStatesConfig'

// Setup the default configurations for the various chart types.
export const defaultConfig = {
  area: defaultArea,
  bar: defaultBar,
  stackedbar: defaultStackedBar,
  beeswarm: defaultBeeSwarm,
  doughnut: defaultDonut,
  line: defaultLine,
  pcoord: defaultParallelCoordinates,
  scatter: defaultScatter,
  ushexstates: defaultUSHexStates
}

export const ChartConfig = () => {
  const { config } = useContext( VegaEditorContext )

  let rendered = null

  switch( config.type ) {
    case 'area':
      rendered = (
        <AreaConfig />
      )
      break;

    case 'bar':
      rendered = (
        <BarConfig />
      )
      break;

    case 'stackedbar':
      rendered = (
        <StackedBarConfig />
      )
      break;

    case 'beeswarm':
      rendered = (
        <BeeSwarmConfig />
      )
      break;

    case 'doughnut':
      rendered = (
        <DonutConfig />
      )
      break;

    case 'line':
      rendered = (
        <LineConfig />
      )
      break;

    case 'pcoord':
      rendered = (
        <ParallelCoordinatesConfig />
      )
      break;

    case 'scatter':
      rendered = (
        <ScatterConfig />
      )
      break;

    case 'ushexstates':
      rendered = (
        <USHexStatesConfig />
      )
      break;

    default:
      // Empty panel?
  }

  return rendered
}
