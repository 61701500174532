
import { addColorScale } from './colorscale'
import { addNumericAxis } from './numericaxis'
import { addPointAxis } from './pointaxis'
import { genTooltip } from './tooltip'
import { getDataset, getConfigField } from './utils'

const defaultDiameter = 5

export function area( config, data ) {

  // If size is defined than this is an older format so convert it to what
  // we are using now.
  let symbol = config.symbol ? config.symbol : config.size ? { field: config.size } : { size: defaultDiameter }

  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated scatter plot specification.',
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    height: 600,
    padding: 5,
    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      },
      {
        name: 'diameter',
        update: `width >= 300 ? ${symbol.size} : ${symbol.size}/2`
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  // Turn off zoom/pan as it's not quite functional.
  const zoompan = false

  if ( zoompan ) {

    // signals for the zoom/pan capability
    spec.signals.push( {
      name: 'xoffset',
      update: '-(height + padding.bottom)'
    } )

    spec.signals.push( {
      name: 'yoffset',
      update: '-(width + padding.left)'
    } )

    spec.signals.push( { name: 'xrange', update: '[0, width]' } )
    spec.signals.push( { name: 'yrange', update: '[height, 0]' } )

    spec.signals.push( {
      name: 'down', value: null,
      on: [
        {events: 'touchend', update: 'null'},
        {events: 'mousedown, touchstart', update: 'xy()'}
      ]
    } )

    spec.signals.push( {
      name: 'xcur', value: null,
      on: [
        {
          events: 'mousedown, touchstart, touchend',
          update: 'slice(xdom)'
        }
      ]
    } )

    spec.signals.push( {
      name: 'ycur', value: null,
      on: [
        {
          events: 'mousedown, touchstart, touchend',
          update: 'slice(ydom)'
        }
      ]
    } )

    spec.signals.push( {
      name: 'delta', value: [0, 0],
      on: [
        {
          events: [
            {
              source: 'window', type: 'mousemove', consume: true,
              between: [{type: 'mousedown'}, {source: 'window', type: 'mouseup'}]
            },
            {
              type: 'touchmove', consume: true,
              filter: 'event.touches.length === 1'
            }
          ],
          update: 'down ? [down[0]-x(), y()-down[1]] : [0,0]'
        }
      ]
    } )

    spec.signals.push( {
      name: 'anchor', value: [0, 0],
      on: [
        {
          events: 'wheel',
          // update: '[invert( "xScale", x()), invert( "yScale", y())]'
          update: '[scale( "xScale", x()), scale( "yScale", y())]'
        },
        {
          events: {type: 'touchstart', filter: 'event.touches.length===2'},
          update: '[(xdom[0] + xdom[1]) / 2, (ydom[0] + ydom[1]) / 2]'
        }
      ]
    } )

    spec.signals.push( {
      name: 'zoom', value: 1,
      on: [
        {
          events: 'wheel!',
          force: true,
          update: 'pow(1.001, event.deltaY * pow(16, event.deltaMode))'
        },
        {
          events: {signal: 'dist2'},
          force: true,
          update: 'dist1 / dist2'
        }
      ]
    } )

    spec.signals.push( {
      name: 'dist1', value: 0,
      on: [
        {
          events: {type: 'touchstart', filter: 'event.touches.length===2'},
          update: 'pinchDistance(event)'
        },
        {
          events: {signal: 'dist2'},
          update: 'dist2'
        }
      ]
    } )

    spec.signals.push( {
      name: 'dist2', value: 0,
      on: [{
        events: {type: 'touchmove', consume: true, filter: 'event.touches.length===2'},
        update: 'pinchDistance(event)'
      }]
    } )

    spec.signals.push( {
      name: 'xdom', update: 'slice(xext)',
      on: [
        {
          events: {signal: 'delta'},
          update: '[xcur[0] + span(xcur) * delta[0] / width, xcur[1] + span(xcur) * delta[0] / width]'
        },
        {
          events: {signal: 'zoom'},
          update: '[anchor[0] + (xdom[0] - anchor[0]) * zoom, anchor[0] + (xdom[1] - anchor[0]) * zoom]'
        }
      ]
    } )

    spec.signals.push( {
      name: 'ydom', update: 'slice(yext)',
      on: [
        {
          events: {signal: 'delta'},
          update: '[ycur[0] + span(ycur) * delta[1] / height, ycur[1] + span(ycur) * delta[1] / height]'
        },
        {
          events: {signal: 'zoom'},
          update: '[anchor[1] + (ydom[0] - anchor[1]) * zoom, anchor[1] + (ydom[1] - anchor[1]) * zoom]'
        }
      ]
    } )
  }

  // The X axis value is defined so add in the requsite filters, scales and axes
  let xField = getConfigField( config.x )

  if ( xField ) {
    let ds = getDataset( spec, xField.datasetId )
    if ( ds ) {
      ds.transform.push( {
        type: 'filter',
        expr: `isNumber( datum.${xField.loc} )`
      } )

      ds.transform.push( {
        type: 'collect',
        sort: { field: xField.loc }

      } )

      // Add in transform to compute the x extents.
      ds.transform.push( { type: 'extent', field: xField.loc, signal: 'xext' } )
    }

    let xOpts = {
      prefix: 'x',
      orient: 'bottom',
      domain: { data: xField.datasetId, field: xField.loc },
      range: 'width'
    }

    if ( zoompan ) {
      // xOpts.orient = 'top'
      xOpts.domain = { signal: 'xdom' }
      xOpts.range = { signal: 'xrange' }
      xOpts.offset = { signal: 'xoffset' }
    }

    addPointAxis( spec, config.x, xOpts )
  }

  // The Y axis value is defined so add in the requsite filters, scales and axes
  let yField = getConfigField( config.y )
  if ( yField ) {
    let ds = getDataset( spec, yField.datasetId )

    if ( ds ) {
      ds.transform.push(
        {
          type: 'filter',
          expr: `isNumber( datum.${yField.loc} )`
        }
      )

      // Add in transform to compute the y extents.
      ds.transform.push( { type: 'extent', field: yField.loc, signal: 'yext' } )
    }

    let yOpts = {
      prefix: 'y',
      orient: 'left',
      domain: { data: yField.datasetId, field: yField.loc },
      range: 'height',
    }

    if ( zoompan ) {
      // yOpts.orient = 'right'
      yOpts.domain = { signal: 'ydom' }
      yOpts.range = { signal: 'yrange' }
      yOpts.offset = { signal: 'yoffset' }
    }

    addNumericAxis( spec, config.y, yOpts )
  }

  // Now add the mark to the specification.
  if ( xField && yField ) {

    // Get the static color for the mark if it exists.
    let markClr = config.color && config.color.mark ? config.color.mark : {}
    let hoverClr = config.color && config.color.hover ? config.color.hover : {}

    let chartMark = {
      type: "area",
      from: { data: xField.datasetId },
      encode: {
        enter: {
          x: { scale: 'xScale', field: xField.loc },
          y: { scale: 'yScale', field: yField.loc },
          y2: { scale: 'yScale', value: 0 },
          stroke: { value: markClr.fill ? markClr.fill : 'steelblue' },
          fill: { value: markClr.fill ? markClr.fill : 'steelblue' },
          strokeWidth: {"value": 2}
        },
        update: {
          interpolate: 'linear',
          x: { scale: 'xScale', field: xField.loc },
          y: { scale: 'yScale', field: yField.loc },
          y2: { scale: 'yScale', value: 0 },
          stroke: { value: markClr.fill ? markClr.fill : 'steelblue' },
          fill: { value: markClr.fill ? markClr.fill : 'steelblue' },
          strokeOpacity: {value: 1},
          strokeWidth: {"value": 2}
        },
        hover: {
          stroke: { value: hoverClr.fill ? hoverClr.fill : 'red' },
          fill: { value: hoverClr.fill ? hoverClr.fill : 'red' },
          strokeOpacity: {value: 0.5},
          zindex: { value: 1 }
        }
      }
    }

    let groupField = getConfigField( config.g )

    if ( groupField ) {
      let ds = getDataset( spec, groupField.datasetId )
      ds.transform.push( {
        type: 'stack',
        groupby: [xField.loc],
        sort: { field: groupField.loc},
        field: yField.loc
      })  

      // Use the grouped values for y.
      chartMark.encode.enter.y = { scale: 'yScale', field: 'y0' }
      chartMark.encode.enter.y2 = { scale: 'yScale', field: 'y1' }
      chartMark.encode.update.y = { scale: 'yScale', field: 'y0' }
      chartMark.encode.update.y2 = { scale: 'yScale', field: 'y1' }

      // Add the color configuration to the specification
      addColorScale( spec, config.g, 'color' )

      // We have a group field so color accordingly
      chartMark.encode.enter.stroke = { scale: 'color', field: groupField.loc }
      chartMark.encode.enter.fill = { scale: 'color', field: groupField.loc }
      chartMark.encode.update.stroke = { scale: 'color', field: groupField.loc }
      chartMark.encode.update.fill = { scale: 'color', field: groupField.loc }

      // Find out if the 'selected' dataset has been created, if so then we 
      // have an interactive legend.
      let colorDataset = spec.data.find( d => {
        return d.name === 'selected'
      })

      if ( colorDataset ) {
        // Only hide the legend if the value is explicitly set to false.

        // If we are using interactive legends...
        chartMark.encode.update.opacity = [
          { test: `(!length(data('selected')) || indata('selected', 'value', datum.${groupField.loc}))`, value: 1.0 },
          { value: 0.05 }
        ]
      }

      // If a tooltip is specified...
      if ( Array.isArray( config.tt ) ) {
        chartMark.encode.update.tooltip = { signal: genTooltip( config.tt ) }
      }

      // Use a group to show the various lines.
      chartMark.from = { data: 'series' }

      chartMark = {
        type: 'group',
        from: {
          facet: {
            name: 'series',
            data: xField.datasetId,
            groupby: groupField.loc
          }
        },
        marks: [chartMark]
      }
    }

    spec.marks.push( chartMark )
  }

  return spec
}
