import React, { useContext, useEffect, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import clone from 'clone'
import isEqual from 'lodash/isEqual'

import { VegaEditorContext } from '../../../../VegaEditorContext'

import { clrDefaultMarkFill } from '../defaultValues'
import { Checkbox } from './Checkbox'
import { ColorPicker } from './ColorPicker'
import { ColorCategorical } from './ColorCategorical'
import { ConfigGroup } from './ConfigGroup'
import { DropField } from './DropField'

export const ColorConfig = ( { label, staticTypes, isDynamic, validDataset } ) => {
  const { config, updateConfig } = useContext( VegaEditorContext )

  // Perform a conversion for the old style colors being just a field definition.
  let colorValues = config.color && config.color.datasetId ? { field: config.color } : config.color ? config.color : {}

  // Setup the static colors.
  const [colors, setColor] = useReducer(
  ( state, colorUpdate ) => {
    return { ...state, ...colorUpdate }
  }, clone( colorValues ) )

  useEffect( () => {
    if ( prevColors.current && !isEqual( prevColors.current, colors ) ) {
      updateConfig( { color: colors } )
    }
  } )

  const prevColors = useRef()
  useEffect( () => {
    prevColors.current = colors
  } )

  function setLegend( config ) {
    let newLegend = { ...config }

    setColor({ legend: newLegend })
  }

  return (
    <ConfigGroup label={label ? label : 'Colors'}>

        {
          isDynamic ? (
            <>
              <DropField
                prop="field"
                label="Value"
                field={colors.field ? colors.field : null}
                accept={['C']}
                onDrop={setColor}
                validDataset={validDataset}
              />

              {
                colors.field ? (
                  <>
                    <ColorCategorical
                      marginTop="xs"
                      label="Scheme"
                      value={colors.scheme}
                      onUpdate={( clr ) => { setColor( { scheme: clr } ) }}
                    />

                    <Checkbox
                      className="cp-xs-prp"
                      label="Hide Legend"
                      value={colors.legend && colors.legend.show === false ? false : true}
                      onUpdate={( val ) => { setLegend( { show: val } ) }}
                      isDisabled={!colors.field}
                    />
                  </>
                ) : null
              }
{/*
              <Checkbox
                className="cp-xs-prp"
                label="Interactive Legend"
                value={colors.legend && colors.legend.interactive === false ? false : true}
                onUpdate={( val ) => { setLegend( { interactive: val } ) }}
                isDisabled={!colors.field}
              />
          */}
            </>
          ) : null
        }

        {
          staticTypes.map( ( d, i ) => {
            return (
                <ColorPicker
                  key={`${d.val}-${i}`}
                  label={d.label}
                  value={colors[d.val] && colors[d.val].fill ? colors[d.val].fill : clrDefaultMarkFill}
                  onUpdate={( clr ) => { setColor( { [d.val]: { fill: clr, stroke: clr } } ) }}
                />
              )
          } )
        }

    </ConfigGroup>
  )
}

ColorConfig.propTypes = {
  configProp: PropTypes.string,
  label: PropTypes.string,
  staticTypes: PropTypes.array,
  isDynamic: PropTypes.bool
}
