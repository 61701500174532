import { Flex, View } from '@aws-amplify/ui-react'
import classNames from 'classnames';

import {Description} from '../Description';

require( './OrderEm.scss' )

export const OrderEmAnswerKey = ( { config, answer } ) => {   
    let desc = config.description ? config.description : "No Description";
    let items = [];

    if ( Array.isArray( config.options ) ) {
        items = config.options.slice().sort( ( a, b ) => {
            let A = Number( a.value );
            let B = Number( b.value );
            return A < B ? 1 : A > B ? -1 : 0
        } );
    }
       
    return (
        <Flex direction="column" maxWidth="450px" margin="large auto" >

            <Description desc={desc} />

            <div className="oe-dropctr">
                <div
                    className="oe-dz"
                >
                {
                    items.length ? (
                    items.map( ( item, i )=> {
                        let colorStyle = "game-ak-success";

                        if ( answer[i].label !== item.label ) {
                            colorStyle = "game-ak-error"
                        }

                        let classes = classNames( 'oe-handle', colorStyle );

                        return (
                            <li key={i} className="oe-item" >
                                <div className={classes} >
                                    {item.label}
                                </div>
                            </li>
                        )
                    } )
                    ) : null
                }
                </div>
            </div>
            
        </Flex>
    )
  }