
import { useNavigate } from 'react-router-dom';
import { Button } from '@aws-amplify/ui-react';

export const ExploreDataButton = ( { gameId }) => {
    const navigation = useNavigate();

    function onNavigate() {

        // Update the url to reflect the new game.
        navigation( `/explore/${gameId}` );
    }
    return (
        <Button
            onClick={onNavigate}
            marginTop="large"
            variation="primary"
        >
            Explore the data
        </Button>
    )
}