import { Outlet } from 'react-router-dom';
import { Flex, ThemeProvider } from '@aws-amplify/ui-react'
import { ShortFooter } from './ShortFooter'
import { Navbar } from './Navbar'

export const SinglePageLayout = () => {

  return (
    <ThemeProvider height="100vh" width="100vw" >
      <Flex
        direction="column"
        backgroundColor="background.secondary"
        height="100vh"
        width="100vw"
        gap="0px"
        overflow="hidden"
      >
        <Navbar />

        <Outlet />

        <ShortFooter />

      </Flex>
    </ThemeProvider>
  );
}