import { getConfigField, makeDisplayable } from './utils'

// Augment the provided specification with a scale and axis
export function addCategoricalAxis( spec, config, prefix, orient, range ) {

  // Find the field to be used for the axis
  let field = getConfigField( config )

  // If a title is defined use that, otherwise fallback to the field name
  // then to the field location.
  let axisTitle = config.title && config.title.title ? config.title.title : makeDisplayable( field.name ? field.name : field.loc )
  let domain = config.domain ? config.domain : {}
  let grid = config.grid ? config.grid : {}
  let label = config.label ? config.label : {}
  let ticks = config.ticks ? config.ticks : {}

  // Set the scale domain based on whether we are sorting or have a values
  // array.
  let scaleDomain = field.values && !domain.sort ? field.values : {
    data: field.datasetId,
    field: field.loc
  }

  // If we don't have a values array then add in sorting if requested.
  if ( !Array.isArray( scaleDomain ) && domain.sort ) {
    // if ( sort.field ) {
    //   scaleDomain.sort = { op: 'count', field: sort.field, order: sort.order }
    // } else {
      scaleDomain.sort = { order: 'ascending' }
    // }
  }

  let scaleConfig = {
    name: `${prefix}Scale`,
    type: 'band',
    round: true,
    padding: 0.05,
    domain: scaleDomain,
    range: range
  }

  // Add the scale
  spec.scales.push( scaleConfig )

  spec.axes.push(
    {
      scale: `${prefix}Scale`,
      grid: !!grid.show,
      domain: !!domain.show,
      orient: orient,
      ticks: !!ticks.show,
      tickCount: 5,
      title: axisTitle,
      labelAngle: label.angle,
      labelAlign: label.align,
      zindex: 2
    }
  )
}
