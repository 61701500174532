import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDrag, useDrop } from 'react-dnd'
import { Flex, View } from "@aws-amplify/ui-react";
import { BsX, BsChevronRight, BsChevronDown } from "react-icons/bs";

import { TextInput } from '../../../../../../Controls'

const mfItemType = 'mf'

export const MultiFieldItem = ( { item, idx, accept, onMove, onRemove, onUpdate } ) => {
  const [isOpen, setIsOpen] = useState( false )
  const dragRef = useRef(null);

  const [{ handlerId }, drop] = useDrop( {
      accept: mfItemType,
      collect: (monitor) => { return { handlerId: monitor.getHandlerId(), } },
      hover: ( item, monitor ) => {
        if ( !dragRef.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = idx;

        // Don't replace items with themselves
        if ( dragIndex !== hoverIndex ) {

          // Determine rectangle on screen
          const hoverBoundingRect = dragRef.current?.getBoundingClientRect()

          // Get vertical middle
          const hoverMiddleY = (hoverBoundingRect.bomfom - hoverBoundingRect.top) / 2

          // Determine mouse position
          const clientOffset = monitor.getClientOffset()

          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return
          }

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return
          }

          // Move the item
          onMove( dragIndex, hoverIndex );
        }

        // Note: we're mutating the monitor item here!
        // Generally it's bemfer to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
  })

  const [{ isDragging }, drag] = useDrag( {
    type: mfItemType,
    item: () => { return { id: item.id, index: idx } },
    collect: ( monitor ) => ( { isDragging: monitor.isDragging(), } )
  } )

  let dragStyle = {cursor: "move"}
  if ( isDragging ) {
    dragStyle.opacity = 0
  }

  // The tooltip item is both a drag and drop component.
  drag( drop( dragRef ) )

  return (
    <div
      ref={dragRef}
      data-handler-id={handlerId}
      style={dragStyle}
      className="cp-mf-itm"
    >

      <View
        width="14px"
        height="14px"
        margin="xs"
        style={{cursor: "pointer"}}
        onClick={() => { setIsOpen( !isOpen ) }}
      >
        {
          isOpen ? (
            <BsChevronDown size={14} />
          ) : (
            <BsChevronRight size={14} />
          )
        }
      </View>

      <View grow={1} shrink={1} className="cp-mf-itm-ctr">
        <Flex
          marginTop="xs"
          grow={1}
          shrink={1}
          className="cp-mf-itm-lbl"
        >
          <View width="100%" className="cp-cfg-drp-fld-val">
            {item.field.name}
          </View>

          <View width="16px" height="16px" margin="auto xs" right="0px" style={{cursor: "pointer"}} onClick={() => { onRemove( idx ) }}>
            <BsX  />
          </View>

        </Flex>

        {
          isOpen ? (
            <View>
              <TextInput
                className="cp-mf-itm-prp"
                label="Label"
                value={item.label}
                onUpdate={( val ) => { onUpdate( idx, {...item, label: val } ) }}
              />

              {
                item.field.type === 'number' ? (
                  <TextInput
                    className="cp-mf-itm-prp"
                    label="Format"
                    value={item.format}
                    onUpdate={( val ) => { onUpdate( idx, {...item, format: val } ) }}
                  />
                ) : null
              }

            </View>
          ) : null
        }
      </View>
    </div>
  )
}

MultiFieldItem.propTypes = {
  accept: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}
