import { useState } from 'react'
import { Flex, TextField, View } from "@aws-amplify/ui-react";

export const TextInput = ( { value, isAnswer, onChange, ...remaining } ) => {
  const [option, setOption] = useState( value ? value : "" )

  function onKeyPress( event ) {
    const keyCode = event.keyCode || event.which

    if ( keyCode === 13 ) {

      // Update the name since a return was encountered.
      onChange( option )

      // Don't process if the pressed key was 'enter'.
      event.preventDefault && event.preventDefault()
    }
  }

  return (
    <TextField shrink={1} grow={1}
      variation="quiet"
      value={option}
      onBlur={( event )=>{onChange( option )}}
      onKeyPress={onKeyPress}
      onChange={( event ) => {setOption( event.target.value )}}
      {...remaining}
    />
  )
}