const defaultInitializer = (index: number) => {
  return {
    id: index,
    label: `Item - ${index}`
  }
}

export function createRange<T = any>(
  length: number,
  initializer: (index: number) => any = defaultInitializer
): T[] {
  return [...new Array(length)].map((_, index) => initializer(index));
}
