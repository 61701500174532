export const fivethirtyeightConfig = {
  "arc": {"fill": "#30a2da"},
  "area": {"fill": "#30a2da"},
  "axis": {
    "domainColor": "#cbcbcb",
    "grid": true,
    "gridColor": "#cbcbcb",
    "gridWidth": 1,
    "labelColor": "#999",
    "labelFontSize": 10,
    "titleColor": "#333",
    "tickColor": "#cbcbcb",
    "tickSize": 10,
    "titleFontSize": 14,
    "titlePadding": 10,
    "labelPadding": 4
  },
  "axisBand": {"grid": false},
  "background": "#f0f0f0",
  "group": {"fill": "#f0f0f0"},
  "legend": {
    "labelColor": "#333",
    "labelFontSize": 11,
    "padding": 1,
    "symbolSize": 30,
    "symbolType": "square",
    "titleColor": "#333",
    "titleFontSize": 14,
    "titlePadding": 10
  },
  "line": {"stroke": "#30a2da", "strokeWidth": 2},
  "path": {"stroke": "#30a2da", "strokeWidth": 0.5},
  "rect": {"fill": "#30a2da"},
  "range": {
    "category": [
      "#30a2da",
      "#fc4f30",
      "#e5ae38",
      "#6d904f",
      "#8b8b8b",
      "#b96db8",
      "#ff9e27",
      "#56cc60",
      "#52d2ca",
      "#52689e",
      "#545454",
      "#9fe4f8"
    ],
    "diverging": [
      "#cc0020",
      "#e77866",
      "#f6e7e1",
      "#d6e8ed",
      "#91bfd9",
      "#1d78b5"
    ],
    "heatmap": ["#d6e8ed", "#cee0e5", "#91bfd9", "#549cc6", "#1d78b5"]
  },
  "point": {"filled": true, "shape": "circle"},
  "shape": {"stroke": "#30a2da"},
  "bar": {"binSpacing": 2, "fill": "#30a2da", "stroke": null},
  "title": {"anchor": "start", "fontSize": 24, "fontWeight": 600, "offset": 20}
}

export const googleConfig = {
  "arc": {"fill": "#3366CC"},
  "area": {"fill": "#3366CC"},
  "path": {"stroke": "#3366CC"},
  "rect": {"fill": "#3366CC"},
  "shape": {"stroke": "#3366CC"},
  "symbol": {"stroke": "#3366CC"},
  "circle": {"fill": "#3366CC"},
  "background": "#fff",
  "padding": {"top": 10, "right": 10, "bottom": 10, "left": 10},
  "style": {
    "guide-label": {"font": "Arial, sans-serif", "fontSize": 12},
    "guide-title": {"font": "Arial, sans-serif", "fontSize": 12},
    "group-title": {"font": "Arial, sans-serif", "fontSize": 12}
  },
  "title": {
    "font": "Arial, sans-serif",
    "fontSize": 14,
    "fontWeight": "bold",
    "dy": -3,
    "anchor": "start"
  },
  "axis": {
    "gridColor": "#ccc",
    "tickColor": "#ccc",
    "domain": false,
    "grid": true
  },
  "range": {
    "category": [
      "#4285F4",
      "#DB4437",
      "#F4B400",
      "#0F9D58",
      "#AB47BC",
      "#00ACC1",
      "#FF7043",
      "#9E9D24",
      "#5C6BC0",
      "#F06292",
      "#00796B",
      "#C2185B"
    ],
    "heatmap": ["#c6dafc", "#5e97f6", "#2a56c6"]
  }
}
