import React, { useState } from 'react'
import clone from "clone"
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Modal from "react-modal";
import { FaEdit } from "react-icons/fa";
import { Button, Flex, View } from "@aws-amplify/ui-react";

import { DataEditorFieldList } from './DataEditorFieldList'
import { DataEditorMetadata } from './DataEditorMetadata'
import { FieldEditor } from './FieldEditor'
import { MetadataEditor } from './MetadataEditor'

export const DataSetEditor = ( { className, dataset, onUpdate } ) => {
    const [isModalOpen, setIsModalOpen] = useState( false );
    const [selected, setSelected] = useState( -1 )
    const [editDataSet, setEditDataSet] = useState( dataset ? clone( dataset ) : {} );
    const dsFields = Array.isArray( editDataSet.fields ) ? editDataSet.fields.slice() : [];

    let wrapperClasses = classNames( 'cp-ctl', className );

    function updateDataset() {
        onUpdate && onUpdate( editDataSet )

        closeModal()
    }

    function closeModal() {
        setIsModalOpen( false )
    }

    function openModal() {
        setIsModalOpen( true )
    }

    function updateMetadata( update ) {
        const newMeta = { ...editDataSet, ...update }
        setEditDataSet( newMeta )
    }

    function updateField( idx, update ) {
        const newFields = [
            ...dsFields.slice( 0, idx ),
            { ...dsFields[idx], ... update },
            ...dsFields.slice( idx + 1 )
        ]

        const newData = { ...editDataSet, fields: newFields }
        setEditDataSet( newData )
    }

    const modalStyles = {
        content: {
            top: "10%",
            left: "10%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            // transform: "translate(-50%, -50%)",
            overflow: "hidden",
            padding: "0px",
            width: "800px",
            height: "600px"
        },
        overlay: {
            zIndex: 2
        }
    }

    return (
        <View className={wrapperClasses} marginLeft="auto" >
            <FaEdit color="var(--amplify-colors-neutral-80)" size="20px" title="Edit" onClick={openModal}/>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Color Picker"
            >
                <Flex className="dp" direction="column" gap="0px" height="100%" justifyContent="stretch">
                    <Flex padding="large" grow={1} shrink={1} backgroundColor="var(--amplify-colors-neutral-40)" overflow="hidden">
                        <Flex direction="column" width="25%">
                            <DataEditorMetadata dataset={editDataSet} />
                            <DataEditorFieldList fields={dsFields} selected={-1} onSelect={(idx) => { setSelected( idx )}} />
                        </Flex>

                        <View backgroundColor="var(--amplify-colors-neutral-20)" width="100%" >
                            {
                                selected < 0 ? (
                                    <MetadataEditor key={selected} dataset={editDataSet} onUpdate={updateMetadata} />
                                ) : (
                                    <FieldEditor key={selected} field={dsFields[selected]} onUpdate={( update ) => { updateField( selected, update )}}/>
                                )
                            }
                        </View>
                    </Flex>

                    <Flex margin="large" justifyContent="space-between" grow={0} shrink={0}>
                        <Button onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button variation="primary" onClick={updateDataset}>
                            Save
                        </Button>
                    </Flex>
                </Flex>

            </Modal>

        </View>
    )
}

DataSetEditor.propTypes = {
//   onUpdate: PropTypes.func.isRequired,
//   value: PropTypes.string,
//   label: PropTypes.string,
}
