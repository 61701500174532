import KSUID from 'ksuid';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Flex, View, useAuthenticator } from "@aws-amplify/ui-react";

import { useAllGameList } from './AllGameList'
// import { createGame as createDbGame } from '../../hooks'
import { GameItem } from './GameItem'
import { UpdatePublished } from './UpdatePublished';

export const GameSelect = () => {
	const { route, user } = useAuthenticator((context) => [context.route]);
	const [games, deleteGame] = useAllGameList();
	const navigate = useNavigate();

	let component = null 

	function createGame() {
		
		// Create a new ID for the game.
		const id = KSUID.randomSync( Date.now() ).string;

		// then navigate to the page.
		navigate(`/editor/${id}`);
	}

	if ( route === 'authenticated' ) {

		component = (
			<View margin="large">

				<Flex direction="row" justifyContent="space-between" >

					<Button onClick={createGame}>
						Create Game
					</Button>

					<UpdatePublished games={games.list} />
				</Flex>

				<Divider paddingTop="medium" />

				<View maxWidth="992px" margin="0 auto">
					{
						games.list.map( d => {
							return (
								<GameItem
									key={d.gameId} 
									game={d}
									onDelete={user.username === d.author ? deleteGame : null}
								/>
							)
						})
					}
				</View>
	
			</View>
		)
	} else {
		// Redirect to home since the user isn't authenticated.
	}

	return component
}