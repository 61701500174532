import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Modal from "react-modal";
import { ChromePicker } from 'react-color'
import { Button, Flex, View } from "@aws-amplify/ui-react";

// import { RdDialog } from '@ripedata/controls'

export const ColorPicker = ( { className, label, value, onUpdate } ) => {
  const [isOpen, setIsOpen] = useState( false )
  const [color, setColor] = useState( value ? value : '#B6B7B8' )

  let wrapperClasses = classNames( 'cp-ctl', className )

  // function updateState() {
  //   let newValue = !isChecked
  //   onUpdate( newValue )
  //   setColor( newValue )
  // }

  function updateState() {
    onUpdate( color )
    setIsOpen( false )
  }

  function updateColor( val ) {
    setColor( val.hex )
  }

  const clrStyle = {
    backgroundColor: color,
    
  }

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "hidden"
    },
    overlay: {
        zIndex: 2
    }
  }

  return (
    <Flex paddingRight="small" paddingBottom="xs" className={wrapperClasses}>
      {
        label && (
          <View className="cp-ctl-lbl" >
            {label}
          </View>
        )
      }

      <View
        className="cp-ctl-clrblk"
        style={clrStyle}
        onClick={() => { setIsOpen( true ) }}
      />

      <Modal
        isOpen={isOpen}
        onRequestClose={() => { setIsOpen( false )}}
        style={modalStyles}
        contentLabel="Color Picker"
      >
        
        <ChromePicker
          color={color}
          onChange={updateColor}
        />

        <Flex marginTop="medium" width="100%" justifyContent="space-between">
          <Button onClick={() => { setIsOpen( false )}}>
            Cancel
          </Button>

          <Button variation="primary" onClick={updateState}>
            Set
          </Button>
        </Flex>

      </Modal>

    </Flex>
  )
}

ColorPicker.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
}
