
import { addColorScale } from './colorscale'
import { genTooltip } from './tooltip'
import { getDataset, getConfigField } from './utils'

const pcoordDataName = '_pcoordFields_'
const pcoordScaleName = '_pcoordScale_'

const chartHeight = 600

export function pcoord( config, data ) {
  let spec = {
    $schema: 'https://vega.github.io/schema/vega/v5.json',
    description: 'A configurator generated scatter plot specification.',
    autosize: { type: 'fit', resize: false, contains: 'padding' },
    height: chartHeight,
    padding: 5,

    // config: {
    //   axisY: {
    //     title: -2,
    //     titleY: chartHeight - 12,
    //     titleAngle: -45,
    //     titleAlign: "left",
    //     titleBaseline: "top"
    //   }
    // },

    data: [],

    signals: [
      {
        name: 'width',
        update: '(containerSize()[0])',
        on: [
          {
            events: { source: 'window', type: 'resize' },
            update: 'containerSize()[0]'
          }
        ]
      }
    ],

    scales: [],
    axes: [],
    marks: [],
    legends: []
  }

  if ( config && Array.isArray( config.pa ) && config.pa.length ) {

    // We need to loop through the parallel fields to generate the values for
    // the horizontal axis.

    let pcoordData = {
      name: pcoordDataName,
      values: config.pa.map( d => {
        return d.id
      } )
    }

    spec.data.push( pcoordData )

    // Now generate the coordinates scale
    let pcoordScale = {
      name: pcoordScaleName,
      type: 'point',
      range: 'width',
      round: true,
      domain: { data: pcoordDataName, field: 'data' }
    }

    spec.scales.push( pcoordScale )

    // Now run through and add in all of the parallel axes.
    config.pa.forEach( d => {
      let pField = getConfigField( d )
      if ( pField ) {
        let ds = getDataset( spec, pField.datasetId )

        if ( ds ) {
          ds.transform.push(
            {
              type: 'filter',
              expr: `isNumber( datum.${pField.loc} )`
            }
          )
        }

        let scale = {
          name: d.id,
          type: 'linear',
          round: true,
          nice: true,
          zero: true,
          domain: { data: pField.datasetId, field: pField.loc },
          range: 'height',
        }

        // Add the scale
        spec.scales.push( scale )

        // If a title is defined use that, otherwise fallback to the field name
        // then to the field location.
        let axisTitle = d.label ? d.label : pField.name ? pField.name : pField.loc
        // let domain = config.domain ? config.domain : {}
        // let grid = config.grid ? config.grid : {}
        // let ticks = config.ticks ? config.ticks : {}

        let axis = {
          scale: d.id,
          grid: false, //!!grid.show,
          domain: true, //!!domain.show,
          orient: 'left',
          ticks: true, //!!ticks.show,
          tickCount: 5,
          title: axisTitle,
          zindex: 2,
          offset: { scale: pcoordScaleName, value: pField.loc, mult: -1 }
        }

        // Add in the axis
        spec.axes.push( axis )
      }
    } )

    // Add in the color specification if provided.
    addColorScale( spec, config.color, 'color' )

    // Now add the lines
    let paField = getConfigField( config.pa[0] )

    // Get the static color for the mark if it exists.
    let markClr = config.color && config.color.mark ? config.color.mark : {}

    let linesMark = {
      type: 'line',
      from: { data: pcoordDataName },
      encode: {
        update: {
          x: { scale : pcoordScaleName, field: 'data' },

          y: {
            scale: { datum: 'data' },
            field: { parent: { datum: 'data' } }
          },

          strokeWidth: { value: 1.5 },
          strokeOpacity: { value: 0.3 },
          stroke: { value: markClr.stroke ? markClr.stroke : 'steelblue' }
        }
      }
    }

    let colorField = getConfigField( config.color )
    if ( colorField ) {
      linesMark.encode.update.stroke = { scale: 'color', field: { parent: colorField.loc } }

      // Find out if the 'selected' dataset has been created, if so then we 
      // have an interactive legend.
      let colorDataset = spec.data.find( d => {
        return d.name === 'selected'
      })

      if ( colorDataset ) {
        // If we are using interactive legends...
        linesMark.encode.update.opacity = [
          { test: `(!length(data('selected')) || indata('selected', 'value', parent.${colorField.loc}))`, value: 1.0 },
          { value: 0.03 }
        ]
      }
    }

    // If a tooltip is specified...
    if ( Array.isArray( config.tt ) ) {
      linesMark.encode.update.tooltip = { signal: genTooltip( config.tt, 'parent' ) }

      // Now add the hover coloring so we have a visual indication of what
      // the tooltip applies to.
      let hoverClr = config.color && config.color.hover ? config.color.hover : {}

      linesMark.encode.hover = {
        stroke: { value: hoverClr.stroke ? hoverClr.stroke : 'red' },
        strokeWidth: { value : 3 },
        zindex: { value: 1 }
      }
    }

    let chartMark = {
      type: 'group',
      from: { data: paField.datasetId },
      marks: [linesMark]
    }

    spec.marks.push( chartMark )
  }

  return spec
}
