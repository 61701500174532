import { useContext } from 'react';
import { GameConfigContext } from '../../GameConfigContext';

import { GameDescription } from './GameDescription';
import { GameType } from './GameType';
import { ConnectFourConfig } from './ConnectFourConfig';
import { ConnectFourCategoryConfig } from './ConnectFourCategoryConfig';
import { MatchConfig } from './MatchConfig';
import { MultipleChoiceConfig } from './MultipleChoiceConfig';
import { OrderEmConfig } from './OrderEmConfig';
import { PartitionConfig } from './PartitionConfig';
import { SplitConfig } from './SplitConfig';

require( './ConfigPanel.scss' );

export const ConfigPanel = ( props ) => {
  const { config } = useContext( GameConfigContext );
  let gameType = config && config.type ? config.type : '';

  return (
    <div  className="gp" >

      <div className="gp-label" >
        Game Configuration
      </div>

      <div className="gp-ctr">
        <GameType />
        <GameDescription />

        <div >
          {
            gameType === 'connectfour' ? (
              <ConnectFourConfig />
            ) : gameType === 'connectfourcategory' ? (
              <ConnectFourCategoryConfig />
            ) : gameType === 'match' ? (
              <MatchConfig />
            ) : gameType === 'multiplechoice' ? (
              <MultipleChoiceConfig />
            ) : gameType === 'orderem' ? (
              <OrderEmConfig />
            ) : gameType === 'partition' ? (
              <PartitionConfig />
            ) : gameType === 'split' ? (
              <SplitConfig />
            ) : (
              <div>
                Invalid type specified. ({gameType})
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}
